import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useCamerasStore } from '@/stores';
import axios, { AxiosResponse } from 'axios';
import {
  ApiCameraWithIncludes,
  MediaShortcut,
  RecordedImage,
  ApiMediaWithIncludes,
} from '@eencloud/eewc-components/src/service/api-types';
import { useAccountStore } from './account';
import { getPlainAuthKeyFromJwt } from '@eencloud/eewc-components/src/utils/helpers.ts';
import { authKey } from '@eencloud/eewc-components/src/service/auth.js';

const PING_RESULT_EXPIRY = 1000 * 60;
const PING_INTERVAL = 300;
const BRIDGE_TIMEOUT = 5000;

export const useMediaShortcutStore = defineStore('mediaShortcut', () => {
  const camerasStore = useCamerasStore();
  const accountStore = useAccountStore();
  const shortcutAxios = axios.create(); // we use a separate axios instance because we are interacting with legacy and bridge api's

  let pingCounter = 0; // used to keep sending unique pings to the bridge to keep the websocket connection alive

  const websocketConnections: Record<string, WebSocket> = {};

  const camerasBeingPreviewedGroupedByBridgeId: Record<string, Set<string>> = {}; // Keeps track of which cameras are being previewed, independent of the websocket connection

  const poller = ref<NodeJS.Timeout | null>(null);

  const pingResults: Record<string, { result: boolean; time: number }> = {};

  const cameraCallbacks: Record<string, [{ onImage: (detail: RecordedImage) => void; onError: () => void }]> = {};

  function bridgeBaseUrl(bridgeId: string) {
    return `https://een${bridgeId}.local.eagleeyenetworks.com/shortcut`;
  }

  /**
   * Ping the bridge and construct the flv url that should give a direct link to the live stream
   */
  async function tryLiveStreamMediashortcut(cameraId: string, bridgeId: string) {
    if (!mediaShortcutEnabled(cameraId)) {
      return null;
    }

    try {
      const authToken = getAuthToken();
      const baseUrl = bridgeBaseUrl(bridgeId);
      const liveStreamUrl = `${baseUrl}/media/video/stream?id=${cameraId}&A=${authToken}`;
      const bridgePingedSuccesfully = await getBridgeStatus(bridgeId);
      return bridgePingedSuccesfully ? liveStreamUrl : null;
    } catch (error) {
      return null;
    }
  }

  function getV1AxiosConfig() {
    const authToken = getAuthToken() || 'c000';
    const cluster = authToken.substring(0, 4);
    return {
      baseURL: `https://${cluster}.eagleeyenetworks.com`,
    };
  }

  function getBridgeAxiosConfig(bridgeId: string) {
    return {
      baseURL: `https://een${bridgeId}.local.eagleeyenetworks.com/shortcut`,
      signal: AbortSignal.timeout(BRIDGE_TIMEOUT),
      params: {
        A: getAuthToken(),
      },
    };
  }

  /**
   * Pings the bridge directly via the dedicated ping endpoint to check if it is reachable
   */
  async function pingBridge(bridgeId: string) {
    const baseUrl = bridgeBaseUrl(bridgeId);
    const pingUrl = `${baseUrl}/ping`;
    return await shortcutAxios.get(pingUrl, getBridgeAxiosConfig(bridgeId));
  }

  /**
   * Check if a recent ping was successful, otherwise make a new one
   */
  async function getBridgeStatus(bridgeId: string): Promise<boolean> {
    const cachedResult = pingResults[bridgeId];
    const isCacheValid = cachedResult && Date.now() - cachedResult.time < PING_RESULT_EXPIRY;

    if (isCacheValid) {
      return cachedResult.result;
    }

    try {
      await pingBridge(bridgeId);
      pingResults[bridgeId] = { result: true, time: Date.now() };
    } catch {
      pingResults[bridgeId] = { result: false, time: Date.now() };
    }

    return pingResults[bridgeId].result;
  }

  function dropCamera(cameraId: string) {
    let bridge = '';
    // update the state
    Object.keys(camerasBeingPreviewedGroupedByBridgeId).forEach((bridgeId) => {
      if (camerasBeingPreviewedGroupedByBridgeId[bridgeId].has(cameraId)) {
        bridge = bridgeId;
        camerasBeingPreviewedGroupedByBridgeId[bridgeId].delete(cameraId);
        if (camerasBeingPreviewedGroupedByBridgeId[bridgeId].size === 0) {
          delete camerasBeingPreviewedGroupedByBridgeId[bridgeId];
          if (websocketConnections[bridgeId]) {
            try {
              websocketConnections[bridgeId]?.close();
              delete websocketConnections[bridgeId];
            } catch (error) {
              console.log('error closing websocket connection - ', error);
            }
          }
        }
      }
    });

    const message = {
      drop: {
        cameras: [cameraId],
      },
    };

    // tell the bridge to stop sending images via the websocket connection for this camera
    if (websocketConnections[bridge]) {
      switch (websocketConnections[bridge].readyState) {
        case WebSocket.CONNECTING:
          websocketConnections[bridge].addEventListener('open', () => {
            websocketConnections[bridge].send(JSON.stringify(message));
          });
          break;
        case WebSocket.OPEN:
          websocketConnections[bridge].send(JSON.stringify(message));
          break;
      }
    }

    // stop the poller if there are no more cameras
    if (Object.keys(camerasBeingPreviewedGroupedByBridgeId).length === 0) {
      stopPoller();
    }
  }

  function addCameraToOpenWebSocketConnection(cameraId: string, bridgeId: string) {
    const message = { cameras: {} };

    message.cameras[cameraId] = {
      resource: ['image'],
    };

    switch (websocketConnections[bridgeId].readyState) {
      case WebSocket.CONNECTING:
        websocketConnections[bridgeId].addEventListener('open', () => {
          websocketConnections[bridgeId].send(JSON.stringify(message));
        });
        break;
      case WebSocket.OPEN:
        websocketConnections[bridgeId].send(JSON.stringify(message));
        break;
      case WebSocket.CLOSING:
      case WebSocket.CLOSED:
        // if the connection is closed, remove the camera from the connection list
        dropCamera(cameraId);
        break;
    }
  }

  /**
   * Runs some validation, attempts to reach the bridge, and initiates the websocket connection with the bridge that will take over the preview stream.
   *
   * @returns A promise that resolves to a boolean indicating whether the preview attempt was successful.
   */
  async function tryPreviewStreamShortcut(
    cameraId: string,
    callBack: (image: RecordedImage) => void,
    wsErrorCallback: () => void
  ) {
    // immediately register the camera as being previewed, so we can handle the case where the camera is dropped before the websocket connection is established
    const temporaryBridgeId = cameraId + '-temp-bridge';
    registerNewCameraBeingPreviewed(cameraId, temporaryBridgeId);

    try {
      const camera = await camerasStore.getCamera(cameraId);
      const enabled = camera && (await mediaShortcutEnabled(cameraId));
      if (!camera) return false;

      // now that we are 100% certain of the bridgeId we can replace the bridgeId in the camerasBeingPreviewedGroupedByBridgeId if it's still there (not dropped in the meantime)
      if (camerasBeingPreviewedGroupedByBridgeId[temporaryBridgeId]?.has(cameraId)) {
        delete camerasBeingPreviewedGroupedByBridgeId[temporaryBridgeId];
        registerNewCameraBeingPreviewed(cameraId, camera.bridgeId);
      }

      if (!enabled) {
        // media shortcut is not enabled for this bridge or feature flag is disabled
        return false;
      }

      const canReachBridge = await getBridgeStatus(camera.bridgeId);
      if (canReachBridge) {
        startPreviewStreamOverWebsocket(cameraId, camera.bridgeId);
        if (cameraCallbacks[cameraId]?.length) {
          cameraCallbacks[cameraId].push({ onImage: callBack, onError: wsErrorCallback });
        } else {
          cameraCallbacks[cameraId] = [{ onImage: callBack, onError: wsErrorCallback }];
        }
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  /*
   * Register a new camera that's being previewed, independent of the websocket connection
   */
  function registerNewCameraBeingPreviewed(cameraId: string, bridgeId: string) {
    if (!camerasBeingPreviewedGroupedByBridgeId[bridgeId]) {
      camerasBeingPreviewedGroupedByBridgeId[bridgeId] = new Set();
    }
    camerasBeingPreviewedGroupedByBridgeId[bridgeId].add(cameraId);
  }

  /*
   * take the necessary steps to start receiving images from the bridge over a new or existing websocket connection
   */
  function startPreviewStreamOverWebsocket(cameraId: string, bridgeId: string) {
    if (!camerasBeingPreviewedGroupedByBridgeId[bridgeId]) {
      // cameras has been dropped in the meantime
      return;
    }

    if (websocketConnections[bridgeId]) {
      addCameraToOpenWebSocketConnection(cameraId, bridgeId);
    } else {
      createWebSocketConnection(bridgeId);
      addCameraToOpenWebSocketConnection(cameraId, bridgeId);
    }
  }

  function getAuthToken() {
    return getPlainAuthKeyFromJwt(authKey());
  }

  async function mediaShortcutEnabled(cameraId: string, bridgeId?: string): Promise<boolean> {
    // the camera object will most likely have the shareDetails property
    if (!bridgeId) {
      bridgeId = (await camerasStore.getCamera(cameraId))?.bridgeId;
    }
    return !!(
      (cameraId && bridgeId && accountStore?.accountCapabilities?.mediaShortcut)
      // a user level media shortcut setting should be implemented and checked here
    );
  }

  function createWebSocketConnection(bridgeId: string) {
    const ws = new WebSocket(`wss://een${bridgeId}.local.eagleeyenetworks.com/shortcut/ws/ws_poll?A=${getAuthToken()}`);

    websocketConnections[bridgeId] = ws;

    ws.onerror = (error) => {
      ws.close;
      if (camerasBeingPreviewedGroupedByBridgeId[bridgeId]) {
        Object.keys(camerasBeingPreviewedGroupedByBridgeId[bridgeId]).forEach((cameraId) => {
          cameraCallbacks[cameraId].forEach((record) => record.onError());
          delete websocketConnections[bridgeId];
        });
      }
    };

    ws.onmessage = (event) => {
      const eventData: WebSocketResponseMessage = JSON.parse(event.data);
      if (eventData.message === 'OK') {
        Object.keys(eventData.data).forEach(async (cameraId) => {
          const image = eventData.data[cameraId].image;
          if (!image) return;
          // convert the base64 string to a byte array
          const byteArray = await convertBase64ToByteArray(image.image);
          const detail: RecordedImage = {
            data: byteArray,
            timestamp: timestampToDate(image.ts).toISOString(),
          };
          cameraCallbacks[cameraId]?.forEach((record) => record.onImage(detail));
        });
      }
    };

    ws.onclose = () => {
      // restart the websocket connection if cameras still depend on it
      if (camerasBeingPreviewedGroupedByBridgeId[bridgeId]) {
        createWebSocketConnection(bridgeId);
      }
    };

    // if not already done, start the poller to keep the websocket connection alive
    if (!poller.value) {
      poller.value = setInterval(() => {
        Object.keys(websocketConnections).forEach((bridgeId) => {
          if (websocketConnections[bridgeId].readyState === WebSocket.OPEN) {
            websocketConnections[bridgeId].send(JSON.stringify({ ack: pingCounter }));
          }
        });
        pingCounter++;
      }, PING_INTERVAL);
    }
  }

  // special function to convert the 'legacy' EEN timestamp to a JS date
  // Convert timestamp -  20221130110647.387 to Date.-  Wed Nov 30 2022 12:06:48 GMT+0100 (Central European Standard Time)
  function timestampToDate(ts: string) {
    const yy = parseInt(ts.substring(0, 4), 10);
    const mm = parseInt(ts.substring(4, 6), 10);
    const dd = parseInt(ts.substring(6, 8), 10);
    const hr = parseInt(ts.substring(8, 10), 10);
    const mn = parseInt(ts.substring(10, 12), 10);
    const sc = parseInt(ts.substring(12, 14), 10);
    const ms = parseInt(ts.substring(15), 10);
    const date = new Date(Date.UTC(yy, mm - 1, dd, hr, mn, sc, ms));
    return date;
  }

  // conversion is needed to use the exisiting render method on the PreviewImage component, which paints on a canvas
  async function convertBase64ToByteArray(base64String: string) {
    var dataUrl = 'data:application/octet-binary;base64,' + base64String;
    const res = await fetch(dataUrl);
    const arrayBuffer = await res.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    return uint8Array;
  }

  function stopPoller() {
    if (poller.value) {
      clearInterval(poller.value);
      poller.value = null;
    }
  }

  /**
   * Generates a string in the EEN date format (YYYYMMDDHHmmss.SSS), which is required for the legacy API.
   *
   * @param date - The Date object to generate the timestamp from.
   * @returns A string representing the timestamp in the format YYYYMMDDHHmmss.SSS.
   */
  function createEENTimestamp(date: Date) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const milliseconds = date.getUTCMilliseconds();
    return `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}${hours
      .toString()
      .padStart(2, '0')}${minutes.toString().padStart(2, '0')}${seconds.toString().padStart(2, '0')}.${milliseconds
      .toString()
      .padStart(3, '0')}`;
  }

  /*
   * Ask the bridge for files for the given time span
   */
  async function enumerateFilesOnBridge(
    cameraId: string,
    bridgeId: string,
    startTime: EENTimestamp,
    endTime: EENTimestamp
  ) {
    try {
      const url =
        '/media/list/video?id=' +
        cameraId +
        ';start_timestamp=' +
        startTime +
        ';end_timestamp=' +
        endTime +
        ';previous=1;format=eents' +
        '&A=' +
        getAuthToken();
      const res = await shortcutAxios.get(url, getBridgeAxiosConfig(bridgeId));
      return res.data;
    } catch (error) {
      return [];
    }
  }

  /**
   * Post request to the cloud to check if we can play the video directly from the bridge.
   * 200 reponse is considered positive
   */
  async function cloudFlvRequest(
    cameraId: string,
    startTime: EENTimestamp,
    endTime: EENTimestamp,
    data: number[]
  ): Promise<FlvResponse | undefined> {
    try {
      const config = getV1AxiosConfig();
      const authKey = getAuthToken();
      const url = `/asset/play/video.flv?metatype=2;id=${cameraId};start_timestamp=${startTime};end_timestamp=${endTime}&A=${authKey}`;
      const res = await shortcutAxios.post(url, data, config);
      return res.data;
    } catch (error) {
      return undefined;
    }
  }

  /*
   * Construct the flv url that should give a direct link to the video on the bridge
   */
  function bridgeFlvRequest(cameraId: string, bridgeId: string, meta: FlvResponse): { url: string; meta: FlvResponse } {
    const baseUrl = getBridgeAxiosConfig(bridgeId).baseURL;
    let url = baseUrl + '/media/video/header?id=' + cameraId;
    url += ';mediats=' + meta.header.mediats;
    url += ';startts=' + meta.header.starttime;
    url += ';duration=' + meta.header.duration;
    url += ';filesize=' + meta.header.size;
    url += ';raw_header_size=' + meta.header.headersize;
    url += ';target_header_size=' + meta.header.virtual_headersize;
    url += ';gopoffset=' + meta.header.gopoffset;
    url += ';decrypt=' + meta.header.encryption;
    url += ';continue=false';
    url += ';A=' + getAuthToken();
    const seeks = [];
    for (let i = 0; i < meta.media.length; i++) {
      const media = meta.media[i];
      // array of mediats, first keyframe offset, virtual offset
      const skf = media[0] + '_' + media[1] + '_' + media[2];
      seeks.push(skf);
    }
    if (seeks.length) {
      url += ';seeks=' + seeks.join(',');
    }
    var info = { url, meta };
    return info;
  }

  /**
   * Try to get a preview image via the shortcut method
   */
  async function getPreviewImageViaShortcut(cameraId: string, timestamp: string) {
    try {
      const res = await v1ApiGetImageMeta(cameraId, timestamp);
      if (res.status === 200) {
        const contentType = res.headers['content-type'];
        const index = contentType.search(/json/i);
        let imageData;
        let imageTimestamp = '';
        if (index < 0) {
          // we have an image blob, and can use it directly, no need to shortcut
          imageData = res;
        } else {
          // use the image meta data to get the image from the bridge
          const bridgeImage = await fetchImageFromBridge(cameraId, res.data);
          imageData = bridgeImage;
        }

        if (!imageData) return null;

        const eenTimestamp = imageData.headers['x-ee-timestamp']; // like "preview-20241114100437.774"
        imageTimestamp = eenTimestamp.slice(8); // like "20241114100437.774"

        // convert the blob response to an array buffer
        const arrayBuffer = await imageData.data.arrayBuffer();

        const detail: RecordedImage = {
          data: arrayBuffer,
          timestamp: timestampToDate(imageTimestamp).toISOString(),
        };
        return detail;
      }
    } catch (error) {
      // error getting preview image via shortcut, suppress error and return null
      return null;
    }
  }

  /*
   * we think we can shortcut, get the necessary metadata from the cloud
   * 200 response is either jpeg or json metadata
   */
  async function v1ApiGetImageMeta(cameraId: string, timestamp: string): Promise<AxiosResponse> {
    const date = new Date(timestamp);
    date.setHours(0, 0, 0, 0);
    const EENTimestamp = createEENTimestamp(new Date(timestamp));
    const config = { ...getV1AxiosConfig(), responseType: 'blob' };
    const url = `/asset/prev/image.jpeg?id=${cameraId};timestamp=${EENTimestamp};asset_class=all;&A=${getAuthToken()}`;
    const res = await shortcutAxios.get(url, config);
    return res;
  }

  /*
   * we have metadata, go get the matching image from the bridge.
   */
  async function fetchImageFromBridge(cameraId: string, frameMeta: FrameMeta) {
    const camera = await camerasStore.getCamera(cameraId);
    if (!camera) return;
    const bridgeId = camera.bridgeId;
    let url = '/media/image?id=' + cameraId;

    try {
      url += ';timestamp=' + frameMeta.frame.timestamp;
      url += ';mediats=' + frameMeta.frame.mediats;
      url += ';offset=' + frameMeta.frame.offset;
      url += ';flags=' + frameMeta.frame.flags;
      url += ';size=' + frameMeta.frame.size;
      if (frameMeta.keyframe) {
        url += ';kf_timestamp=' + frameMeta.keyframe.timestamp;
        url += ';kf_offset=' + frameMeta.keyframe.offset;
        url += ';kf_flags=' + frameMeta.keyframe.flags;
        url += ';kf_size=' + frameMeta.keyframe.size;
      }
      url += ';A=' + getAuthToken();

      const res = await shortcutAxios.get(url, getBridgeAxiosConfig(bridgeId));
      return res;
    } catch (err) {
      // fetch the image from the cloud
      return undefined;
    }
  }

  /**
   * Main function used by the History Browser to get an FLV video from the bridge
   *
   * The sequence of steps is as follows:
   * 1. Conditional checks to see if the media shortcut is enabled for the camera.
   * 2. Enumerates files on the bridge for the given time span.
   * 3. Requests metadata match from the cloud for the files on the bridge.
   * 4. If a match is found, a url to use with the flv player is constructed and returned.
   *
   * If no flv url can be constructed, null is returned and the HB should fall back to the cloud.
   */
  async function constructVideoSrc(cameraId: string, recording: ApiMediaWithIncludes) {
    try {
      // don't allow media shortcut for recordings newer than 5 minutes, because the classic webapp does the same
      if (new Date(recording.startTimestamp) > new Date(Date.now() - 5 * 60 * 1000)) {
        return null;
      }

      // some conditional checks
      const camera = await camerasStore.getCamera(cameraId);
      if (!camera || !(await mediaShortcutEnabled(cameraId, camera.bridgeId))) return;
      const bridgePingedSuccesfully = await getBridgeStatus(camera.bridgeId);
      if (!bridgePingedSuccesfully) return;
      // we retrieve the complete recording as listed in the media list api. Because the HB wasn't designed to handle files deviating from those spans.
      const startTime = createEENTimestamp(new Date(recording.startTimestamp));
      const endTime = createEENTimestamp(new Date(recording.endTimestamp));

      // first check if there are any relevant files on the bridge
      const bridgeEnumerationResponse = await enumerateFilesOnBridge(cameraId, camera.bridgeId, startTime, endTime);
      if (bridgeEnumerationResponse.data?.length) {
        // ask cloud for metadata match of the files on the bridge
        const cloudFlvMetaRequestResult = await cloudFlvRequest(
          cameraId,
          startTime,
          endTime,
          bridgeEnumerationResponse.data
        );
        if (cloudFlvMetaRequestResult) {
          // if we get a 200, we can play the video directly from the bridge
          return await bridgeFlvRequest(cameraId, camera.bridgeId, cloudFlvMetaRequestResult);
        }
      }
    } catch (error) {
      return null;
    }
    // if no files on bridge, or any error, fetch from cloud
    return null;
  }

  return {
    tryPreviewStreamShortcut,
    tryLiveStreamMediashortcut,
    dropCamera,
    getPreviewImageViaShortcut,
    mediaShortcutEnabled,
    constructVideoSrc,
  };
});

// types

type WebSocketResponseMessage = {
  status_code: number;
  message: 'OK';
  data: Record<string, BridgeCameraFrame>;
};

type BridgeCameraFrame = {
  image: {
    image: string; // base64 encoded image
    ts: string; // een timestamp, e.g.  '20241107144222.527',
    mbox: [number[]]; // e.g. [[1, 1, 0.4, 0, 0.6, 0.8333]]
    epoch: number;
  };
};

export type FlvResponse = {
  header: {
    mediaType: 'flv';
    encryption: number;
    mediats: number;
    duration: number;
    starttime: number;
    size: number;
    headersize: number;
    virtual_headersize: number;
    gopoffset: number;
  };
  keyframes: [[number, number, number]];
  media: [[number, number, number]];
};

/**
 *
 * Timestamp in the EEN format. e.g. 20241113122603.747
 */
type EENTimestamp = string;

type FrameMeta = {
  mediats: EENTimestamp;
  frame: {
    offset: number;
    size: number;
    flags: number;
    timestamp: EENTimestamp;
    mediats: EENTimestamp;
  };
  keyframe: {
    offset: number;
    size: number;
    flags: number;
    timestamp: EENTimestamp;
  };
};
