<template>
  <div
    ref="previewImageContainer"
    @click.exact="handleClick"
    @dblclick.shift.exact="handleShiftDbClick"
  >
    <eewc-loading-spinner
      :data-testid="'preview-image-loader-' + cameraId"
      :is-loading="loading && isCameraOnline"
    />
    <canvas
      :id="`preview-image-${cameraId}`"
      ref="previewImage"
      class="preview-canvas"
      width="720"
      :class="{ 'preview-canvas-fill': applyObjectFill }"
      height="720"
    />
    <display-overlay
      v-if="displayOverlaySvg && showAnalytics"
      class="preview-display-overlay"
      :svg="displayOverlaySvg"
      :image-dimensions="displayOverlayDimensions"
      :container="previewImageContainer"
      :object-fill="applyObjectFill"
    />
  </div>
</template>

<script setup lang="ts">
import { authKey } from '@eencloud/eewc-components/src/service/auth.js';
import { ref, onMounted, onUnmounted, watch, computed, onBeforeUnmount, Ref } from 'vue';
import {
  useFeedsStore,
  useMediaStore,
  usePreviewImageStore,
  useUsersStore,
  useMediaShortcutStore,
  useAppStateStore,
  useAuthStateStore,
} from '@/stores';
import { usePTZControls } from '@/service/usePTZControls';
import LivePlayer from '@een/live-video-web-sdk';
import lodash from 'lodash';
import useMeasureLoadingTimes from '@/service/useMeasureLoadingTimes';
import { generateTimestamp, rerouteToLogin } from '@/service/helpers';
import {
  ApiCameraWithIncludes,
  RecordedImage,
  RecordedImageParams,
} from '@eencloud/eewc-components/src/service/api-types';
import VideoPlayer from '@een/live-video-web-sdk';
import DisplayOverlay from '@/components/DisplayOverlay.vue';

const props = defineProps<{
  cameraId: string;
  maxHeight?: number;
  fullQualityVideo?: boolean;
  isCameraOnline?: boolean;
  isVisible?: boolean;
  paneId: string;
  camera?: ApiCameraWithIncludes;
  aspectRatio?: '16x9' | '4x3';
  ptzControlsEnabled?: Ref<boolean>;
}>();

const emit = defineEmits<{
  (e: 'timestamp', value: number | string): void;
  (e: 'videoPlayingState', value: boolean): void;
  (e: 'shiftdblclick'): void;
  (e: 'click'): void;
  (e: 'feedError'): void;
  (e: 'imageDataBuffer', value: ArrayBuffer): void;
}>();

const SUPPORTED_OVERLAY_EVENT_TYPES = [
  'een.motionDetectionEvent.v1',
  'een.objectIntrusionEvent.v1',
  'een.personDetectionEvent.v1',
  'een.vehicleDetectionEvent.v1',
  'een.gunDetectionEvent.v1',
].toString();
const previewImage = ref();
const aspectRatioCalculated = ref(false);
const addFillClass = ref(false);
const feedsStore = useFeedsStore();
const mediaStore = useMediaStore();
const previewImageStore = usePreviewImageStore();
const usersStore = useUsersStore();
const mediaShortcutStore = useMediaShortcutStore();
const appStateStore = useAppStateStore();
const authStateStore = useAuthStateStore();

const displayOverlaySvg = ref<string | null>(null);
const displayOverlayDimensions = ref<{ width: number; height: number } | undefined>(undefined);
const showAnalytics = computed(() => {
  return usersStore.currentUser?.previewSettings?.showAnalytics;
});
const applyObjectFill = computed(() => {
  return addFillClass.value || isFishEye.value;
});

const uniqueDeviceId = `${props.cameraId}-${props.paneId}`; // At any time, unique id must be passed jpegParser worker. The issue comes when in single view/page multiple previewImages component is rendered for same camera
const ptzControlsEnabled = computed(() => props.ptzControlsEnabled?.value);
const isInternetConnected = computed(() => appStateStore.isInternetConnected);
const previewImageContainer = ref();
const isRefetchTriggered = ref(false);

usePTZControls(previewImage, props.cameraId, ptzControlsEnabled);

const loading = ref(false);
const isPlaying = ref(false);
let player: VideoPlayer | null = null;
let reader: ReadableStreamDefaultReader | null = null;
let controller: AbortController = new AbortController();
let timer: number;

let lastImageResponse: any = null;

const PREVIEW_DATA_CACHE_TIME = 300000;

onMounted(() => {
  applyCachedPreviewData(); // apply cached image if available
  startVideoStream();
});

function retryPreviewStream() {
  if (props.isCameraOnline) {
    startVideoStream();
  }
}

watch(
  () => [props.isVisible],
  () => {
    if (props.isVisible) {
      getLastRecordedImage(); // get the latest recorded image
      props.isCameraOnline && startVideoStream();
    } else if (isPlaying.value && !props.isVisible) {
      cachePreviewData();
      stopStream();
    } else if (!props.isCameraOnline) {
      stopStream();
    }
  }
);

watch(
  () => isInternetConnected.value,
  (online) => {
    if (online) {
      // Refetch when the network connection is restored.
      retryPreviewStream();
    }
  }
);

function resizeEvent() {
  if (!previewImage.value) return;
  previewImage.value.width = previewImage.value.offsetWidth;
  previewImage.value.height = previewImage.value.offsetHeight;
}

watch(
  () => props.fullQualityVideo,
  (fullQuality) => {
    const resizeObserver = new ResizeObserver(lodash.debounce(resizeEvent, 200));
    isPlaying.value = false;
    if (fullQuality) {
      // stop the preview stream and remove event listener
      reader?.cancel();
      controller?.abort();
      resizeObserver.observe(previewImage.value);
    } else {
      // stop the live video player
      player?.stop();
      resizeObserver.unobserve(previewImage.value);
    }
    startVideoStream();
  }
);

watch(
  () => props.isCameraOnline,
  (online) => {
    if (isPlaying.value) return;
    if (online) {
      startVideoStream();
    } else {
      getLastRecordedImage();
      stopStream();
    }
  }
);

const handleClick = () => {
  if (!ptzControlsEnabled.value) {
    emit('click');
  }
};

const handleShiftDbClick = () => {
  if (!ptzControlsEnabled.value) {
    emit('shiftdblclick');
  }
};

async function getLastRecordedImage() {
  const params: RecordedImageParams = {
    deviceId: props.cameraId,
    timestamp__lte: generateTimestamp(Date.now()),
    type: 'preview',
  };
  const res = await mediaStore.getRecordedImage(params, false, controller);

  res?.data && renderOnCanvas(res);
}

function startVideoStream() {
  if (props.isVisible && !isPlaying.value) {
    isPlaying.value = true;
    if (props.fullQualityVideo) {
      startFullQualityStream();
    } else {
      tryMediaShortcut();
      preparePreviewStream();
    }
  }
}
const isFishEye = computed(() => props.camera?.capabilities?.ptz?.fisheye);

async function renderOnCanvas(res: RecordedImage) {
  try {
    const previewImageCanvas = previewImage.value;
    if (!previewImageCanvas || !res.data) return;

    lastImageResponse = res;
    if (isFishEye.value) {
      emit('imageDataBuffer', res.data);
    } else {
      const bitmap = await createImageBitmap(new Blob([res.data], { type: 'image/jpeg' }));
      const ctx = previewImageCanvas.getContext('2d');
      // add fill class if aspect ratio is 16:9 otherwise normal class is there by default
      if (!aspectRatioCalculated.value) {
        const shouldAddFillClass = previewImageCanvas.width < 1.2 * bitmap.width;
        aspectRatioCalculated.value = true;
        addFillClass.value = shouldAddFillClass;
      }

      previewImageCanvas.height = bitmap.height;
      previewImageCanvas.width = bitmap.width;
      displayOverlayDimensions.value = { width: bitmap.width, height: bitmap.height };
      ctx.drawImage(bitmap, 0, 0, previewImageCanvas.width, previewImageCanvas.height);
      bitmap.close();
    }
    res.timestamp && emit('timestamp', res.timestamp);
    displayOverlaySvg.value = res.overlaySvg ?? null;
  } catch (error) {
    console.debug('Error rendering image on canvas:', error);
  }
}
/**
 * Attempts to set up a media shortcut for the given camera.
 * If the shortcut is successfully set up, it stops the current preview stream because the media shortcut should take over immediately.
 */
async function tryMediaShortcut() {
  const shortcutSuccessful = await mediaShortcutStore.tryPreviewStreamShortcut(
    props.cameraId,
    renderOnCanvas,
    preparePreviewStream
  );
  if (shortcutSuccessful) {
    isPlaying.value = true;
    emit('videoPlayingState', true);
    controller.abort();
  }
}

async function preparePreviewStream() {
  const signal = controller.signal;
  const canvas = previewImage.value;
  const feed = await feedsStore.getPreviewFeed(props.cameraId, signal);
  if (!feed) {
    emit('feedError');
    return;
  }

  if (canvas) {
    loading.value = true;
    if (window.jpegStreamParser && feed) {
      const overlayIdParam = showAnalytics.value ? `&overlayId__in=${SUPPORTED_OVERLAY_EVENT_TYPES}` : '';
      startPreviewStream(`${feed.multipartUrl}&isoTimestamps=true${overlayIdParam}`);
      window.jpegStreamParser.addEventListener(
        'message',
        async ({ data }: { data: { cameraId: string; value: RecordedImage } }) => {
          try {
            if (data.cameraId === uniqueDeviceId) {
              isPlaying.value = true;
              emit('videoPlayingState', true);
              await renderOnCanvas(data.value);
            }
          } catch (error) {
            console.log(
              `A frame for camera ${props.cameraId} failed to decode, due to the following error: ${error}, Timestamp: ${data.value.timestamp}`
            );
            emit('videoPlayingState', false);
            isPlaying.value = false;
          }
        },
        {
          capture: false,
          once: false,
          passive: false,
          signal: signal,
        }
      );
    } else {
      loading.value = false;
    }
  }
}

async function startFullQualityStream() {
  if (isFishEye.value) return false;
  loading.value = true;
  const feed = await feedsStore.getMainFeed(props.cameraId);
  previewImage.value.width = previewImage.value.offsetWidth;
  previewImage.value.height = previewImage.value.offsetHeight;

  player = new LivePlayer();
  player.start({
    canvasElement: previewImage.value,
    onFrame: (time) => onFrame(time),
    onStop: () => {
      emit('videoPlayingState', false);
      isPlaying.value = false;
    },
    feedUrl: feed?.multipartUrl,
    jwt: authKey(),
  });

  function onFrame(time: number) {
    loading.value = false;
    isPlaying.value = true;
    emit('videoPlayingState', true);
    emit('timestamp', time);
  }
}

async function startPreviewStream(streamUrl: string | URL | Request) {
  try {
    const res = await fetch(streamUrl, {
      signal: controller.signal,
      headers: {
        Authorization: 'bearer ' + authKey(),
      },
    });

    // forcefully reroute to login multipart api returns 401
    if (res.status === 401) {
      rerouteToLogin();
    }

    if (res.body) {
      reader = res.body.getReader();
      let result = await reader.read();
      isRefetchTriggered.value = false;

      while (!result.done) {
        const transfer = [result.value.buffer];
        window.jpegStreamParser?.postMessage({ cameraId: uniqueDeviceId, value: result.value }, transfer);
        loading.value = false;
        result = await reader.read();
      }

      isPlaying.value = false;
      loading.value = false;
    }
  } catch (err: any) {
    loading.value = false;
    console.log(err);
    if (err.name === 'AbortError') return;
    if (err.message === 'network error' && isInternetConnected.value && !isRefetchTriggered.value) {
      stopStream();
      retryPreviewStream();
      isRefetchTriggered.value = true;
      /*
      trigger refetch on the first network error or if the API succeeds and later fails,
      ensuring refetch is not triggered multiple times unnecessarily.
      */
    }
  }
}

function resetJpegStreamBuffer() {
  // clean up the partial data from the buffer
  window.jpegStreamParser?.postMessage({ cameraId: uniqueDeviceId });
}

function applyCachedPreviewData() {
  const cachedData = previewImageStore.images[props.cameraId] as unknown as RecordedImage;
  if (
    cachedData &&
    cachedData.timestamp &&
    new Date(cachedData.timestamp) > new Date(Date.now() - PREVIEW_DATA_CACHE_TIME)
  ) {
    renderOnCanvas(cachedData);
  }
}

function cachePreviewData() {
  if (lastImageResponse) {
    previewImageStore.images[props.cameraId] = lastImageResponse;
  }
}

function stopStream() {
  if (props.fullQualityVideo) {
    player?.stop();
  } else {
    mediaShortcutStore.dropCamera(props.cameraId);
    reader?.cancel();
    controller?.abort();
    resetJpegStreamBuffer();
    emit('videoPlayingState', false);
    isPlaying.value = false;
    controller = new AbortController();
  }
}

onBeforeUnmount(() => {
  cachePreviewData();
});

onUnmounted(() => {
  stopStream();
});
</script>

<style lang="scss" scoped>
@import '../styles/public/main.scss';

.preview-canvas {
  width: 100%;
  height: 100%;
  background: $primary;
  object-fit: contain;
  cursor: pointer;

  &-fill {
    object-fit: fill;
  }
}
</style>
