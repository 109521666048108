<template>
  <div class="d-flex justify-space-between pt-2">
    <div class="multi-camera-site">
      <site-sidebar
        v-if="addSidebarOpen"
        @close="addSidebarOpen = false"
      />
      <div>
        <eewc-form-field :label="t('Site')">
          <div class="multi-camera-site__site-picker">
            <location-picker
              class="multi-camera-site__site-picker__picker"
              data-testid="multi-camera-settings-site"
              :location-id="multiCameraData?.locationId"
              :has-no-location-item="true"
              @input="handleSiteChange"
            />
            <eewc-button-common
              data-testid="multi-camera-settings-add-new-site"
              type="clear"
              icon
              append-icon="$icon_plus"
              @click="addSite"
            />
          </div>
        </eewc-form-field>
        <div class="multi-camera-site__site-details">
          <eewc-expansion-panels
            v-model="showSiteDetails"
            class="mb-4"
          >
            <eewc-expansion-panel :title="t('Details')">
              <eewc-form-field
                :loading="isSitesFetching"
                :label="t('Street address')"
                class="mt-4"
              >
                {{ getSanitizedText(siteSummary?.streetAddress || siteSummary?.streetAddress2) }}
              </eewc-form-field>
              <eewc-form-field
                :loading="isSitesFetching"
                :label="t('City')"
              >
                {{ getSanitizedText(siteSummary?.city) }}
              </eewc-form-field>
              <eewc-form-field
                :loading="isSitesFetching"
                :label="t('State')"
              >
                {{ getSanitizedText(siteSummary?.region) }}
              </eewc-form-field>
              <eewc-form-field
                :loading="isSitesFetching"
                :label="t('ZIP code')"
              >
                {{ getSanitizedText(siteSummary?.postalCode) }}
              </eewc-form-field>
              <eewc-form-field
                :loading="isSitesFetching"
                :label="t('Country')"
              >
                {{ getSanitizedText(siteSummary?.country) }}
              </eewc-form-field>
            </eewc-expansion-panel>
          </eewc-expansion-panels>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

import { ApiMultiCameraWithIncludes } from '@eencloud/eewc-components/src/service/api-types';

import { t } from '@/plugins/i18n.ts';
import { useLocationsStore } from '@/stores';
import { getSanitizedText } from '@/service/helpers';

import { SiteSummary } from './types';
import LocationPicker from '@/components/LocationPicker.vue';
import SiteSidebar from '../Sites/settings/SiteSidebar.vue';

const props = defineProps<{
  multiCameraData?: ApiMultiCameraWithIncludes;
}>();

const addSidebarOpen = ref(false);
const isSitesFetching = ref(false);
const showSiteDetails = ref<number[] | []>([]);
const siteId = ref<string | null>(props.multiCameraData?.locationId || null);
const siteSummary = ref<SiteSummary>();

const sitesStore = useLocationsStore();

onMounted(() => {
  initSitesSummary();
  if (props.multiCameraData?.locationId) {
    fetchSite(props.multiCameraData.locationId);
  }
});

watch(
  () => sitesStore.location,
  () => {
    initSitesSummary();
  }
);

function initSitesSummary() {
  const site = sitesStore.location;
  siteSummary.value = {
    streetAddress: site?.address?.streetAddress,
    streetAddress2: site?.address?.streetAddress2,
    city: site?.address?.city,
    region: site?.address?.region,
    postalCode: site?.address?.postalCode,
    country: site?.address?.country,
  };
}

async function fetchSite(siteId: string) {
  isSitesFetching.value = true;
  await sitesStore.getLocation(siteId);
  isSitesFetching.value = false;
}

function handleSiteChange(currentSiteId: string) {
  siteId.value = currentSiteId;

  if (currentSiteId) {
    fetchSite(currentSiteId);
  } else {
    siteSummary.value = {
      streetAddress: '',
      streetAddress2: '',
      city: '',
      region: '',
      postalCode: '',
      country: '',
    };
  }
}

function isLocationIdChanged() {
  const defaultLocationId = props.multiCameraData?.locationId;
  return defaultLocationId !== undefined && siteId.value !== defaultLocationId;
}

function isDirty() {
  return isLocationIdChanged();
}

function getUpdates() {
  return {
    locationId: siteId.value,
  };
}

function addSite() {
  addSidebarOpen.value = true;
}

defineExpose({
  isDirty,
  getUpdates,
});
</script>

<style scoped lang="scss">
@import '@/styles/public/main.scss';

.multi-camera-site {
  width: 600px;
  column-gap: 8px;

  &__site-picker {
    display: inline-flex;
    width: 100%;

    &__picker {
      width: 400px;
      margin-right: 8px;
    }
  }

  &__site-details {
    width: 564px;
  }

  .eewc-form-field {
    align-items: center;
    grid-template-columns: 160px 1fr !important;
  }
}
</style>
