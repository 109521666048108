import posthog from 'posthog-js';

export default {
  install(Vue) {
    posthog.init('phc_ziFxZGCxvRMdAJ9HaygHhUCrkVxqKwO9usoEeoTDOPU', {
      api_host: 'https://us.i.posthog.com',
      capture_pageview: false,
    });
    Vue.prototype.$posthog = posthog;
  },
};
