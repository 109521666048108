<template>
  <eewc-sidebar
    :tab-names="['Info', 'Cameras']"
    :active-tab="tabValue"
    class="add-site-sidebar"
    :title="sidebarTitle"
    :drawer-open="true"
    :permanent="true"
    :cancel-button="t('Cancel')"
    @closeSidebar="$emit('close')"
    @changeActiveTab="updateActiveTab"
  >
    <template #tabContent>
      <!-- Info tab -->
      <v-tab-item>
        <v-card flat>
          <v-form ref="addSiteForm">
            <v-card-text>
              <eewc-form-field
                :loading="siteLoading"
                :label="t('Name')"
              >
                <eewc-text-field
                  v-model="formData.name"
                  :rules="[rules.requiredText, rules.isMaxLength(NAME_MAX_LENGTH)]"
                  :label="t('Name')"
                  :data-testid="siteId ? 'edit-location-name' : 'add-location-name'"
                />
              </eewc-form-field>
              <eewc-form-field
                :loading="siteLoading"
                :label="t('Street address')"
                :optional="`(${t('optional')})`"
              >
                <eewc-text-field
                  v-model="formData.address"
                  :rules="[rules.isMaxLength(ADDRESS_MAX_LENGTH)]"
                  :label="t('Street address')"
                  :data-testid="siteId ? 'edit-location-address' : 'add-location-address'"
                />
              </eewc-form-field>
              <eewc-form-field
                :loading="siteLoading"
                :label="t('City')"
                :optional="`(${t('optional')})`"
              >
                <eewc-text-field
                  v-model="formData.city"
                  :rules="[rules.isMaxLength(ADDRESS_MAX_LENGTH)]"
                  :label="t('City')"
                  :data-testid="siteId ? 'edit-location-city' : 'add-location-city'"
                />
              </eewc-form-field>
              <eewc-form-field
                :loading="siteLoading"
                :label="t('Region')"
                :optional="`(${t('optional')})`"
              >
                <eewc-text-field
                  v-model="formData.region"
                  :rules="[rules.isMaxLength(ADDRESS_MAX_LENGTH)]"
                  :label="t('Region')"
                  :data-testid="siteId ? 'edit-location-region' : 'add-location-region'"
                />
              </eewc-form-field>
              <eewc-form-field
                :loading="siteLoading"
                :label="t('ZIP code')"
                :optional="`(${t('optional')})`"
              >
                <eewc-text-field
                  v-model="formData.zip"
                  :rules="[rules.isMaxLength(ADDRESS_MAX_LENGTH)]"
                  :label="t('Zip/Postal code')"
                  :data-testid="siteId ? 'edit-location-zip' : 'add-location-zip'"
                />
              </eewc-form-field>
              <eewc-form-field
                :loading="siteLoading"
                :label="t('Country')"
                :optional="`(${t('optional')})`"
              >
                <eewc-text-field
                  v-model="formData.country"
                  :rules="[rules.isMaxLength(ADDRESS_MAX_LENGTH)]"
                  :label="t('Country')"
                  :data-testid="siteId ? 'edit-location-country' : 'add-location-country'"
                />
              </eewc-form-field>
              <div>
                <eewc-skeleton
                  v-if="siteLoading"
                  type="text"
                  max-width="250px"
                />
                <eewc-check-box
                  v-else
                  :value="isDefault"
                  :label="t('Make this site as a default')"
                  :data-testid="siteId ? 'edit-location-default' : 'add-location-default'"
                  @change="() => setDefault()"
                />
              </div>
            </v-card-text>
          </v-form>
        </v-card>
      </v-tab-item>
      <!-- Cameras tab -->
      <v-tab-item>
        <v-card
          flat
          class="cameras-list"
        >
          <v-card-text>
            <eewc-text-field
              v-model="searchText"
              left-icon="$icon_funnel"
              :data-testid="siteId ? 'edit-location-filter-location' : 'add-location-filter-location'"
              :label="t('Camera filter')"
              clearable
            />
            <v-row
              v-if="siteId"
              class="cameras-list__switch-row"
            >
              <v-col
                cols="8"
                class="switch-row__switch"
              >
                {{ t('Show cameras already in location') }}
              </v-col>
              <v-col
                cols="4"
                class="d-flex justify-end"
              >
                <eewc-switch-button
                  id="switch"
                  v-model="showCamsInSite"
                  class="d-flex align-center"
                />
              </v-col>
            </v-row>
            <v-divider class="cameras-list__divider mt-5" />

            <div
              v-if="filteredInfiniteLoadingCameras.length > 0"
              class="cameras-list__scrollbox"
            >
              <!-- <eewc-check-box
                :value="
                  filteredInfiniteLoadingCameras.every((c) => cameraIdsToAdd.includes(c.id)) &&
                  cameraIdsToAdd.length > 0
                "
                :indeterminate="
                  filteredInfiniteLoadingCameras.length !== cameraIdsToAdd.length && cameraIdsToAdd.length > 0
                "
                :label="t('Select all')"
                @change="(value) => selectAllCameras(value)"
              /> -->
              <scrollable-list-view-switcher v-model="view" />
              <scrollable-list-item
                v-for="cam in filteredInfiniteLoadingCameras"
                :key="cam.id"
                :camera="cam"
                type="selectable"
                :view="view"
                :selected="cameraIdsToAdd.includes(cam.id)"
                @onSelect="(value) => selectCam(cam.id, value)"
              />
            </div>
            <infinite-loading
              ref="infiniteLoadingRef"
              class="cameras-list__scrollbox"
              @infinite="infiniteHandler"
            >
              <template #no-more>
                <span />
              </template>
              <template #spinner>
                <span>
                  <scrollable-list-item-skeleton
                    v-if="!filteredInfiniteLoadingCameras.length"
                    class="pb-5"
                    :view="view"
                  />
                  <scrollable-list-item-skeleton :view="view" />
                </span>
              </template>
              <template #no-results>
                <span>
                  <div class="cameras-list__empty text-center">
                    <div class="empty__title">
                      {{ t('No results found') }}
                    </div>
                    <div class="empty__text">
                      {{ t('Enter camera filter criteria above') }}
                    </div>
                  </div>
                </span>
              </template>
            </infinite-loading>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </template>
    <template #buttonright>
      <eewc-button-common
        :loading="loading"
        color="accent"
        @click="saveChanges"
      >
        {{ saveButtonText }}
      </eewc-button-common>
    </template>
  </eewc-sidebar>
</template>

<script setup lang="ts">
import { reactive, watch, ref, computed, onMounted, onUnmounted } from 'vue';
import InfiniteLoading from 'vue-infinite-loading';

import { ApiCameraWithIncludes, GetCamerasParams, Location } from '@eencloud/eewc-components/src/service/api-types';
import type { ViewType } from '@/components/ScrollableList/types.ts';

import { InfiniteStateChanger } from '@/service/types/infinite-loading';
import { rules } from '@/service/validators';
import { t } from '@/plugins/i18n.ts';
import { useCamerasStore, useLocationsStore } from '@/stores';

import ScrollableListItemSkeleton from '@/components/ScrollableList/ScrollableListItemSkeleton.vue';
import ScrollableListViewSwitcher from '@/components/ScrollableList/ScrollableListViewSwitcher.vue';
import ScrollableListItem from '@/components/ScrollableList/ScrollableListItem.vue';

const props = defineProps<{
  siteId?: string;
  parentId?: string;
  activeTab?: number;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'updateLocation'): void;
}>();

const FIRST_TAB_INDEX = 0;
const ADDRESS_MAX_LENGTH = 128;
const NAME_MAX_LENGTH = 126;
const getCamerasIncludes = ['status', 'shareDetails'];

const camerasStore = useCamerasStore();
const sitesStore = useLocationsStore();
const addSiteForm = ref();
const infiniteLoadingRef = ref();
const infiniteLoadingCameras = ref<ApiCameraWithIncludes[]>([]);
const searchText = ref('');
const infiniteLoaderPageSize = 15;
let pageToken = '';
let searchDebounceTimeout: ReturnType<typeof setTimeout> | undefined;
const formData = reactive({
  name: '',
  address: '',
  city: '',
  zip: '',
  region: '',
  country: '',
});
const loading = ref(false);
const isDefault = ref(false);
const showCamsInSite = ref(false);
const cameraIdsToAdd = ref<string[]>([]);
const cameraIdsOnSite = ref<string[]>([]);
const siteLoading = ref(false);
const camerasAlreadyInLocation = ref<ApiCameraWithIncludes[]>([]);
const tabValue = ref<number | undefined>(props.activeTab);
const view = ref<ViewType>('grid');
const location = ref<Location | undefined>(undefined);

const sidebarTitle = computed(() =>
  props.siteId ? t('Site settings') : props.parentId ? t('Add child site') : t('Add site')
);
const saveButtonText = computed(() => (props.siteId ? t('Save changes') : t('Add site')));

async function getCamerasAlreadyInSite() {
  camerasAlreadyInLocation.value = [];
  const res = await camerasStore.getAllCamerasByCustomParam({
    locationId__in: props.siteId,
    'shareDetails.shared': false,
    include: getCamerasIncludes.toString(),
  });
  if (res) {
    camerasAlreadyInLocation.value = res;
    cameraIdsOnSite.value = cameraIdsToAdd.value = res.map((cam) => cam.id);
  }
}

function updateActiveTab(value: number) {
  tabValue.value = value;
}

onMounted(() => {
  addEditSite();
});

onUnmounted(() => {
  addSiteForm.value.reset();
});

watch(
  () => searchText.value,
  () => {
    if (searchText.value === null) return (searchText.value = ''); // handle clearing button
    clearTimeout(searchDebounceTimeout);
    searchDebounceTimeout = setTimeout(() => {
      infiniteLoadingCameras.value = [];
      infiniteLoadingRef.value.stateChanger.reset();
      pageToken = '';
    }, 500);
  }
);

const data = () => ({
  name: formData.name ?? '',
  address: {
    country: formData.country ?? '',
    city: formData.city ?? '',
    region: formData.region ?? '',
    streetAddress: formData.address ?? '',
    postalCode: formData.zip ?? '',
  },
  isDefault: isDefault.value ?? undefined,
});

async function infiniteHandler(state: InfiniteStateChanger) {
  const params: GetCamerasParams = {
    pageToken,
    q: searchText.value.length ? searchText.value : undefined,
    pageSize: infiniteLoaderPageSize,
    'shareDetails.shared': false,
    include: getCamerasIncludes.toString(),
  };
  const { cameras, totalSize, nextPageToken } = await camerasStore.getPagedCameras(params);
  pageToken = nextPageToken;
  infiniteLoadingCameras.value = [...infiniteLoadingCameras.value, ...cameras];
  infiniteLoadingCameras.value.length && state.loaded();
  if (!cameras.length || totalSize === cameras.length || nextPageToken === '') {
    state.complete();
  }
}

const filteredInfiniteLoadingCameras = computed(() => {
  const camerasNotInLocation = infiniteLoadingCameras.value.filter((cam) => !cameraIdsOnSite.value.includes(cam.id));

  return showCamsInSite.value
    ? Array.from(new Set([...camerasAlreadyInLocation.value, ...camerasNotInLocation])) // To show cameras already in location on the top
    : camerasNotInLocation;
});

async function addEditSite() {
  infiniteLoadingCameras.value = [];
  cameraIdsToAdd.value = [];
  pageToken = '';
  infiniteLoadingRef.value && infiniteLoadingRef.value.stateChanger.reset();

  if (props.siteId) {
    // if editing a location
    getCamerasAlreadyInSite();
    getInitialFormData();
  } else {
    // if adding a location
    cameraIdsToAdd.value = [];
    cameraIdsOnSite.value = [];
    isDefault.value = false;
    showCamsInSite.value = false;
  }
}

async function getInitialFormData() {
  siteLoading.value = true;
  location.value = await sitesStore.findLocation(props.siteId);
  siteLoading.value = false;

  formData.name = location.value?.name || '';
  formData.country = location.value?.address?.country || '';
  formData.city = location.value?.address?.city || '';
  formData.region = location.value?.address?.region || '';
  formData.address = location.value?.address?.streetAddress || '';
  formData.zip = location.value?.address?.postalCode || '';
  isDefault.value = !!location.value?.isDefault;
}

async function addSite() {
  const camsToAdd = cameraIdsToAdd.value.filter((camId) => !cameraIdsOnSite.value.includes(camId));
  const locationData = {
    ...data(),
    parentId: props.parentId,
  };
  await sitesStore.addLocation(locationData, camsToAdd);
}

async function editSite() {
  if (!props.siteId) return;
  const camsToAdd = cameraIdsToAdd.value.filter((camId) => !cameraIdsOnSite.value.includes(camId));
  const cameraIdsToRemove = cameraIdsOnSite.value.filter((camId) => !cameraIdsToAdd.value.includes(camId));
  await sitesStore.editLocation({
    ...data(),
    cameraIdsToAdd: camsToAdd,
    cameraIdsToRemove,
    locationId: props.siteId,
  });
}

const isEditFormDirty = () => {
  if (!location.value) return;

  const { address, name } = location.value;
  const isDirty =
    formData.name !== name ||
    formData.city !== address?.city ||
    formData.region !== address.region ||
    formData.address !== address.streetAddress ||
    formData.country !== address.country ||
    formData.zip !== address.postalCode ||
    isDefault.value !== !!location.value.isDefault ||
    cameraIdsToAdd.value.length !== cameraIdsOnSite.value.length;

  return isDirty;
};

async function saveChanges() {
  if (props.siteId && !isEditFormDirty()) return;

  if (!!addSiteForm.value && !addSiteForm.value.validate()) {
    updateActiveTab(FIRST_TAB_INDEX);
    return;
  }

  loading.value = true;

  if (props.siteId) {
    await editSite();
  } else {
    await addSite();
  }

  loading.value = false;

  updateActiveTab(FIRST_TAB_INDEX);

  emit('close');
  emit('updateLocation');
}

function selectCam(id: string, value: string) {
  cameraIdsToAdd.value =
    value === 'add' ? cameraIdsToAdd.value.concat(id) : cameraIdsToAdd.value.filter((camID) => camID !== id);
}

function setDefault() {
  isDefault.value = !isDefault.value;
}
</script>

<style lang="scss">
@import '../../../styles/public/main.scss';

.add-site-sidebar {
  .cameras-list {
    &__switch-row {
      margin-top: 8px;
    }

    .switch-row__switch {
      text-align: left !important;
      color: $primary !important;
      @include subtitle-2;
    }

    &__divider {
      margin-left: -20px;
      max-width: unset;
      width: calc(100% + 48px);
    }

    &__scrollbox {
      & > .v-input {
        margin: 24px 0 6px 16px;
      }
    }

    &__empty {
      margin-top: 80px;
      * {
        text-align: center !important;
      }
      .empty__title {
        @include headline-5;
        color: $primary;
      }
      .empty__text {
        @include body-1;
      }
    }
  }
}
</style>
