import { computed, ref, Ref } from 'vue';
import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/vue-query';

import api from '@eencloud/eewc-components/src/service/api';
import {
  ApiPaginatedApplicationLinksResponse,
  ApplicationLinkParams,
} from '@eencloud/eewc-components/src/service/api-types/applicationLinks';

import { QueryKeys } from '@/queries';

export const useGetPaginatedApplicationLinks = (params: ApplicationLinkParams, enabled: Ref<boolean> = ref(true)) => {
  return useInfiniteQuery<ApiPaginatedApplicationLinksResponse | undefined, Error>({
    queryKey: computed(() => [QueryKeys.GET_APPLICATION_LINKS, params]),
    queryFn: ({ pageParam = '' }) => api.getApplicationLinks({ ...params, pageToken: pageParam as string }),
    enabled,
    getNextPageParam: (lastPage) => {
      return lastPage?.nextPageToken || undefined;
    },
    placeholderData: keepPreviousData,
    initialPageParam: '',
  });
};
