import { DeviceStatusDetails, getDeviceDisplayStatus } from '@/service/helpers';
import { ITableData } from './types';
import { DeviceStatusInfo } from '@/stores';
import { CLASSIC_WEB_APP_URL } from '@/constants';
import router from '@/service/router';

type DeviceStatusIconInfo = { id: string; type: string; statusInfo: DeviceStatusDetails };

/**
 * the function flattens the table data - it will convert the tree structure to a flat structure and will add children to the parent object
 */
export function flattenTableData(inputArray: ITableData[]): ITableData[] {
  const outputArray: ITableData[] = [];

  function flatten(obj: ITableData) {
    outputArray.push(obj);
    if (obj.children && obj.children.length > 0) {
      obj.children.forEach((child: ITableData) => flatten(child));
    }
  }

  inputArray.forEach((obj: ITableData) => flatten(obj));

  return outputArray;
}

/**
 * Handles operations related to status of the device. The status of the device is updated by SSE or Websocket in store.
 *
 * @param {Object} device - The device object containing relevant information - tooltipText and Icon.
 */
export function getDeviceStatus(cameraStatusArray: DeviceStatusInfo[], device: DeviceStatusIconInfo) {
  if (device.type !== 'camera') return device.statusInfo;
  const cam = cameraStatusArray.find((camera) => camera.id === device.id);
  if (cam) {
    return getDeviceDisplayStatus(cam.status?.connectionStatus);
  } else {
    return device.statusInfo;
  }
}

/**
 * Retrieves the classic web URL based on the current hostname.
 *
 * This function extracts the domain from the current window location,
 * checks against a predefined mapping, and returns the corresponding classic web URL.
 * If no match is found, it defaults to 'http://login.eagleeyenetworks.com'.
 *
 * @returns {string} The classic web URL corresponding to the current domain.
 */
export function getClassicWebUrl() {
  const DOMAIN_MAP: Record<string, string> = {
    'capturecloudvms.com': 'http://capturecloudvms.com',
    'mobotixcloud.com': 'http://mobotixcloud.com',
    'wisenetsky.com': 'http://wisenetsky.com',
  };

  const hostname = window.location.hostname;
  const domainParts = hostname.split('.');
  const domain = domainParts.length > 2 ? domainParts.slice(-2).join('.') : hostname;
  return DOMAIN_MAP[domain] || CLASSIC_WEB_APP_URL;
}

export function handleTagClick(tag: string) {
  router.push({ name: 'Tags', params: { id: tag } });
}

export function isValidDeviceESN(esn: string): boolean {
  const DEVICE_ESN_RE = /^[a-f0-9]{8}$/; // Exact match for 8-character hex string
  return DEVICE_ESN_RE.test(esn);
}

export function getFilterParams(searchText?: string) {
  if (!searchText || !searchText?.length) return {};
  if (isValidDeviceESN(searchText)) {
    return {
      id__in: searchText,
    };
  } else {
    return {
      q: searchText,
    };
  }
}
