import { defineStore } from 'pinia';
import { ref } from 'vue';
import { t } from '@/plugins/i18n.ts';
import { capitalizeFirstLetter } from '@/service/helpers';

export type buttonTypes = 'accent' | 'negative' | 'positive' | 'warning' | 'depressed' | 'primary' | 'outlined';

type DialogType = {
  showDialog: boolean;
  icon?: string;
  iconColor?: string;
  titleSlot?: string;
  bodySlot?: string;
  id?: string;
  confirmButtonCallback?(): void;
  confirmCancelButtonCallback?(): void;
  doNotShowNotification?: boolean;
  buttonMessage?: string;
  cancelBtnText?: string;
  name?: string;
  msgType?: 'delete' | 'remove';
  confirmBtnColor?: string;
  cancelBtnColor?: string;
  cancelBtnType?: buttonTypes;
  confirmBtnType?: buttonTypes;
  reverseBtnOrder?: boolean;
  hideConfirmButton?: boolean;
};

export type NotificationType = {
  actionBtnColor?: string;
  actionBtnText?: string;
  body?: string;
  onActionBtnClick?(): void;
  icon: string;
  iconColor: string;
  title: string;
  timeout?: number;
};

export const useMessagingStore = defineStore('messaging', function () {
  const confirmationDialog = ref<DialogType>({ showDialog: false });
  const notification = ref<NotificationType | null>(null);
  const showNoSavedChangesModal = ref(false);
  const unsavedChangesDialog = ref();

  function showConfirmationDialog(payload: {
    type: string;
    callback(): Promise<void>;
    cancelCallback?(): Promise<void>;
    item: {
      id: string;
      name: string;
    };
    msgType: 'delete' | 'remove';
    showDialog?: boolean;
    doNotShowNotification?: boolean;
    fromItem?: {
      name: string;
    };
  }) {
    confirmationDialog.value = {
      showDialog: true,
      icon: '$icon_delete',
      iconColor: 'negative',
      titleSlot: `${capitalizeFirstLetter(payload.msgType)} the ${payload.type}?`,
      bodySlot: createBodySlotHtml(payload.msgType, payload.type, payload.item.name, payload.fromItem?.name),
      id: payload.item.id,
      confirmButtonCallback: payload.callback,
      confirmCancelButtonCallback: payload.cancelCallback,
      buttonMessage: `${payload.msgType} ${payload.type}`,
      name: payload.item.name ?? `${payload.type}`,
      msgType: payload.msgType,
      confirmBtnColor: 'negative',
      cancelBtnColor: 'accent',
      confirmBtnType: 'depressed',
      cancelBtnText: 'Cancel',
      reverseBtnOrder: false,
      doNotShowNotification: payload.doNotShowNotification ?? false,
    };
  }

  function showCustomConfirmationDialog(payload: {
    title: string;
    callback?(): Promise<void>;
    cancelCallback?(): Promise<void>;
    bodyText: string;
    buttonText?: string;
    doNotShowNotification: boolean;
    icon?: string;
    iconColor?: string;
    confirmBtnColor?: string;
    cancelBtnColor?: string;
    cancelBtnType?: buttonTypes;
    confirmBtnType?: buttonTypes;
    name?: string;
    cancelText?: string;
    reverseBtnOrder?: boolean;
    hideConfirmButton?: boolean;
  }) {
    confirmationDialog.value = {
      showDialog: true,
      icon: payload.icon ?? '$icon_delete',
      iconColor: payload.iconColor ?? 'negative',
      titleSlot: payload.title,
      bodySlot: payload.bodyText,
      confirmButtonCallback: payload.callback,
      confirmCancelButtonCallback: payload.cancelCallback,
      buttonMessage: payload.buttonText,
      doNotShowNotification: payload.doNotShowNotification,
      confirmBtnColor: payload.confirmBtnColor ?? 'negative',
      cancelBtnColor: payload.cancelBtnColor ?? 'accent',
      confirmBtnType: payload.confirmBtnType ?? 'depressed',
      cancelBtnType: payload.cancelBtnType,
      name: payload.name,
      cancelBtnText: payload.cancelText ?? 'Cancel',
      reverseBtnOrder: payload.reverseBtnOrder ?? false,
      hideConfirmButton: payload.hideConfirmButton ?? false,
    };
  }

  function showWarningDialog(payload: {
    title: string;
    callback(): Promise<void>;
    cancelCallback?: () => void;
    bodyText: string;
    buttonText?: string;
    doNotShowNotification?: boolean;
    icon?: string;
    iconColor?: string;
    name?: string;
    cancelText?: string;
  }) {
    confirmationDialog.value = {
      showDialog: true,
      icon: payload.icon ?? '$icon_attention',
      iconColor: payload.iconColor ?? 'warning',
      titleSlot: payload.title,
      bodySlot: payload.bodyText,
      confirmButtonCallback: payload.callback,
      confirmCancelButtonCallback: payload.cancelCallback,
      buttonMessage: payload.buttonText ?? 'Continue',
      doNotShowNotification: payload.doNotShowNotification ?? true,
      confirmBtnColor: 'accent',
      confirmBtnType: 'accent',
      cancelBtnType: 'outlined',
      name: payload.name,
      cancelBtnText: payload.cancelText ?? 'Cancel',
      reverseBtnOrder: true,
    };
  }

  function createBodySlotHtml(
    msgType: 'delete' | 'remove',
    type: string,
    itemName: string,
    fromItemName?: string
  ): string {
    itemName = itemName ? `with the name <span class="font-weight-medium">${itemName}</span>` : '';
    fromItemName = fromItemName ? ` from <span class="font-weight-medium">${fromItemName}</span>` : '';
    return `Are you sure you want to ${msgType} the ${type} ${itemName} ${fromItemName}?`;
  }

  function resetConfirmationDialog() {
    confirmationDialog.value = {
      doNotShowNotification: confirmationDialog.value.doNotShowNotification,
      showDialog: false,
    };
  }

  function savedSuccessfully() {
    addNotification({
      iconColor: 'positive',
      icon: '$icon_check_zero',
      title: t('Saved successfully'),
      body: `${t('The changes has been saved')}`,
    });
  }

  function showSuccessMessage(bodyText: string, title = t('Success')) {
    addNotification({
      iconColor: 'positive',
      icon: '$icon_check_zero',
      title: title,
      body: bodyText,
    });
  }

  function showErrorMessage(bodyText: string) {
    addNotification({
      iconColor: 'negative',
      icon: '$icon_attention',
      title: t('Error'),
      body: bodyText,
    });
  }

  function showWarningMessage(bodyText: string) {
    addNotification({
      iconColor: 'negative',
      icon: '$icon_attention',
      title: t('Warning'),
      body: bodyText,
    });
  }

  function addNotification(notificationToSet: NotificationType) {
    notification.value = notificationToSet;
    // Set a longer timeout if there is an action button, otherwise use the default timeout as per the new design
    const timeoutDuration = notificationToSet.actionBtnText ? 10000 : 5000;
    notification.value.timeout = timeoutDuration;
    setTimeout(() => {
      notification.value = null;
    }, timeoutDuration);
  }

  function removeNotification() {
    notification.value = null;
  }

  return {
    confirmationDialog,
    notification,
    showConfirmationDialog,
    resetConfirmationDialog,
    addNotification,
    removeNotification,
    savedSuccessfully,
    showCustomConfirmationDialog,
    showWarningDialog,
    showSuccessMessage,
    showWarningMessage,
    showNoSavedChangesModal,
    unsavedChangesDialog,
    showErrorMessage,
  };
});
