import router from './router';

export function useHelpCenterRedirection() {
  const WINDOW_WIDTH = 400;
  const WINDOW_HEIGHT = 879;
  const CAPTURE_CLOUD = 'capturecloudvms.com';
  const MOBOTIX_CLOUD = 'mobotixcloud.com';
  const WISENET_SKY = 'wisenetsky.com';

  function getDomain() {
    const { hostname } = window.location;
    const domainParts = hostname.split('.');
    return domainParts.length > 2 ? domainParts.slice(-2).join('.') : hostname;
  }

  function getBaseRoute(domain: string) {
    switch (domain) {
      case CAPTURE_CLOUD:
        return 'https://helpcenter.capturecloudvms.com';
      case MOBOTIX_CLOUD:
        return 'https://helpcenter.mobotixcloud.com';
      case WISENET_SKY:
        return 'https://helpcenter.wisenetsky.com';
      default:
        return config.is_production
          ? 'https://helpcenter.eagleeyenetworks.com'
          : 'https://helpcenter.stage.eagleeyenetworks.com';
    }
  }

  function getSanitizedRoute(route: string, id: string) {
    const hasQuery = !!Object.keys(router.currentRoute.query).length;

    if (hasQuery) {
      route = route.split('?')[0];
    }

    return route.replace(`/${id}`, '').replace('#', '').replace('#', '/');
  }

  const domain = getDomain();
  const baseRoute = getBaseRoute(domain);

  const route = window.location.hash;
  const id = router.currentRoute.params.id;
  const language = window.$cookies.get('lang') || 'en-US';
  const leftPosition = window.screen.width - WINDOW_WIDTH;

  const sanitizedRoute = getSanitizedRoute(route, id);

  const finalUrl = `${baseRoute}/help/${language}${sanitizedRoute}`;
  const windowOptions = `width=${WINDOW_WIDTH}, height=${WINDOW_HEIGHT}, top=0, left=${leftPosition}`;

  window.open(finalUrl, 'helpCenterWindow', windowOptions);
}
