import api from '@eencloud/eewc-components/src/service/api';
import {
  ApiAuthentication,
  ApiAuthenticationCode,
  ApiUserWithIncludes,
  GetUsersParams,
  TimeZoneResponse,
  ApiUserSelfUpdate,
  AvailableLanguage,
  ApiUserUpdate,
  ResourceGrants,
  ApiAddUser,
  TrustedClient,
  ApiUserNotificationSettings,
  MessageContext,
} from '@eencloud/eewc-components/src/service/api-types';
import { defineStore } from 'pinia';
import locale from '@/plugins/i18n.ts';
import { ref, computed } from 'vue';
import { useMessagingStore } from '@/stores';
import routePermissionCheck from '@/service/routePermissionCheck.ts';
import router from '@/service/router';
import { userRoleSettings } from '@eencloud/eewc-components/src/service/api-types';

export const useUsersStore = defineStore('User', () => {
  const messagingStore = useMessagingStore();

  const currentUser = ref<ApiUserWithIncludes>();
  const allSupportedTimeZones: TimeZoneResponse[] = [];
  const pagedUsers = ref<ApiUserWithIncludes[]>([]);
  const userContext = ref<MessageContext>();
  const userPreferenceDataForEventsPopover = ref<any>();
  const currentUserDisplayName = computed(() => {
    if (currentUser.value?.firstName || currentUser.value?.lastName) {
      return `${currentUser.value.firstName} ${currentUser.value.lastName}`;
    } else return '';
  });
  const permissions = computed(() => {
    if (currentUser.value?.permissions) {
      return currentUser.value.permissions;
    } else return undefined;
  });
  const currentUserTimezone = computed(() => {
    if (currentUser.value?.timeZone) {
      return currentUser.value.timeZone.timeZone;
    } else return Intl.DateTimeFormat().resolvedOptions().timeZone;
  });

  const currentUserTimeSettings = computed(() => currentUser.value?.timeSettings);
  const hour12 = computed(() => currentUser.value?.timeSettings?.format !== '24h');

  async function getCurrentUser() {
    const include = 'timeZone,permissions,layoutSettings,previewSettings,timeSettings';
    const res = await getUsers({ include }, 'self');
    if (res) {
      currentUser.value = res as ApiUserWithIncludes;
      if (currentUser.value.language && window.$cookies.get('lang') !== 'aa-ER') {
        locale.setLanguage(currentUser.value.language);
      }
      if (!routePermissionCheck()) {
        router.replace('/');
      }
    }
  }

  const getters = {
    // DO NOT USE THIS, will be removed in future. Instead use getUser()
    user: (id: string) => {
      let user: ApiUserWithIncludes[] = [];
      const include =
        'loginDetails,locationSummary,contactDetails,timeZone,support,status,permissions,layoutSettings,previewSettings,effectivePermissions,timeSettings';
      getUsers({ include }, id)
        .then((res) => {
          user = res?.results as ApiUserWithIncludes[];
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
      return user.length ? user[0] : null;
    },
  };

  async function getUsers(params: GetUsersParams, userId?: string) {
    try {
      return await api.getUsers(params, userId);
    } catch (error) {
      console.error(error);
    }
  }

  async function getResourceGrant(userId: string, resourceType: 'camera' | 'location' | 'layout' | 'account') {
    let pageToken: string | undefined;
    let cameraAccess: ResourceGrants[] = [];
    do {
      try {
        const data = await api.getResourceGrant({
          pageSize: 500,
          pageToken,
          resourceType,
          userId,
        });
        if (data) {
          pageToken = data?.nextPageToken;
          cameraAccess = [...cameraAccess, ...(data.results as ResourceGrants[])];
        } else {
          break;
        }
      } catch (error) {
        console.error(error);
      }
    } while (pageToken !== '');
    return cameraAccess;
  }

  async function passwordResetRequests(userId: string) {
    try {
      return await api.passwordResetRequests(userId);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * function fetches all supported timezones from the backend and caches them
   * @returns {TimeZoneResponse[]} List of all supported timezones
   */
  async function getSupportedTimeZones() {
    if (allSupportedTimeZones.length) return allSupportedTimeZones;
    let pageToken: string | undefined;
    let timeZonesList: TimeZoneResponse[] = [];
    let totalSize = 0;
    do {
      try {
        const data = await api.getTimeZones({ pageSize: 500, pageToken });
        if (data) {
          pageToken = data?.nextPageToken;
          totalSize = data.totalSize ?? 0;
          timeZonesList = [...timeZonesList, ...(data.results as TimeZoneResponse[])];
        } else {
          break;
        }
      } catch (error) {
        console.error(error);
      }
    } while (pageToken !== '');
    if (totalSize === timeZonesList.length) {
      allSupportedTimeZones.push(...timeZonesList);
    }
    return timeZonesList;
  }

  async function getAvailableLanguages() {
    let pageToken: string | undefined;
    let languageList: AvailableLanguage[] = [];
    do {
      try {
        const data = await api.getLanguages({ pageSize: 500, pageToken });
        if (data) {
          pageToken = data?.nextPageToken;
          languageList = [...languageList, ...(data.results as AvailableLanguage[])];
        } else {
          break;
        }
      } catch (error) {
        console.error(error);
      }
    } while (pageToken !== '');
    return languageList;
  }

  async function getUserAuthentication() {
    try {
      const res = await api.getUserAuthentication();
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getUserNotificationSettings(userId: string) {
    try {
      const res = await api.getUserNotificationSettings(userId);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }

  async function patchUserNotificationSettings(userId: string, payload: ApiUserNotificationSettings) {
    try {
      const res = await api.patchUserNotificationSettings(userId, payload);
      return res;
    } catch (error) {
      console.error(error);
    }
  }

  async function updateUserAuthentication(params: ApiAuthentication) {
    try {
      const data = await api.updateUserAuthentication(params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async function postResourceGrant(params: ResourceGrants) {
    try {
      const data = await api.postResourceGrant(params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getPagedUsers(params: GetUsersParams) {
    try {
      const res = await getUsers(params);
      pagedUsers.value = res?.results as ApiUserWithIncludes[];
      return {
        totalSize: res?.totalSize,
        nextPageToken: res?.nextPageToken,
        prevPageToken: res?.prevPageToken,
        users: res?.results as ApiUserWithIncludes[],
      };
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllUsersByCustomParam(params: GetUsersParams): Promise<ApiUserWithIncludes[]> {
    let usersList: ApiUserWithIncludes[] = [];
    try {
      let pageToken: string | undefined;
      do {
        const data = await api.getUsers(
          {
            pageToken: pageToken as string,
            pageSize: 500,
            ...params,
            include: params.include ? params.include.toString() : undefined,
          },
          undefined
        );
        if (data) {
          pageToken = data.nextPageToken;
          usersList = [...usersList, ...(data.results as ApiUserWithIncludes[])];
        } else break;
      } while (pageToken !== '');
    } catch (error) {
      console.error(error);
    }
    return usersList;
  }

  async function deleteResourceGrant(params: ResourceGrants) {
    try {
      const data = await api.deleteResourceGrant(params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async function patchUser(
    userId: string,
    params: ApiUserUpdate | ApiUserSelfUpdate,
    showSuccessMessage: boolean = true
  ) {
    try {
      const status = await api.patchUser(userId, params);
      if (showSuccessMessage && status === 204 && !!params['timeSettings']) {
        messagingStore.savedSuccessfully();
        getCurrentUser();
      }
      return status;
    } catch (error) {
      console.error(error);
    }
  }
  async function verifyAuthenticationCode(params: ApiAuthenticationCode) {
    try {
      const data = await api.verifyAuthenticationCode(params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getUser(userId: string, params: GetUsersParams) {
    const res = await getUsers(params, userId);
    return res as ApiUserWithIncludes;
  }

  function updateCurrentUser(data: ApiUserWithIncludes) {
    currentUser.value = data;
  }

  async function deleteUser(userId: string) {
    try {
      const data = await api.deleteUser(userId);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  //add new user
  async function addUser(params: ApiAddUser) {
    try {
      const data = await api.postUser(params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getTrustedDevices() {
    let trustedDevices: TrustedClient[] = [];
    let pageToken: string | undefined;
    try {
      while (pageToken !== '') {
        const data = await api.getTrustedDevices({ include: 'device,browser,operatingSystem', pageToken });
        if (data) {
          pageToken = data?.nextPageToken;
          trustedDevices = [...trustedDevices, ...(data.results as TrustedClient[])];
        } else break;
      }
      return trustedDevices;
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteTrustedDevice(deviceId: string) {
    try {
      return await api.deleteTrustedDevice(deviceId);
    } catch (error) {
      console.error(error);
    }
  }

  async function getRoleSettings(userId: string) {
    return await api.getUserRoleSettings(userId);
  }

  async function updateRoleSettings(userId: string, enabled: boolean) {
    const params: userRoleSettings = {
      enabled,
    };
    return await api.updateUserRoleSettings(userId, params);
  }

  async function getUserContext() {
    const res = await api.getUserMessageContext();
    userContext.value = res ?? {};
  }

  async function getUserPreference(preferenceId: string) {
    return await api.getUserPreference(preferenceId);
  }

  async function updateUserPreference(preferenceId: string, params: any) {
    return await api.updateUserPreference(preferenceId, params);
  }

  async function deleteUserPreference(preferenceId: string) {
    return await api.deleteUserPreference(preferenceId);
  }

  return {
    currentUser,
    userContext,
    pagedUsers,
    getCurrentUser,
    currentUserTimezone,
    currentUserDisplayName,
    permissions,
    getSupportedTimeZones,
    updateUserAuthentication,
    getUserAuthentication,
    getUserNotificationSettings,
    patchUserNotificationSettings,
    patchUser,
    getAvailableLanguages,
    passwordResetRequests,
    verifyAuthenticationCode,
    getUser,
    updateCurrentUser,
    getResourceGrant,
    postResourceGrant,
    deleteResourceGrant,
    deleteUser,
    addUser,
    getTrustedDevices,
    deleteTrustedDevice,
    currentUserTimeSettings,
    hour12,
    getPagedUsers,
    getAllUsersByCustomParam,
    getRoleSettings,
    updateRoleSettings,
    getUserContext,
    getUserPreference,
    updateUserPreference,
    deleteUserPreference,
    userPreferenceDataForEventsPopover,
    ...getters,
  };
});
