<template>
  <eewc-app-switcher
    v-if="applicationLinksData?.length"
    :menu-items="applicationLinksData"
    :icon="APP_SWITCHER_ICON"
    :disabled="isFetchingApplicationLinks"
    @infinite="infiniteHandler"
    @click="handleAppSwitcherItemClick"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import _ from 'lodash';

import {
  ApiApplicationLink,
  ApiApplicationType,
} from '@eencloud/eewc-components/src/service/api-types/applicationLinks';

import { useGetPaginatedApplicationLinks } from '@/queries/applicationSwitcher/useGetApplicationLinks';
import { IntersectionStateChanger } from '@eencloud/eewc-components/src/utils/types';

const APP_SWITCHER_ICON = '$icon_nine_dots';
const ICON_MAP: Record<string, string> = {
  'Access Control': '$icon_access',
  Video: '$icon_brivo_camera',
};

let pageToken: string | undefined = '';

const APPLICATION_LINKS_PARAMS = {
  type: ApiApplicationType.AppLauncher,
  sort: '+name',
  pageToken,
  pageSize: 50,
};

const applicationLinksData = computed(() => {
  const flattenedData = applicationLinksResults.value?.pages.flatMap((page) => page?.results) ?? [];

  return flattenedData.map((item) => ({
    ...item,
    icon: item?.category ? ICON_MAP[item.category] : undefined,
  }));
});

const {
  data: applicationLinksResults,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isFetching: isFetchingApplicationLinks,
} = useGetPaginatedApplicationLinks(APPLICATION_LINKS_PARAMS);

async function infiniteHandler(state: IntersectionStateChanger) {
  if (hasNextPage.value && !isFetchingNextPage.value) {
    await fetchNextPage();
    state.loaded();
  }

  if (!hasNextPage.value || !applicationLinksData.value.length) {
    state.completed();
  }
}

function handleAppSwitcherItemClick(item: ApiApplicationLink) {
  window.open(item.accessUrl, '_blank');
}
</script>
