<template>
  <div class="camera-states">
    <v-icon
      v-if="!hideIcon"
      :size="statusContent(status)?.iconSize"
      :color="statusContent(status)?.iconColor"
      :class="[{ 'mb-2': iconSize !== 'small' }]"
    >
      {{ statusContent(status)?.icon }}
    </v-icon>
    <div
      :class="[
        'camera-states__time',
        { 'camera-states__time--large': iconSize === 'large' },
        { 'camera-states__time--small': iconSize === 'small' },
        { 'camera-states__time--extra-space': hideIcon },
      ]"
    >
      {{ statusContent(status)?.videoText }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { DeviceStatus } from '@eencloud/eewc-components/src/service/api-types';
import { t } from '@/plugins/i18n.ts';
import { getDeviceDisplayStatus } from '@/service/helpers';

const props = withDefaults(
  defineProps<{
    status?: DeviceStatus;
    iconSize?: 'small' | 'medium' | 'large';
    offlineDuration?: string | null;
    hideIcon?: boolean;
    feedError?: boolean;
    cameraId?: string;
    previewPermission?: boolean;
    livePermission?: boolean;
    showOnlineIcon?: boolean;
  }>(),
  {
    showOnlineIcon: false,
  }
);

function statusContent(connectionStatus?: DeviceStatus) {
  const iconSize = getIconSize(props.iconSize);
  let deviceStatusDisplayDetails;

  switch (connectionStatus) {
    case 'online':
      if (!props.previewPermission) {
        deviceStatusDisplayDetails = getDeviceDisplayStatus('viewPreviewVideo');
      } else if (!props.livePermission) {
        deviceStatusDisplayDetails = getDeviceDisplayStatus('livePermission');
      } else if (props.feedError) {
        deviceStatusDisplayDetails = getDeviceDisplayStatus('error');
      } else if (props.showOnlineIcon) {
        deviceStatusDisplayDetails = getDeviceDisplayStatus(connectionStatus);
      }
      break;
    case 'offline':
    case 'error':
    case 'off':
    case 'deviceOffline':
    case 'bridgeOffline':
      deviceStatusDisplayDetails = getDeviceDisplayStatus(connectionStatus);
      if (props.offlineDuration) {
        deviceStatusDisplayDetails.videoText = `${deviceStatusDisplayDetails.videoText} ${t('for')} ${
          props.offlineDuration
        }`;
      }
      break;
  }

  return {
    ...deviceStatusDisplayDetails,
    iconSize,
  };
}

function getIconSize(iconSize: 'small' | 'medium' | 'large' | undefined) {
  switch (iconSize) {
    case 'small':
      return 32;
    case 'medium':
      return 48;
    case 'large':
      return 80;
    default:
      return 80;
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/public/main.scss';

.camera-states {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;

  &__time {
    @include body-2;
    color: $primaryWhite;
    text-align: center;
    &--large {
      @include body-1;
    }
    &--small {
      display: none;
    }
    &--extra-space {
      margin-top: 64px;
    }
  }
}
</style>
