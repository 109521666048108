<template>
  <eewc-snackbar-notification
    v-if="messagingStore.notification"
    :body="messagingStore.notification.body"
    :icon-color="messagingStore.notification.iconColor"
    :icon="messagingStore.notification.icon"
    :style="wrapperStyle"
    :title="messagingStore.notification.title"
    :transition-timeout="messagingStore.notification.timeout"
    @closeNotificationDialog="messagingStore.removeNotification()"
  >
    <template #action>
      <eewc-button-common
        v-if="messagingStore.notification.actionBtnText"
        :color="messagingStore.notification.actionBtnColor"
        class="notification__action-button"
        type="clear"
        @click="handleActionClick"
      >
        {{ messagingStore.notification.actionBtnText }}
      </eewc-button-common>
    </template>
  </eewc-snackbar-notification>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useMessagingStore } from '@/stores';

const BOTTOM_OFFSET = 20;
const DEFAULT_POSITION = { bottom: `${BOTTOM_OFFSET}px` };
const NAVIGATION_MENU = '.v-navigation-drawer';
const BOTTOM_BAR_TYPE = {
  HB: '.history-browser__timeline-container',
  SETTINGS: '.settings-page__footer__wrapper',
};

const messagingStore = useMessagingStore();

const sideNavWidth = ref(0);

function handleActionClick() {
  messagingStore.notification?.onActionBtnClick?.();
  messagingStore.removeNotification();
}

function getBoundRect(selector: string): DOMRect | null {
  const element = document.querySelector(selector);
  return element ? element.getBoundingClientRect() : null;
}

// Observe side navigation width changes
let resizeObserver: ResizeObserver | null = null;
onMounted(() => {
  const sideNav = document.querySelector(NAVIGATION_MENU);
  if (sideNav) {
    resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        sideNavWidth.value = entry.contentRect.width;
      }
    });
    resizeObserver.observe(sideNav);
  }
});

onUnmounted(() => {
  resizeObserver?.disconnect();
});

const wrapperStyle = computed(() => {
  const timelineRect = getBoundRect(BOTTOM_BAR_TYPE.HB);
  const settingsRect = getBoundRect(BOTTOM_BAR_TYPE.SETTINGS);
  if (messagingStore.notification) {
    let style: { bottom: string; left?: string } = { ...DEFAULT_POSITION };

    // Set left position based on side navigation width
    if (sideNavWidth.value) {
      style.left = `${sideNavWidth.value / 2}px`;
    }

    // Position the snackbar above the timeline or settings footer if they are visible
    if (timelineRect) {
      style.bottom = `${window.innerHeight - timelineRect.top + BOTTOM_OFFSET}px`;
    } else if (settingsRect) {
      style.bottom = `${window.innerHeight - settingsRect.top + BOTTOM_OFFSET}px`;
    }

    return style;
  }
});
</script>
