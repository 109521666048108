import { Route } from '@/types/index';

const AlertsContainer = () => import('@/pages/AlertManager/AlertsContainer.vue');
const RulesContainer = () => import('@/pages/AlertManager/RulesContainer.vue');
const AddRuleContainer = () => import('@/pages/AlertManager/AddRuleContainer.vue');
const AlertActions = () => import('@/pages/AlertManager/Actions/Actions.vue');
const AddActionContainer = () => import('@/pages/AlertManager/Actions/AddActionContainer.vue');

const routes: Route[] = [
  {
    path: '/automations/alerts',
    name: 'Automations alerts',
    component: AlertsContainer,
    meta: {
      auth: true,
      permissions: ['administrator', 'addEditBridgesCameras', 'viewVSP'],
      rootRoute: true,
    },
  },

  {
    path: '/automations/rules',
    name: 'Automations rules',
    component: RulesContainer,
    meta: {
      auth: true,
      permissions: ['administrator', 'addEditBridgesCameras'],
      rootRoute: true,
    },
  },
  {
    path: '/automations/rules/add',
    name: 'Add rule',
    component: AddRuleContainer,
    meta: {
      auth: true,
      permissions: ['administrator', 'addEditBridgesCameras'],
      alertManagerSubRoute: true,
    },
  },
  {
    path: '/automations/rules/:ruleId',
    name: 'Edit rule',
    component: AddRuleContainer,
    meta: {
      auth: true,
      permissions: ['administrator', 'addEditBridgesCameras'],
      alertManagerSubRoute: true,
    },
  },
  {
    path: '/automations/actions',
    name: 'Alert actions',
    component: AlertActions,
    meta: {
      auth: true,
      permissions: ['administrator', 'addEditBridgesCameras', 'editVSPRule'],
      rootRoute: true,
    },
  },
  {
    path: '/automations/actions/add',
    name: 'Add action',
    component: AddActionContainer,
    meta: {
      auth: true,
      permissions: ['administrator', 'addEditBridgesCameras', 'editVSPRule'],
      alertManagerSubRoute: true,
    },
  },
  {
    path: '/automations/actions/:actionId',
    name: 'Edit action',
    component: AddActionContainer,
    meta: {
      auth: true,
      permissions: ['administrator', 'addEditBridgesCameras', 'editVSPRule'],
      alertManagerSubRoute: true,
    },
  },
];

export default routes;
