import api from '@eencloud/eewc-components/src/service/api';
import { t } from '@/plugins/i18n.ts';
import {
  ApiAccountWithIncludes,
  ApiAccountCapabilities,
  ApiAccountPatch,
  ApiAccountCredentials,
  GetAccountsParams,
} from '@eencloud/eewc-components/src/service/api-types/account';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useMessagingStore } from '@/stores';
import { CLASSIC_WEB_APP_URL } from '@/constants';

export const useAccountStore = defineStore('account', () => {
  const messagingStore = useMessagingStore();
  const account = ref<ApiAccountWithIncludes>();
  const isAccountPermissionDenied = ref<boolean>(false);
  const accountCapabilities = ref<ApiAccountCapabilities>();
  const isEEN = ref<boolean>(false);
  const applyBranding = ref<boolean>(false);

  const actions = {
    async fetchAccount(params: GetAccountsParams) {
      const response = await api.getAccount(params);
      if (response) {
        account.value = response;
        if (response.brandingInfo?.isWhiteLabelEnabled === false && response.brandingInfo?.authHostname) {
          // Branding will be applied under the following conditions:
          // 1. White label feature is not enabled.
          // 2. The authHostname is not null.
          // This approach is taken to accommodate a scenario where both white label and branding features are enabled.
          // The handling of this particular scenario will be implemented in the future when we develop the white label feature.
          applyBranding.value = true;
        }

        if (
          account.value?.brandingInfo?.isWhiteLabelEnabled === false &&
          account.value?.brandingInfo?.authHostname === null &&
          localStorage.getItem('domain') === 'EEN'
        ) {
          isEEN.value = true;
        }
        if (applyBranding.value && response.brandingInfo?.authHostname) {
          config.authServer = 'https://' + response.brandingInfo.authHostname;
        }
      }
    },

    async fetchAccountWithCustomParam(params: GetAccountsParams) {
      return await api.getAccount(params); // update accounts details in store to apply changes in all pages without webapp refresh
    },

    async patchAccount(userId: string, params: ApiAccountPatch) {
      try {
        const status = await api.patchAccount(userId, params);
        return status;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchAccountCapabilities() {
      const response = await api.getAccountCapabilities();
      if (response) {
        accountCapabilities.value = response;
      }
    },
    updateAccountPermissionDeniedStatus(status: boolean) {
      isAccountPermissionDenied.value = status;
      if (status) {
        window.gtag('event', 'reseller_user');
        window.open(CLASSIC_WEB_APP_URL, '_self');
      } else {
        window.gtag('event', 'end_user');
      }
    },

    async fetchAccountCameraCredentials() {
      let pageToken: string | undefined;
      const cameraCredentials: ApiAccountCredentials[] = [];
      while (pageToken !== '') {
        const data = await api.fetchAccountCameraCredentials('self', {
          pageToken,
          pageSize: 500,
        });
        if (data) {
          pageToken = data.nextPageToken;
          cameraCredentials.push(...(data.results as ApiAccountCredentials[]));
        } else break;
      }
      // Since the backend doesn't support sorting and each GET call returns a randomized results
      cameraCredentials.sort((a, b) => a.username.localeCompare(b.username));
      return cameraCredentials;
    },

    async addAccountCameraCredentials(credential: ApiAccountCredentials): Promise<number | undefined> {
      return await api.addAccountCameraCredentials('self', {
        username: credential.username,
        password: credential.password,
      });
    },

    async deleteAccountCameraCredentials(credential: ApiAccountCredentials): Promise<number | undefined> {
      return await api.deleteAccountCameraCredentials('self', credential.id);
    },

    updateAccount(updatedValue: ApiAccountWithIncludes) {
      account.value = updatedValue;
    },
  };
  return {
    account,
    accountCapabilities,
    isAccountPermissionDenied,
    isEEN,
    applyBranding,
    ...actions,
  };
});
