import { t } from '@/plugins/i18n';
import { ApiDewarpConfigComposite, ApiDewarpConfigPane } from '@eencloud/eewc-components/src/service/api-types';

const basePane: ApiDewarpConfigPane = {
  lookAt: { x: 1, y: -1, z: 0 },
  fov: 70,
  shader: 'sphere',
  width: '50%',
  height: '50%',
  x: '0%',
  y: '0%',
};

function getDefaultQuad(): ApiDewarpConfigComposite {
  const paneOne = { ...basePane, lookAt: { x: 1, y: -1, z: 0 } };
  const paneTwo = { ...basePane, x: '50%', lookAt: { x: 1, y: -1, z: 1 } };
  const paneThree = { ...basePane, y: '50%', lookAt: { x: 1, y: -1, z: -1 } };
  const paneFour = { ...basePane, x: '50%', y: '50%', lookAt: { x: -1, y: -1, z: -1 } };

  return {
    panes: [paneOne, paneTwo, paneThree, paneFour],
    id: 'default-quad',
    name: t('Quad'),
  };
}

function getDefaultPanorama(): ApiDewarpConfigComposite {
  const pane = { ...basePane };
  pane.lookAt = { x: 0.707, y: -0.707, z: 0.0 };
  pane.fov = 180;
  pane.shader = 'panorama';
  pane.width = '100%';
  pane.height = '50%';
  pane.x = '0%';
  pane.y = '0%';

  const paneTwo = { ...pane, y: '50%', lookAt: { x: -0.707, y: 0.707, z: 0 } };

  return {
    panes: [pane, paneTwo],
    id: 'default-double',
    name: t('Panorama'),
  };
}

export function getDefaultSingle(): ApiDewarpConfigComposite {
  const pane = { ...basePane };
  pane.lookAt = { x: 1, y: -1, z: 0 };
  pane.fov = 70;
  pane.shader = 'sphere';
  pane.width = '100%';
  pane.height = '100%';
  pane.x = '0%';
  pane.y = '0%';

  return {
    panes: [pane],
    id: 'default-single',
    name: t('Single'),
  };
}

function getDefaultFisheye(): ApiDewarpConfigComposite {
  return {
    panes: [],
    id: 'default-fisheye',
    name: t('Fisheye'),
  };
}

export function getDefaultComposites(): ApiDewarpConfigComposite[] {
  return [getDefaultQuad(), getDefaultPanorama(), getDefaultSingle(), getDefaultFisheye()];
}

/*
 * Private function that translates an x, y coord into a
 * pane_idx.
 *
 * @param selectedComposite The selected composite
 * @param mouseEvent The mouse event
 * @param canvas The canvas
 *
 * @return the new x coord, y coord and pane index
 */
export function translateCoords(
  selectedComposite: ApiDewarpConfigComposite,
  mouseEvent: MouseEvent,
  canvas: HTMLCanvasElement
) {
  const x = mouseEvent.offsetX;
  const y = mouseEvent.offsetY;
  const width = mouseEvent.target.clientWidth;
  const height = mouseEvent.target.clientHeight;

  //Determine composite width and height
  let cwidth = 0;
  let cheight = 0;
  const panes = selectedComposite.panes;
  const firstPaneWidth = Number(panes?.[0]?.width);
  const firstPaneHeight = Number(panes?.[0]?.height);

  switch (panes?.length) {
    case 1:
      cwidth = !isNaN(firstPaneWidth) ? firstPaneWidth : canvas.width;
      cheight = !isNaN(firstPaneHeight) ? firstPaneHeight : canvas.height;
      break;
    case 2:
      cwidth = !isNaN(firstPaneWidth) ? firstPaneWidth : canvas.width;
      cheight = !isNaN(firstPaneHeight) ? firstPaneHeight * 2 : canvas.height;
      break;

    case 4:
      cwidth = !isNaN(firstPaneWidth) ? firstPaneWidth * 2 : canvas.width;
      cheight = !isNaN(firstPaneHeight) ? firstPaneHeight * 2 : canvas.height;
      break;
  }
  //Calculate percentage x, y
  let px = x / width;
  let py = y / height;

  //Translate percentage x y to composite x y
  let ex = px * cwidth;
  let ey = py * cheight;

  //Determine which pane is hit
  let pane_idx = 0;
  switch (panes?.length) {
    case 2:
      if (py > 0.5) {
        pane_idx = 1;
      }
      break;

    case 4:
      if (px >= 0.5 && py < 0.5) {
        pane_idx = 1;
        ex = ex % (cwidth / 2);
      } else if (px < 0.5 && py >= 0.5) {
        pane_idx = 2;
        ey = ey % (cheight / 2);
      } else if (px >= 0.5 && py >= 0.5) {
        pane_idx = 3;
        ex = ex % (cwidth / 2);
        ey = ey % (cheight / 2);
      }

      break;
  }

  return { x: ex, y: ey, paneId: pane_idx };
}

const PANE_SINGLE = 1;
const PANE_PANOROMA = 2;
const PANE_QUAD = 4;
const HALF_SIZE = 0.5;
const FULL_SIZE = 1.0;
const INITIAL_POSITION = 0;
const FULL_PERCENT_VALUE = 100;

/*
 * Helper function that ensures:
 * pane of size 1 is 100% height and width
 * pane of size 2 is 50% height and 100% width
 * pane of size 4 is 50% height and 50% width
 * pane of size 4 set correct position of pane
 *
 * @param panes The panes to normalize
 */
export function normalizePaneDimensionsToPercentage(panes: ApiDewarpConfigPane[]) {
  const heightRatio = panes.length > PANE_SINGLE ? HALF_SIZE : FULL_SIZE;
  const widthRatio = panes.length > PANE_PANOROMA ? HALF_SIZE : FULL_SIZE;
  let panelsPositionsXArr: number[];
  let panelsPositionsYArr: number[];
  if (panes.length === PANE_QUAD) {
    panelsPositionsXArr = [INITIAL_POSITION, HALF_SIZE, INITIAL_POSITION, HALF_SIZE];
    panelsPositionsYArr = [INITIAL_POSITION, INITIAL_POSITION, HALF_SIZE, HALF_SIZE];
  }

  //Iterate on the panes
  panes.forEach((pane, i) => {
    pane.width = (FULL_PERCENT_VALUE * widthRatio).toString() + '%';
    pane.height = (FULL_PERCENT_VALUE * heightRatio).toString() + '%';
    if (panelsPositionsXArr && panelsPositionsYArr) {
      pane.x = (FULL_PERCENT_VALUE * panelsPositionsXArr[i]).toString() + '%';
      pane.y = (FULL_PERCENT_VALUE * panelsPositionsYArr[i]).toString() + '%';
    }
  });
}
