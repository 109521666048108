import { defineStore } from 'pinia';
import api from '@eencloud/eewc-components/src/service/api';
import {
  OverlayId,
  RecordedImage,
  RecordedImageFieldValues,
  RecordedImageParams,
} from '@eencloud/eewc-components/src/service/api-types';
import { reactive, ref, set, del } from 'vue';
import { get } from 'lodash';
import { useEventsStore, useMediaShortcutStore } from '@/stores';
import { QueryKeys } from '@/queries';
import { useQueryClient } from '@tanstack/vue-query';

interface requestConfig {
  fillShallowRecording?: boolean;
  deviceId: string;
  startTimestamp: string;
  endTimestamp?: string;
  customPageSize?: number;
  include?: string;
  type?: 'preview' | 'main';
  mediaType?: 'video' | 'image';
  coalesce?: boolean;
}

export const useMediaStore = defineStore('media', () => {
  const queryClient = useQueryClient();
  const recordedImageCancelToken = reactive<{
    [key: string]: AbortController;
  }>({});
  const stopSearchingFutureRecordings = ref(false);
  const recordedImageFieldValues = reactive<{ [key: string]: OverlayId }>({});
  // key is cameraId, value is svg string
  const overlaySvgs = reactive<{ [key: string]: string }>({});

  function setOverlaySvg(cameraId: string, svg: string) {
    set(overlaySvgs, cameraId, svg);
  }

  function cleanOverlaySvg(cameraId: string) {
    del(overlaySvgs, cameraId);
  }

  function cleanOverlaySvgs() {
    for (const key in overlaySvgs) {
      del(overlaySvgs, key);
    }
  }

  /**
   * Retrieves the field values for a given device ID.
   * It checks if the field values are already available and returns them if so.
   * Otherwise, it makes an API call to fetch the field values and stores them for future use.
   *
   * @param deviceId - The ID of the device.
   * @returns The field values for the specified device ID, or undefined if not found.
   */
  async function getRecordedImageFieldValues(deviceId: string) {
    try {
      // if we already have the field values for the deviceId , return them
      if (get(recordedImageFieldValues, deviceId)) return get(recordedImageFieldValues, deviceId);
      const res = await api.fetchRecordedImageFieldValues(deviceId, { include: 'overlayId' });
      if (!res) return undefined;
      set(recordedImageFieldValues, deviceId, res);
      return res;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Retrieves the overlay IDs that include the specified device ID.
   *
   * @param deviceId - The ID of the device.
   * @returns comma seperated string of intersection of overlay IDs that includeng of intersection of ov or undefined if no overlay IDs include the device ID.
   */
  async function getOverlayIdIncludeParams(deviceId: string): Promise<string | undefined> {
    const recordedImageFieldValues = await getRecordedImageFieldValues(deviceId);
    if (!recordedImageFieldValues) return undefined;
    const visibleEventTypes = useEventsStore().visibleEventTypes;
    const overlayIds = recordedImageFieldValues.overlayId.map((overlay: OverlayId) => overlay.id);
    const overlayIdsIntersection = overlayIds.filter((overlayId: string) => visibleEventTypes.includes(overlayId));
    if (!overlayIdsIntersection.length) return undefined;
    return overlayIdsIntersection.toString();
  }

  /**
   * Fetches a recorded image based on the provided parameters.
   * This function uses TanStack Query's queryClient directly for caching.
   *
   * @param params - The parameters required to fetch the recorded image.
   * @param cancelPrevReq - A boolean indicating whether to cancel the previous request for the same featureId. Defaults to true.
   * @param controller - An optional AbortController to handle request cancellation.
   * @param featureId - This ensures cancelling the previous request logic does not overlap between different features.
   * @returns A promise that resolves to the recorded image or undefined if an error occurs.
   */
  async function getRecordedImage(
    params: RecordedImageParams,
    cancelPrevReq = true,
    controller?: AbortController,
    featureId?: string
  ): Promise<RecordedImage | undefined> {
    try {
      // if we have a featureId, we need to cancel the previous request for the same featureId
      const uniqueKey = `${params.deviceId}-${featureId}`;
      if (recordedImageCancelToken[uniqueKey] && cancelPrevReq) {
        recordedImageCancelToken[uniqueKey].abort();
      }
      recordedImageCancelToken[uniqueKey] = controller as AbortController;

      // Try to get from cache first
      const queryKey = [QueryKeys.GET_RECORDED_IMAGE, params, featureId];
      const cachedData = queryClient.getQueryData<RecordedImage>(queryKey);
      if (cachedData) {
        return cachedData;
      }

      // If not in cache, fetch and cache the data
      const res = await api.getRecordedImage(params, controller?.signal);
      if (res) {
        // Cache the successful response
        await queryClient.setQueryData(queryKey, res);
        return res;
      }

      // Retry once if first attempt fails
      const retryRes = await api.getRecordedImage(params, controller?.signal);
      if (retryRes) {
        await queryClient.setQueryData(queryKey, retryRes);
      }
      return retryRes;
    } catch (error) {
      console.error(error);
    }
    return undefined;
  }

  async function getMediaList(requestConfig: requestConfig) {
    const requestPayload = {
      deviceId: requestConfig.deviceId,
      startTimestamp__gte: requestConfig.startTimestamp,
      endTimestamp__lte: requestConfig.endTimestamp,
      pageToken: undefined as string | undefined,
      pageSize: requestConfig.customPageSize ?? 500,
      type: requestConfig.type ?? 'main',
      mediaType: requestConfig.mediaType ?? 'video',
      include: requestConfig.include || 'mp4Url',
      coalesce: requestConfig.coalesce ?? true,
    };
    try {
      const data = await api.getMedia(requestPayload);
      return data?.results;
    } catch (error) {
      console.log(error);
    }
  }

  async function getLiveImage(deviceId: string) {
    try {
      const res = await api.getLiveImage(deviceId);
      if (res?.data) {
        return res as { data: ArrayBuffer; timestamp: string };
      }
    } catch (error) {
      console.log(error);
    }
  }

  return {
    getRecordedImage,
    getMediaList,
    getLiveImage,
    stopSearchingFutureRecordings,
    getRecordedImageFieldValues,
    getOverlayIdIncludeParams,
    recordedImageFieldValues,
    overlaySvgs,
    setOverlaySvg,
    cleanOverlaySvg,
    cleanOverlaySvgs,
  };
});
