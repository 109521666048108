import api from '@eencloud/eewc-components/src/service/api';
import {
  ApiBridgeCreate,
  ApiBridgeUpdate,
  ApiBridgeWithIncludes,
  SwapBridgeParams,
  GetBridgeId,
  GetBridgeParams,
} from '@eencloud/eewc-components/src/service/api-types/bridges';
import { Event, DeviceStatus } from '@eencloud/eewc-components/src/service/api-types';
import { t } from '@/plugins/i18n.ts';
import { defineStore } from 'pinia';
import { useMessagingStore, useTagsStore } from '@/stores';
import { PatchBridgeSettings } from '@eencloud/eewc-components/src/service/api-types/bridgeSettings';

interface State {
  bridges: ApiBridgeWithIncludes[];
  allBridgesByCustomParam: ApiBridgeWithIncludes[];
  pagedBridges: ApiBridgeWithIncludes[];
  loadingBridges: boolean | undefined;
  messagingStore: ReturnType<typeof useMessagingStore>;
  tagsStore: ReturnType<typeof useTagsStore>;
}

export const useBridgesStore = defineStore('bridges', {
  state: (): State => ({
    bridges: [],
    allBridgesByCustomParam: [],
    messagingStore: useMessagingStore(),
    tagsStore: useTagsStore(),
    loadingBridges: undefined,
    pagedBridges: [],
  }),
  actions: {
    /**
     * the function will fetch all brdiges based on GetBridgeParams params, currently it will be used to fetch bridges for a location
     * but in future it can be extended to other things
     * Note - the include param does not have all properties, as bridge object for a location does not require all of the properties
     * @param params GetBridgeParams
     */
    async getAllBridgesByCustomParam(params: GetBridgeParams) {
      let bridgesList: ApiBridgeWithIncludes[] = [];
      this.allBridgesByCustomParam = [];
      try {
        let pageToken: string | undefined;
        do {
          const data = await api.getBridges({
            pageToken,
            pageSize: 500,
            ...params,
            include: params.include ? params.include.toString() : undefined,
          });
          if (data) {
            pageToken = data.nextPageToken;
            bridgesList = [...bridgesList, ...(data.results as ApiBridgeWithIncludes[])];
          } else break;
        } while (pageToken !== '');
      } catch (error) {
        console.error(error);
      }
      this.allBridgesByCustomParam = bridgesList;
      return bridgesList;
    },

    async getBridgeSettings(bridgeId: GetBridgeId) {
      try {
        return await api.getBridgeSettings(bridgeId);
      } catch (error) {
        console.error(error);
      }
    },

    async updateBridgeSettings(bridgeId: GetBridgeId, payload: PatchBridgeSettings) {
      return await api.patchBridgeSettings(bridgeId, payload);
    },

    async getBridge(bridgeId: GetBridgeId, params?: GetBridgeParams) {
      try {
        return await api.getBridge(bridgeId, params);
      } catch (error) {
        console.error(error);
      }
    },

    async getPagedBridges(params: GetBridgeParams) {
      let bridges: ApiBridgeWithIncludes[] = [];
      let nextPageToken = '';
      let prevPageToken = '';
      let totalSize = 0;
      try {
        const data = await api.getBridges(params);
        if (data) {
          nextPageToken = data?.nextPageToken ?? '';
          prevPageToken = data?.prevPageToken ?? '';
          totalSize = data.totalSize ?? 0;
          bridges = data.results as ApiBridgeWithIncludes[];
        }
      } catch (error) {
        console.error(error);
      }
      return { bridges, totalSize, nextPageToken, prevPageToken };
    },

    async deleteBridge(bridgeId: GetBridgeId) {
      try {
        await api.deleteBridge(bridgeId);
        this.allBridgesByCustomParam = this.allBridgesByCustomParam.filter((bridge) => bridge.id !== bridgeId);
      } catch (error) {
        console.error(error);
      }
    },

    async swapBridge(params: SwapBridgeParams, newBridgeName: string) {
      const request = await api.swapBridge(params);
      if (request) {
        this.messagingStore?.addNotification({
          iconColor: 'positive',
          icon: '$icon_check_zero',
          title: t(`Bridge added`),
          body: t('{deviceName} is now in the My devices list', {
            deviceName: newBridgeName,
          }),
        });
      }
      return request;
    },

    async addBridge(payload: ApiBridgeCreate) {
      const data = await api.addBridge(payload);

      if (data) {
        this.messagingStore?.addNotification({
          iconColor: 'positive',
          icon: '$icon_check_zero',
          title: t(`Bridge added`),
          body: `${data.name} ${t('has been successfully added')}`,
        });

        return data;
      }
    },
    async updateBridge(payload: ApiBridgeUpdate & { bridgeId: GetBridgeId }) {
      try {
        const { bridgeId, ...newPayload } = payload;
        const status = await api.updateBridge({ bridge: newPayload, bridgeId });
        if (status === 204) {
          this.tagsStore.resetPagedTags();
        }
        return status;
      } catch (error) {
        console.error(error);
      }
    },

    updateBridgeStatus(deviceId: string, status: DeviceStatus) {
      this.updateBridgeArray(this.pagedBridges, deviceId, status);
      this.updateBridgeArray(this.allBridgesByCustomParam, deviceId, status);
    },

    updateBridgeArray(cameraArray: ApiBridgeWithIncludes[], deviceId: string, status: DeviceStatus) {
      const cameraIndex = cameraArray.findIndex((cam) => cam.id === deviceId);
      if (cameraIndex !== -1) {
        const cam = cameraArray[cameraIndex];
        cam.status = { ...cam.status, connectionStatus: status };
      }
    },
    processSSEResponse(event: Event) {
      switch (event.type) {
        case 'een.deviceCloudStatusUpdateEvent.v1':
          if (event.data.length) {
            this.updateBridgeStatus(event.actorId, event.data[0].newStatus.connectionStatus);
          }
          break;
      }
    },
  },
});
