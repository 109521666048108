import { Ref, computed, reactive } from 'vue';
import router from '@/service/router';
import { t } from '@/plugins/i18n';
import { useAccountStore, useAppStateStore, useUsersStore } from '@/stores';

function useAlertManagerNavigation(AlertManagerActiveRoute: Ref<string>) {
  const usersStore = useUsersStore();
  const accountStore = useAccountStore();
  const appStateStore = useAppStateStore();

  const ALERT_MANAGER_SUB_ROUTES = [
    'Automations alerts',
    'Automations rules',
    'Alert actions',
    'Add rule',
    'Edit rule',
    'Add action',
    'Edit action',
  ];

  const showRulesPage = computed(() => {
    const hasRulesPermissions = usersStore.permissions?.administrator || usersStore.permissions?.addEditBridgesCameras;
    const hasAutomationFeatureFlag = accountStore?.accountCapabilities?.alertManager;

    if (!hasRulesPermissions) {
      return false;
    }

    return hasAutomationFeatureFlag || appStateStore.showHiddenFeatures;
  });

  const showActionsPage = computed(
    () =>
      usersStore.permissions?.administrator ||
      usersStore.permissions?.addEditBridgesCameras ||
      usersStore.permissions?.editVSPRule
  );

  const showAlertsPage = computed(() => {
    const hasAlertsPermissions =
      usersStore.permissions?.administrator ||
      usersStore.permissions?.addEditBridgesCameras ||
      usersStore.permissions?.viewVSP;
    const hasAutomationFeatureFlag = accountStore?.accountCapabilities?.alertManager;

    if (!hasAlertsPermissions) {
      return false;
    }

    return hasAutomationFeatureFlag || appStateStore.showHiddenFeatures;
  });

  function getAlertManagerSubmenuItems() {
    const isActiveRulesRoute = ['Automations rules', 'Add rule', 'Edit rule'].includes(AlertManagerActiveRoute.value);

    const items = [];

    showAlertsPage.value &&
      items.push({
        id: 'alerts',
        name: t('Alerts'),
        routeName: 'Automations alerts',
        active: AlertManagerActiveRoute.value === 'Automations alerts',
      });
    showRulesPage.value &&
      items.push({
        id: 'rules',
        name: t('Rules'),
        routeName: 'Alert rules',
        active: isActiveRulesRoute,
      });
    showActionsPage.value &&
      items.push({
        id: 'actions',
        name: t('Actions'),
        routeName: 'Alert actions',
        active: AlertManagerActiveRoute.value === 'Alert actions',
      });

    return items;
  }

  const AlertManagerNavigationData = reactive({
    items: computed(() => {
      const alertManagerSubmenuItems = getAlertManagerSubmenuItems();
      if (!alertManagerSubmenuItems.length) {
        return;
      }
      return {
        icon: '$icon_automations',
        name: t('Automations'),
        active:
          ALERT_MANAGER_SUB_ROUTES.includes(AlertManagerActiveRoute.value) ||
          router.currentRoute.meta?.alertManagerSubRoute,
        route: alertManagerSubmenuItems[0].routeName,
        subItems: alertManagerSubmenuItems,
      };
    }),
  });

  const handleAlertManagerChildrenRoutes = (id: string) => {
    let targetRoute = '';
    switch (id) {
      case 'actions': {
        targetRoute = '/automations/actions';
        break;
      }
      case 'alerts': {
        targetRoute = '/automations/alerts';
        break;
      }
      case 'rules': {
        targetRoute = '/automations/rules';
        break;
      }
      default:
        return false;
    }

    if (router.currentRoute.path !== targetRoute) {
      router.push(targetRoute);
    }

    return true;
  };

  return { AlertManagerNavigationData, handleAlertManagerChildrenRoutes, ALERT_MANAGER_SUB_ROUTES };
}

export default useAlertManagerNavigation;
