<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="showSvg"
    ref="svgContainerRef"
    :class="['display-overlay', { 'display-overlay--object-fill': objectFill }]"
    v-html="DOMPurify.sanitize(modifiedSvg)"
  ></div>
</template>

<script setup lang="ts">
import DOMPurify from 'dompurify';
import { onMounted, onUnmounted, ref, Ref, watch, computed } from 'vue';

const props = defineProps<{
  svg: string;
  videoElementRef?: Ref<HTMLVideoElement | undefined>; // Reference to the video element
  container?: Ref<HTMLElement | undefined>; // If you dont have a video element, you can pass a container
  tiled?: boolean;
  imageDimensions?: { width: number; height: number };
  objectFill?: boolean;
}>();

const svgContainerRef = ref<HTMLDivElement>();
const showSvg = ref(!props?.videoElementRef);
let videoElementObserver: MutationObserver;
let containerResizeObserver: ResizeObserver;

/**
 * A computed property that modifies the SVG string based on the `objectFill` prop.
 * If `objectFill` is true, it adds the `preserveAspectRatio="none"` attribute to the SVG tag.
 * This attribute allows the SVG to fill the container without maintaining its aspect ratio.
 * Otherwise, it returns the original SVG string.
 *
 * @returns {string} The modified or original SVG string.
 */
const modifiedSvg = computed(() => {
  if (props.objectFill) {
    return props.svg.replace('<svg', '<svg preserveAspectRatio="none"');
  }
  return props.svg;
});

onMounted(() => {
  // When you resize the container or the video element, the overlay will adjust its size and position with observers.
  // If you work with static content you wont need it just use svg.
  initObservers();
});

onUnmounted(() => {
  cleanObservers();
});

function initObservers() {
  if (props?.videoElementRef?.value) {
    initVideoObserver();
  } else if (props.container) {
    initContainerObserver();
  }
}

function cleanObservers() {
  cleanContainerObservers();
  cleanVideoObservers();
}

watch(
  () => props.videoElementRef?.value,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      initVideoObserver();
    }
  }
);

watch(
  () => props.tiled, // Watch for changes to the tiled prop which triggers a resize
  () => {
    calculateSvgForVideo();
  }
);

watch(
  () => props.imageDimensions,
  () => {
    calculateSvgSizeForContainer();
  },
  { deep: true }
);

function getContainerDimensions() {
  return {
    width: props.container.clientWidth,
    height: props.container.clientHeight,
  };
}

function getImageDimensions() {
  return {
    width: props.imageDimensions?.width,
    height: props.imageDimensions?.height,
  };
}

function initContainerObserver() {
  cleanContainerObservers();
  if (!props.container) return;
  containerResizeObserver = new ResizeObserver(() => {
    calculateSvgSizeForContainer();
  });
  containerResizeObserver.observe(props.container);
}

/**
 * Disconnects the container resize observer if it exists.
 * This function ensures that the observer is properly cleaned up
 * to prevent memory leaks and unnecessary observations.
 */
function cleanContainerObservers() {
  containerResizeObserver && containerResizeObserver.disconnect();
}

// Observe the video element for changes to the poster attribute
const handleMutations: MutationCallback = (mutations) => {
  mutations.forEach((mutation) => {
    if (mutation.type === 'attributes' && mutation.attributeName === 'poster') {
      if (!showSvg.value) {
        showSvg.value = true;
        calculateSvgForVideo();
      }
    }
  });
};

// Create a videoContainerResizeObserver for the video container
const videoContainerResizeObserver = new ResizeObserver(() => {
  calculateSvgForVideo(); // Recalculate the size when the container resizes
});

/**
 * Get the dimensions of the poster image from the video element
 *
 * @param videoElement
 * @param callback
 */
function getPosterDimensions(
  videoElement: HTMLVideoElement,
  callback: (width: number | null, height: number | null) => void
) {
  const posterUrl = videoElement.poster;
  if (!posterUrl) {
    callback(null, null);
    return;
  }

  // Create a new Image object to load the poster image
  const img = new Image();
  img.src = posterUrl;

  img.onload = function () {
    callback(img.width, img.height); // Poster dimensions
  };

  img.onerror = function () {
    callback(null, null); // Error loading the poster
  };
}

/**
 * Adjust the size and position of the overlay based on the container dimensions and aspect ratio
 *
 * @param containerWidth
 * @param containerHeight
 * @param aspectRatio
 */
function adjustSize(containerWidth: number, containerHeight: number, aspectRatio: number) {
  let displayedWidth, displayedHeight, offsetX, offsetY;
  const containerAspectRatio = containerWidth / containerHeight;
  // Calculate the scaled dimensions based on aspect ratios
  if (containerAspectRatio > aspectRatio) {
    // Container is wider than the content (poster or video)
    displayedHeight = containerHeight; // match height
    displayedWidth = displayedHeight * aspectRatio; // scale width
    offsetX = (containerWidth - displayedWidth) / 2; // center horizontally
    offsetY = 0; // no vertical offset
  } else {
    // Container is taller than the content
    displayedWidth = containerWidth; // match width
    displayedHeight = displayedWidth / aspectRatio; // scale height
    offsetX = 0; // no horizontal offset
    offsetY = (containerHeight - displayedHeight) / 2; // center vertically
  }
  if (!svgContainerRef.value) return;
  // Update the overlay to match the content size and position
  svgContainerRef.value.style.width = displayedWidth + 'px';
  svgContainerRef.value.style.height = displayedHeight + 'px';
  svgContainerRef.value.style.left = offsetX + 'px';
  svgContainerRef.value.style.top = offsetY + 'px';
}

/**
 * Calculate the size of the video element based on the container dimensions
 */
function calculateSvgForVideo() {
  const video = props.videoElementRef.value;
  const container = video?.parentElement;
  if (!video || !container) {
    return;
  }
  // Get the container dimensions (usually the window in this case)
  const containerWidth = container?.clientWidth;
  const containerHeight = container?.clientHeight;

  // rely on the poster image dimensions to calculate the aspect ratio
  getPosterDimensions(video, (posterWidth, posterHeight) => {
    if (posterWidth && posterHeight) {
      const posterAspectRatio = posterWidth / posterHeight;
      adjustSize(containerWidth, containerHeight, posterAspectRatio);
    } else {
      console.error('Failed to load poster or no poster available.');
    }
  });
}

/**
 * Calculate the size of the video element based on the container dimensions
 */
function calculateSvgSizeForContainer() {
  // if the objectFill prop is set, the SVG will fill the container without maintaining its aspect ratio
  if (props.objectFill) return;
  const containerDimensions = getContainerDimensions();
  const imageDimensions = getImageDimensions();
  adjustSize(containerDimensions.width, containerDimensions.height, imageDimensions.width / imageDimensions.height);
}

/**
 * Video by default has black bars on the sides when the aspect ratio doesn't match the container.
 * This function adjusts the size and position of the overlay to match the aspect ratio of the video.
 * It also observes the video element for changes to the poster attribute.
 * When the poster attribute is removed, the overlay is hidden.
 * When the poster attribute is set, the overlay is shown.
 */
function initVideoObserver() {
  cleanVideoObservers();
  // Observe the video container for resize events
  props.videoElementRef?.value?.parentElement &&
    videoContainerResizeObserver.observe(props.videoElementRef.value?.parentElement);

  if (props.videoElementRef?.value?.poster) {
    showSvg.value = true;
  } else {
    videoElementObserver = new MutationObserver(handleMutations);
    if (props.videoElementRef?.value) {
      videoElementObserver.observe(props.videoElementRef.value, { attributes: true });
    }
  }
  calculateSvgForVideo();
}

/**
 * Cleans up video observers by disconnecting them.
 * This function ensures that the observers stop observing when the component is unmounted.
 *
 * - Disconnects `videoContainerResizeObserver` if it exists.
 * - Disconnects `videoElementObserver` if it exists.
 */

function cleanVideoObservers() {
  videoContainerResizeObserver && videoContainerResizeObserver.disconnect(); // Stop observing when the component is unmounted
  videoElementObserver && videoElementObserver.disconnect();
}
</script>

<style lang="scss" scoped>
@import '@/styles/public/main.scss';

.display-overlay {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  &--object-fill {
    :deep svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
