import { onMounted, onUnmounted } from 'vue';

import { useAppStateStore } from '@/stores';

export function useNetworkState() {
  const appStateStore = useAppStateStore();

  const updateNetworkState = (e: Event) => {
    appStateStore.updateNetworkState(e.type === 'online');
  };

  onMounted(() => {
    window.addEventListener('online', updateNetworkState);
    window.addEventListener('offline', updateNetworkState);
  });

  onUnmounted(() => {
    window.removeEventListener('online', updateNetworkState);
    window.removeEventListener('offline', updateNetworkState);
  });
}
