import { DEFAULT_SMART_LAYOUT_SETTINGS, SMART_LAYOUT_SETTINGS } from '@/pages/Layouts/smartLayout/constants';
import { defineStore } from 'pinia';
import { ref } from 'vue';

/**
 * Pinia store for managing persisted state across sessions.
 * This store includes settings such as `smartLayoutSettings` and `miniMenuBar`.
 */
export const usePersistedStateStore = defineStore(
  'persistedState',
  function () {
    /**
     * Reactive reference for the state of the mini menu bar.
     * @type {Ref<boolean>}
     */
    const miniMenuBar = ref<boolean>(false);

    /**
     * Reactive reference for the smart layout settings, initialized with default values.
     * @type {Ref<SMART_LAYOUT_SETTINGS>}
     */
    const smartLayoutSettings = ref<SMART_LAYOUT_SETTINGS>(DEFAULT_SMART_LAYOUT_SETTINGS);

    /**
     * Reactive reference for the state of the breadcrumbs backRoute
     */
    const backRouteName = ref<string>('');

    /**
     * Actions available within the persisted state store to modify store values.
     */
    const actions = {
      /**
       * Sets the visibility state of the mini menu bar.
       * @param {boolean} state - The new state of the mini menu bar (true to show, false to hide).
       */
      setMiniMenuBar(state: boolean) {
        miniMenuBar.value = state;
      },

      /**
       * Clears the persisted smart layout settings, resetting to an empty state.
       */
      clearPersistedState() {
        smartLayoutSettings.value = {} as SMART_LAYOUT_SETTINGS;
      },
    };

    return {
      miniMenuBar,
      smartLayoutSettings,
      backRouteName,
      ...actions,
    };
  },
  {
    /**
     * Enables persistence of this store, allowing data to be saved across sessions.
     */
    persist: true,
  }
);
