import { Ref, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch } from 'vue';
import lodash from 'lodash';
import { usePtzStore } from '@/stores';
import { MoveType } from '@eencloud/eewc-components/src/service/api-types';

const DEBOUNCE_TIME = 400;
const ZOOM_STEP = 0.1;
const ZOOM_LIMITS = { min: 0, max: 1 };
const RECTANGLE_SIZES = { small: 0.2, medium: 0.5 };
const ZOOM_STEPS = { small: 0.1, medium: 0.3, large: 0.5 };
const MAX_COUNT = 30;
const LINE_WIDTH = 8;
const MAX_ELAPSED = 500;
const CIRCLE_MULTIPLIER = 8 * Math.PI;

export function usePTZControls(
  videoElement: Ref<HTMLVideoElement>,
  cameraId: string,
  enable: Ref<boolean | undefined> = ref(true)
) {
  const ptzStore = usePtzStore();

  let video: HTMLVideoElement | undefined;
  let ctx: CanvasRenderingContext2D | null = null;
  let canvas: HTMLCanvasElement | undefined;

  let mousedown = false;
  let x1: number, y1: number, x2: number, y2: number;

  const state = reactive({
    zoomLevel: 0,
    disableZoomSlider: true,
    canvasWidth: 0,
  });

  watch(
    () => [videoElement.value, enable.value],
    ([newVideoElement, newEnable], [oldVideoElement, oldEnable]) => {
      if (newVideoElement && newEnable && (!oldVideoElement || !oldEnable)) {
        init();
      }
      if (!newEnable && oldEnable) {
        destroy();
      }
    }
  );

  onMounted(async () => {
    if (enable.value) {
      await getPosition();
      init();
    }
  });

  function init() {
    createCanvas();
    addEventListners();
    state.disableZoomSlider = false;
  }

  function createCanvas() {
    video = videoElement.value;
    canvas = videoElement.value.insertAdjacentElement(
      'beforebegin',
      document.createElement('canvas')
    ) as HTMLCanvasElement;
    state.canvasWidth = videoElement.value.clientWidth;
    ctx = canvas.getContext('2d');

    canvas.height = videoElement.value.clientHeight;
    canvas.width = videoElement.value.clientWidth;
    canvas.className = 'ptz-control-overlay';
    canvas.style.position = 'absolute';
    canvas.style.zIndex = '1';
    canvas.style.color = 'transparent';
  }

  function addEventListners() {
    if (canvas) {
      canvas.addEventListener('wheel', preventScroll, { passive: false });
      videoElement.value.offsetParent?.addEventListener('wheel', lodash.debounce(handleMouseWheel, DEBOUNCE_TIME));
      canvas.addEventListener('mousedown', handleMouseDown);
      canvas.addEventListener('mouseup', handleMouseUp);
      canvas.addEventListener('mousemove', handleMouseMove);
      canvas.addEventListener('dblclick', handleDoubleClick);
      window.addEventListener('resize', onResize);
    }
  }

  function preventScroll(e: Event) {
    e.preventDefault();
  }

  function handleMouseWheel(event: Event) {
    event.stopPropagation();

    const wheelEvent = event as WheelEvent;
    const zoomDirection = wheelEvent.deltaY < 0 ? 'in' : 'out';
    incrementalZoom(zoomDirection);
  }

  function handleMouseDown(e: MouseEvent) {
    mousedown = true;
    x1 = e.offsetX;
    y1 = e.offsetY;
  }

  async function handleMouseUp(e: MouseEvent) {
    mousedown = false;
    const { offsetX: x, offsetY: y } = e;
    clearCanvas();
    const moveRequest = createMoveRequest(x, y);
    if (isClick(x, y)) {
      renderRipple(x, y);
      await ptzStore.movePTZ({ cameraId, request: moveRequest });
    } else {
      const zoomStep = calculateZoomStep(x, y);
      await ptzStore.movePTZ({ cameraId, request: moveRequest });
      incrementalZoom('in', zoomStep);
    }
  }

  function handleMouseMove(e: MouseEvent) {
    if (mousedown) {
      x2 = e.offsetX;
      y2 = e.offsetY;
      redrawRect();
    }
  }

  function onResize() {
    if (!canvas || !video) return;
    state.canvasWidth = video.clientWidth;
    canvas.height = video.clientHeight;
    canvas.width = video.clientWidth;
  }

  function redrawRect() {
    if (!video || !ctx) return;
    ctx.clearRect(0, 0, video.clientWidth, video.clientHeight);
    ctx.beginPath();
    ctx.lineWidth = 3;
    ctx.strokeStyle = 'white';
    ctx.rect(x1, y1, x2 - x1, y2 - y1);
    ctx.stroke();
  }

  function renderRipple(x: number, y: number) {
    if (!video || !ctx) return;
    let start: number | undefined;
    let done = false;

    window.requestAnimationFrame(drawCircle);

    function drawCircle(timestamp: number) {
      if (!video || !ctx) return;
      if (!start) {
        start = timestamp;
      }

      const elapsed = timestamp - start;
      const count = Math.min(0.1 * elapsed, MAX_COUNT);

      ctx.clearRect(0, 0, video.clientWidth, video.clientHeight);
      ctx.beginPath();
      ctx.lineWidth = LINE_WIDTH - (count * 2) / 10;
      ctx.strokeStyle = 'white';
      ctx.arc(x, y, count, 0, CIRCLE_MULTIPLIER);
      ctx.stroke();

      if (count === MAX_COUNT) {
        done = true;
        ctx.clearRect(0, 0, video.clientWidth, video.clientHeight);
      }

      if (elapsed < MAX_ELAPSED && !done) {
        window.requestAnimationFrame(drawCircle);
      }
    }
  }

  function clearCanvas() {
    if (ctx && video) {
      ctx.clearRect(0, 0, video.clientWidth, video.clientHeight);
    }
  }

  function isClick(x: number, y: number) {
    return x === x1 && y === y1;
  }

  function createMoveRequest(x: number, y: number) {
    let relativeX, relativeY;

    if (!isClick(x, y)) {
      const xCenter = (x1 + x2) / 2;
      const yCenter = (y1 + y2) / 2;
      relativeX = canvas ? xCenter / canvas.clientWidth : 0;
      relativeY = canvas ? yCenter / canvas.clientHeight : 0;
    } else {
      relativeX = canvas ? x / canvas.clientWidth : 0;
      relativeY = canvas ? y / canvas.clientHeight : 0;
    }

    return {
      moveType: MoveType.CenterOn,
      relativeX,
      relativeY,
    };
  }

  function calculateZoomStep(x: number, y: number) {
    if (!canvas) return;
    const relativeRectangleSize = Math.abs((x1 - x) * (y1 - y)) / (canvas.clientWidth * canvas.clientHeight);
    if (relativeRectangleSize > RECTANGLE_SIZES.medium) {
      return ZOOM_STEPS.small;
    } else if (relativeRectangleSize > RECTANGLE_SIZES.small) {
      return ZOOM_STEPS.medium;
    } else {
      return ZOOM_STEPS.large;
    }
  }

  function destroy() {
    window.removeEventListener('wheel', preventScroll);
    videoElement.value.offsetParent?.removeEventListener('wheel', handleMouseWheel);
    canvas?.removeEventListener('mousedown', handleMouseDown);
    canvas?.removeEventListener('mouseup', handleMouseUp);
    canvas?.removeEventListener('mousemove', handleMouseMove);
    canvas?.removeEventListener('dblclick', handleDoubleClick);
    window.removeEventListener('resize', onResize);

    canvas?.remove();
  }

  async function getPosition() {
    const pos = await ptzStore.getPTZLocation(cameraId);
    if (pos) {
      state.zoomLevel = pos.z ?? 0;
    }
  }

  async function zoomToPosition() {
    await ptzStore.movePTZ({
      cameraId: cameraId,
      request: { moveType: MoveType.Position, z: state.zoomLevel },
    });
  }

  async function move(stepSize: any, direction: any) {
    const moveType = MoveType.Direction;
    await ptzStore.movePTZ({
      cameraId: cameraId,
      request: { moveType, direction, stepSize },
    });
  }

  function incrementalZoom(direction: 'in' | 'out', step = ZOOM_STEP) {
    let newZoomValue = direction === 'in' ? state.zoomLevel + step : state.zoomLevel - step;
    newZoomValue = Math.max(Math.min(newZoomValue, ZOOM_LIMITS.max), ZOOM_LIMITS.min);
    if (state.zoomLevel !== newZoomValue) {
      state.zoomLevel = newZoomValue;
      zoomToPosition();
    }
  }

  function handleDoubleClick() {
    state.zoomLevel = ZOOM_LIMITS.min;
    zoomToPosition();
  }

  onBeforeUnmount(() => {
    destroy();
  });

  return { ...toRefs(state), move, incrementalZoom, zoomToPosition, init };
}
