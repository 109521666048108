import posthog from 'posthog-js';
import { ApiUserMain } from '@eencloud/eewc-components/src/service/api-types';

export default function useIdentifyAndTrackUser() {
  function identifyAndTrackUser(user: ApiUserMain) {
    if (!user?.id) return;
    posthog.identify(user.id);
    posthog.capture('user_opened_webapp', { generation: 'enhanced' });
  }

  function resetTracking() {
    posthog.reset();
  }

  return { identifyAndTrackUser, resetTracking };
}
