import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { logOut, rerouteToOAuthLogin as reRouteToLogin } from '@eencloud/eewc-components/src/service/auth.js';

import useIdentifyAndTrackUser from '@/service/posthog/useIdentifyAndTrackUser';
import { usePersistedStateStore } from './persistedState';
import { useUsersStore } from './users';

export const useAuthStateStore = defineStore('authState', function () {
  const loggedIn = ref(false);
  const persistedStore = usePersistedStateStore();
  const usersStore = useUsersStore();
  const { identifyAndTrackUser, resetTracking } = useIdentifyAndTrackUser();

  function loginSuccessful() {
    loggedIn.value = true;
  }

  function logout() {
    loggedIn.value = false;
    persistedStore.clearPersistedState();
    resetTracking();
    logOut();
  }

  /**
   * Logs out the user, clears persisted state, and redirects to the OAuth login page.
   *
   * This function sets the `loggedIn` status to `false`, clears any stored data in the
   * persisted store, and then redirects the user to the OAuth login route for re-authentication.
   */
  function rerouteToOAuthLogin() {
    loggedIn.value = false;
    persistedStore.clearPersistedState();
    reRouteToLogin();
  }

  watch(
    () => usersStore.currentUser,
    (newUser, oldUser) => {
      if (newUser?.id && newUser?.id !== oldUser?.id) {
        identifyAndTrackUser(newUser);
      }
    }
  );

  return {
    loggedIn,
    loginSuccessful,
    logout,
    rerouteToOAuthLogin,
  };
});
