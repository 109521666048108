import { useEventsStore, useHistoryBrowserStore } from '@/stores';

export default function useHistoryBrowserRouterGuard(to) {
  if (!to.name.includes('History Browser')) return;

  const hbStore = useHistoryBrowserStore();
  const eventsStore = useEventsStore();

  if (to.query.ids) {
    let ids;
    if (typeof to.query.ids === 'string') {
      ids = to.query.ids.split(',').filter((id) => id);
    } else if (Array.isArray(to.query.ids)) {
      ids = to.query.ids.filter((id) => id);
    }
    // custom order represents all tiles, including empty and live tiles
    hbStore.customOrder = ids;

    // cameraIds represent the cameras involved, used for fetching data. It can only contain camera ids, not empty or live tiles
    hbStore.cameraIds = ids.filter((id) => id && !id.includes('empty') && !id.includes('live'));

    // we do not want to include empty tiles in the query
    to.query.ids = ids.filter((id) => id && !id.includes('empty')).toString();

    if (!to.query.compositeIds) {
      hbStore.compositeIds = {};
    }
  }

  if (to.query.compositeIds && typeof to.query.compositeIds === 'string') {
    const compositeIdArr = [to.query.compositeIds]; //example of to.query.compositeIds: "cameraId1#compositeId1,cameraId2#compositeId2"
    hbStore.compositeIds = compositeIdArr.reduce((obj, str) => {
      const [key, value] = str.split('#');
      obj[key] = value;
      return obj;
    }, {});
  }

  if (to.query.defaultEvents && typeof to.query.defaultEvents === 'string') {
    eventsStore.setDefaultEventTypes(to.query.defaultEvents);
    delete to.query.defaultEvents;
  }

  if (to.query.resolutionSetting && typeof to.query.resolutionSetting === 'string') {
    const supportedResolutions = ['main', 'preview'];
    if (supportedResolutions.includes(to.query.resolutionSetting)) {
      hbStore.resolutionSetting = to.query.resolutionSetting;
    } else {
      delete to.query.resolutionSetting;
    }
  }

  if (to.query?.time) {
    const decodedDateTime = decodeURIComponent(to.query.time).replace('+00:00', 'Z');
    if (new Date(decodedDateTime).toString() !== 'Invalid Date') {
      hbStore.currentTime = new Date(decodedDateTime).getTime();
    }
  } else {
    // if no time is provided, set the current time
    hbStore.currentTime = Date.now() - 1000;
  }
}
