import { computed, ref, onBeforeUnmount, watch } from 'vue';

import useCookie from '@eencloud/eewc-components/src/service/useCookie.ts';
import { useUsersStore, useAuthStateStore } from '@/stores';
import { useRoute } from 'vue-router/composables';

/**
 * Manages Appcues integration and banner styling.
 * Handles banner positioning, height calculations and style adjustments.
 * Initializes on login and cleans up on unmount.
 */

export function useAppcues() {
  const usersStore = useUsersStore();
  const authStateStore = useAuthStateStore();
  const { getCookie } = useCookie();
  const route = useRoute();

  const bannerHeight = ref<number>(0);
  const resizeObserver = ref<ResizeObserver | null>(null);

  const userContext = computed(() => usersStore.userContext);
  const getMarginTopStyle = computed(() => {
    return { marginTop: `${bannerHeight.value}px` };
  });

  const getNavigationStyle = computed(() => {
    const marginTop = bannerHeight.value ? `${bannerHeight.value}px` : '0';
    const height = bannerHeight.value ? `calc(100vh - ${bannerHeight.value}px)` : '100vh';

    return { marginTop, height };
  });

  const getContainerStyle = computed(() => {
    const marginTop = bannerHeight.value ? `${bannerHeight.value - 56}px` : '0';

    return { marginTop };
  });

  function configureAppcues() {
    if (!window.Appcues || !userContext.value) return;

    const combinedUserData = {
      ...userContext.value,
      browser: navigator.userAgent,
      os: window.navigator.userAgentData?.platform,
      product: 'VMS',
      application: 'WebApp',
      platform: 'web',
      appVersion: config.version,
      language: getCookie('lang'),
      environment: config.is_production ? 'production' : 'development',
    };

    window.Appcues.identify(userContext.value?.userId, combinedUserData);
  }

  function setupBannerObserver() {
    if (!window.Appcues) return;

    resizeObserver.value = new ResizeObserver(() => {
      const banners = document.querySelectorAll<HTMLElement>('[id^="appcues-container"]');
      let totalHeight = 0;

      banners.forEach((banner, index) => {
        const height = banner.getBoundingClientRect().height;
        if (index > 0) {
          banner.style.position = 'fixed';
          banner.style.top = `${totalHeight}px`;
          banner.style.width = '100%';
        }

        totalHeight += height;
      });

      bannerHeight.value = totalHeight;
    });

    // Observe new Appcues banners
    window.Appcues.on('v2:experience_started', () => {
      const banners = document.querySelectorAll('[id^="appcues-container"]');
      banners.forEach((banner) => resizeObserver.value?.observe(banner));
    });
  }

  // Initialize Appcues
  watch(
    () => authStateStore.loggedIn,
    async (newValue, oldValue) => {
      if (newValue !== oldValue && newValue) {
        /*
         * Prevent Appcues initialization in local environments (non-production).
         * This avoids triggering Appcues during local development.
         * To enable Appcues locally for testing purposes, remove this condition.
         */

        if (import.meta.env.PROD) {
          await usersStore.getUserContext();
          configureAppcues();
          setupBannerObserver();
        }
      }
    },
    { immediate: true }
  );

  onBeforeUnmount(() => {
    resizeObserver.value?.disconnect();
  });

  return {
    bannerHeight,
    getMarginTopStyle,
    getNavigationStyle,
    getContainerStyle,
  };
}
