import { t } from '@/plugins/i18n.ts';
import { PPESearchAlias } from './types';

const VS_FILTER_OBJECTS_ALL_DATA_TYPES = {
  objectClassification: 'filter.objectClassification.v1',
  vehicleAttribute: 'filter.vehicleAttribute.v1',
  personAttribute: 'filter.personAttribute.v1',
};

const VS_EVENT_DATA_TYPES = {
  objectDetection: 'een.objectDetection.v1',
  personAttributes: 'een.personAttributes.v1',
  croppedFrameImageUrl: 'een.croppedFrameImageUrl.v1',
  fullFrameImageUrl: 'een.fullFrameImageUrl.v1',
  objectClassification: 'een.objectClassification.v1',
  vehicleAttribute: 'een.vehicleAttributes.v1',
};

enum EVENT_MENU_ITEM {
  findSimilarImages = 'FindSimilarImages',
  liveView = 'LiveView',
  historyBrowser = 'HistoryBrowser',
  historyWithLive = 'HistoryWithLive',
}

const EVENT_MENU_ITEMS = [
  {
    name: t('Find similar images'),
    disabled: false,
    value: EVENT_MENU_ITEM.findSimilarImages,
  },
  {
    name: t('Live view'),
    disabled: false,
    value: EVENT_MENU_ITEM.liveView,
  },
  {
    name: t('History browser'),
    disabled: false,
    value: EVENT_MENU_ITEM.historyBrowser,
  },
  {
    name: t('History browser + Live view'),
    disabled: false,
    value: EVENT_MENU_ITEM.historyWithLive,
  },
];

const PPE_ATTRIBUTES = ['helmet', 'vest'];

const STATIONARY_CLASSES = {
  person: 'person',
  vehicle: 'vehicle',
};

const PPE_SEARCH_ALIASES: PPESearchAlias = {
  ppe: 'person, helmet:false, vest:false',
  helmet: 'person, helmet:false',
  vest: 'person, vest:false',
  'person with helmet': 'person, helmet:true',
  'person without helmet': 'person, helmet:false',
  'person with vest': 'person, vest:true',
  'person without vest': 'person, vest:false',
  'person with ppe': 'person, helmet:true, vest:true',
  'person without ppe': 'person, helmet:false, vest:false',
  'person with helmet and without vest': 'person, helmet:true, vest:false',
  'person with helmet and with vest': 'person, helmet:true, vest:true',
  'person without helmet and with vest': 'person, helmet:false, vest:true',
  'person without helmet and without vest': 'person, helmet:false, vest:false',
  'person with vest and with helmet': 'person, helmet:true, vest:true',
  'person with vest and without helmet': 'person, helmet:false, vest:true',
  'person without vest and without helmet': 'person, helmet:false, vest:false',
  'person without vest and with helmet': 'person, helmet:true, vest:false',
};

const DISABLED_MENU_ITEMS = ['Find similar images'];

const EVENT_SORT_OPTIONS = [
  { text: t('Most recent first'), value: '+timestamp' },
  { text: t('Oldest first'), value: '-timestamp' },
  { text: t('Event frequency'), value: '+eventCount' },
  { text: t('Most relevant'), value: '+relevance' },
];

enum EVENTS_OBJECT_VALUES {
  person = 'filter.personAttributes.v1',
  vehicle = 'filter.vehicleAttributes.v1',
}

const DEFAULT_EVENT_INCLUDES = [
  'data.een.objectDetection.v1',
  'data.een.vehicleAttributes.v1',
  'data.een.personAttributes.v1',
  'data.een.objectClassification.v1',
  'data.een.fullFrameImageUrl.v1',
  'data.een.croppedFrameImageUrl.v1',
];

const EVENT_ATTRIBUTES_FILTER_NAMES = ['filter.vehicleAttributes.v1', 'filter.personAttributes.v1'];

enum PERSON_COMMON_ATTRIBUTE {
  gender = 'gender',
  upperBodyClothingColor = 'upperBodyClothingColor',
  lowerBodyClothingColor = 'lowerBodyClothingColor',
}

const PERSON_COMMON_ATTRIBUTES = [
  PERSON_COMMON_ATTRIBUTE.gender,
  PERSON_COMMON_ATTRIBUTE.upperBodyClothingColor,
  PERSON_COMMON_ATTRIBUTE.lowerBodyClothingColor,
];

enum VEHICLE_COMMON_ATTRIBUTE {
  make = 'make',
  bodyType = 'bodyType',
  color = 'color',
}

enum SINGLE_VALUE_OPTIONS {
  with = 'With',
  without = 'Without',
}

const VEHICLE_COMMON_ATTRIBUTES = [
  VEHICLE_COMMON_ATTRIBUTE.make,
  VEHICLE_COMMON_ATTRIBUTE.bodyType,
  VEHICLE_COMMON_ATTRIBUTE.color,
];

enum EVENT_TYPES {
  firstSample = 'firstSample',
  lastSample = 'lastSample',
}

const FACE_SEARCH_QUERY_TEXT = 'Face';

// 24 hours in seconds = 24 * (60 * 60) = 86400
const THRESHOLD_TIME_FOR_DENSITY_MAP = 86400;
const START_INDEX_OF_TIME_IN_TIMESTAMP = 11;

const DEFAULT_ATTRIBUTE_OPTION = 'Any';
// TODO: This is temporary and will be removed in future
const AGGREGATE_INDUSTRIES_ACCOUNT_ID = '00142573';

const YESTERDAY = 'yesterday';
const TODAY = 'today';
const CUSTOM_DATE = 'custom';
const TIME_PICKER_OPTIONS = [
  ...[30, 7].map((day) => {
    return { text: t(`Last ${day} days`), key: day, timeValue: 'd' };
  }),
  { text: t('Last 24 hours'), key: 24, timeValue: 'h' },
  { text: t('Yesterday'), key: null, timeValue: YESTERDAY },
  { text: t('Today'), key: null, timeValue: TODAY },
];

const STATIONARY_ATTRIBUTES = {
  stationary: 'stationary',
  parked: 'parked',
};

const CAMERA_FILTER_KEYS = ['cameras', 'layouts', 'sites', 'tags', 'bridges'];

export {
  AGGREGATE_INDUSTRIES_ACCOUNT_ID,
  CAMERA_FILTER_KEYS,
  CUSTOM_DATE,
  DEFAULT_ATTRIBUTE_OPTION,
  DEFAULT_EVENT_INCLUDES,
  DISABLED_MENU_ITEMS,
  EVENT_ATTRIBUTES_FILTER_NAMES,
  EVENT_MENU_ITEM,
  EVENT_MENU_ITEMS,
  EVENT_SORT_OPTIONS,
  EVENT_TYPES,
  EVENTS_OBJECT_VALUES,
  FACE_SEARCH_QUERY_TEXT,
  PERSON_COMMON_ATTRIBUTE,
  PERSON_COMMON_ATTRIBUTES,
  PPE_ATTRIBUTES,
  PPE_SEARCH_ALIASES,
  SINGLE_VALUE_OPTIONS,
  START_INDEX_OF_TIME_IN_TIMESTAMP,
  STATIONARY_ATTRIBUTES,
  STATIONARY_CLASSES,
  THRESHOLD_TIME_FOR_DENSITY_MAP,
  TIME_PICKER_OPTIONS,
  TODAY,
  VEHICLE_COMMON_ATTRIBUTE,
  VEHICLE_COMMON_ATTRIBUTES,
  VS_EVENT_DATA_TYPES,
  VS_FILTER_OBJECTS_ALL_DATA_TYPES,
  YESTERDAY,
};
