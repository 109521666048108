import { fromAbortSignal } from '@eencloud/eewc-components/src/service/adapters';
import api from '@eencloud/eewc-components/src/service/api';
import { ApiFeedWithIncludes } from '@eencloud/eewc-components/src/service/api-types';
import axios, { CancelTokenSource } from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useFeedsStore = defineStore('feeds', function () {
  const feeds = ref<{
    [key: string]: ApiFeedWithIncludes;
  }>({});

  let abortController: AbortController | undefined;

  async function getPreviewFeed(deviceId: string, signal?: AbortSignal) {
    return await getFeed(deviceId, 'preview', signal);
  }

  async function getMainFeed(deviceId: string) {
    return await getFeed(deviceId, 'main');
  }

  async function getTalkDownFeed(deviceId: string) {
    return await getFeed(deviceId, 'talkdown');
  }

  async function getFeed(deviceId: string, type: 'main' | 'preview' | 'talkdown', signal?: AbortSignal) {
    const targetId = `${deviceId}-${type}`;

    try {
      const foundFeedObj: ApiFeedWithIncludes = feeds.value[targetId];
      if (foundFeedObj) {
        return foundFeedObj;
      } else {
        const data = await api.getFeeds({ deviceId }, signal);
        let foundFeedObj;
        if (data && data.results) {
          data.results.forEach((result) => {
            feeds.value[result.id] = result;
            if (result.id === targetId) {
              foundFeedObj = result;
            }
          });
        }
        return foundFeedObj;
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * The function is used to get feeds for multiple cameras and update the feeds store. Before making api call, it checks if the feed is already in the store.
   *
   * @param deviceIds the list of camera ids
   * @param feed type
   */
  async function getFeedsForDevices(
    deviceIds: string[],
    type: 'main' | 'preview' | 'talkdown',
    controller?: AbortController,
    cancelPrevReq = false
  ) {
    try {
      const cameraIds = deviceIds.reduce((result: string[], id) => {
        !feeds.value[`${id}-${type}`] && result.push(id);
        return result;
      }, []);
      if (!cameraIds.length) {
        return;
      }
      const camIdsInString = cameraIds.toString();

      if (cancelPrevReq && abortController) {
        abortController.abort();
      }
      abortController = controller;

      const data = await api.getFeeds({ deviceId__in: camIdsInString }, controller?.signal);
      if (data && data.results) {
        data.results.forEach((result) => {
          feeds.value[result.id] = result;
        });
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled');
      } else {
        console.error(error);
      }
    }
  }

  return {
    feeds,
    getPreviewFeed,
    getMainFeed,
    getTalkDownFeed,
    getFeed,
    getFeedsForDevices,
  };
});
