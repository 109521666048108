import { defineStore } from 'pinia';
import api from '@eencloud/eewc-components/src/service/api';
import { ref } from 'vue';
import {
  EventsRequestParams,
  EventType,
  Event,
  EventSubscriptionCreate,
} from '@eencloud/eewc-components/src/service/api-types';
import { useHistoryBrowserStore, useUsersStore } from '@/stores';
import useConcurrentApiTracker from '@/service/useConcurrentApiTracker';
import { ExtendedEvent } from '@/pages/HistoryBrowser/types';

/**
 * Constants
 */

export const DEFAULT_POS_EVENTS_INCLUDES = [
  'data.een.posTransactionStart.v1',
  'data.een.posTransactionEnd.v1',
  'data.een.posTransactionItem.v1',
  'data.een.posTransactionFlag.v1',
  'data.een.displayLocationSummary.v1',
];

export const useEventsStore = defineStore('events', () => {
  const MOTION_DETECTION_EVENT = 'een.motionDetectionEvent.v1';
  const POS_TRANSACTION_EVENT = 'een.posTransactionEvent.v1';
  const STATUS_UPDATE_EVENT = 'een.deviceCloudStatusUpdateEvent.v1';

  const hbStore = useHistoryBrowserStore();
  const eventTypes = ref<EventType[]>([]);
  const eventTypesPerCamera: { [key: string]: string[] } = {};
  const usersStore = useUsersStore();
  const { registerApiCall, unregisterApiCall, eventsLoading, resetConcurrentApiTracker } = useConcurrentApiTracker();
  // currently api return this event but it is not valid so we hide it
  const SECENE_LABEL_EVENT = 'een.sceneLabelEvent.v1';
  const DEVICE_CREATION_EVENT = 'een.deviceCreationEvent.v1';
  const HIDDEN_EVENT_TYPES = [SECENE_LABEL_EVENT, DEVICE_CREATION_EVENT];
  const DEAFULT_EVENT_TYPES = [MOTION_DETECTION_EVENT];
  // Reflects the event types that are visible in the timeline
  const visibleEventTypes = ref<string[]>([...DEAFULT_EVENT_TYPES]);
  const eventsUpdatedTimestamp = ref<number>(0);
  const stopSearchingFilteredEvents = ref(false);
  const uniqueRequestedEventTypes = ref<string[]>([]);

  // this timestamp is used to trigger a resize event on the timeline
  const resizeTriggeredTimestamp = ref<number>(0);

  let terminateEventCalls = false;

  function stopSearchingEvents() {
    if (stopSearchingFilteredEvents.value) return;
    stopSearchingFilteredEvents.value = true;
  }

  async function setDefaultEventTypes(eventType: string) {
    if (eventType) {
      DEAFULT_EVENT_TYPES[0] = eventType;
      visibleEventTypes.value = [...DEAFULT_EVENT_TYPES];
    }
  }

  const resetDefaultEventTypes = () => {
    DEAFULT_EVENT_TYPES[0] = MOTION_DETECTION_EVENT;
    visibleEventTypes.value = [...DEAFULT_EVENT_TYPES];
  };

  async function getEventTypes() {
    if (!eventTypes.value.length) {
      try {
        const language = usersStore.currentUser?.language || 'en';
        const res = await api.getEventTypes({ language });
        if (res && res.results) {
          eventTypes.value = res.results;
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  /**
   * @returns A promise of a combined list of event types supported by the provided cameras
   */
  async function getDeviceSupportedEventTypes(deviceIds: string[]): Promise<string[]> {
    let eventTypes: string[] = [];
    for (const deviceId of deviceIds) {
      if (eventTypesPerCamera[deviceId]) {
        eventTypes = [...eventTypes, ...eventTypesPerCamera[deviceId]];
      } else {
        const res = await api.fetchEventFieldValues(deviceId);
        if (res && res['type']) {
          eventTypesPerCamera[deviceId] = res['type'];
          eventTypes = [...eventTypes, ...res['type']];
        }
      }
    }
    return [...new Set(eventTypes)];
  }

  /**
   * Retrieves the requested event types for a specific device.
   * @param deviceId - The ID of the device to retrieve event types for.
   * @param waitForTypes - Whether to wait for supported event types to be fetched.
   * @returns A promise of an array of event types.
   */
  async function getRequestedEventTypes(deviceId: string, waitForTypes = true): Promise<string[]> {
    const requestedEventTypes = [...visibleEventTypes.value];

    if (!eventTypesPerCamera[deviceId]) {
      if (waitForTypes) {
        await getDeviceSupportedEventTypes([deviceId]);
        const eventTypesCameraSupports: string[] = eventTypesPerCamera[deviceId] || [];
        return eventTypesCameraSupports;

        //   return requestedEventTypes.filter((item) => eventTypesCameraSupports.includes(item));
      }
      // Start fetching supported event types in the background
      getDeviceSupportedEventTypes([deviceId]).catch(console.error);
      // Return only motion detection events for now
      return requestedEventTypes.filter((item) => item === MOTION_DETECTION_EVENT);
    }

    const eventTypesCameraSupports: string[] = eventTypesPerCamera[deviceId] || [];
    return requestedEventTypes.filter((item) => eventTypesCameraSupports.includes(item));
  }

  async function fetchEvents(requestConfig: {
    deviceId: string;
    startTimestamp: string;
    endTimestamp: string;
    customEventTypes?: string[];
  }): Promise<boolean> {
    let apiCallSuccessful = false;
    const userPrefferedEventTypes = usersStore.userPreferenceDataForEventsPopover;
    const eventsSelected = userPrefferedEventTypes[requestConfig.deviceId];

    // Combine requested event types with selected events
    const requestedEventTypes = [
      ...(requestConfig.customEventTypes || (await getRequestedEventTypes(requestConfig.deviceId, true))),
    ];
    if (eventsSelected) {
      const filteredRequestedEventTypes = requestedEventTypes.filter((item) => eventsSelected.includes(item));
      requestedEventTypes.length = 0;
      requestedEventTypes.push(...filteredRequestedEventTypes);
    }

    // remove the HIDDEN_EVENT_TYPES from the requestedEventTypes
    requestedEventTypes.forEach((item) => {
      if (HIDDEN_EVENT_TYPES.includes(item)) {
        requestedEventTypes.splice(requestedEventTypes.indexOf(item), 1);
      }
    });

    // Remove duplicates
    uniqueRequestedEventTypes.value = [...new Set(requestedEventTypes)];
    const eventsIncludes = visibleEventTypes.value.includes(POS_TRANSACTION_EVENT)
      ? DEFAULT_POS_EVENTS_INCLUDES
      : ['data.een.deviceCloudStatusUpdate.v1'];

    const params: EventsRequestParams = {
      startTimestamp__gte: requestConfig.startTimestamp,
      startTimestamp__lte: requestConfig.endTimestamp,
      actor: `camera:${requestConfig.deviceId}`,
      pageToken: undefined as string | undefined,
      type__in: uniqueRequestedEventTypes.value,
      pageSize: 500,
      include: eventsIncludes,
    };

    hbStore.loadingEvents = true;
    const apiCallId = requestConfig.deviceId + requestConfig.startTimestamp + requestConfig.endTimestamp;
    registerApiCall(apiCallId);

    do {
      try {
        if (terminateEventCalls) break;
        const res = await api.getEvents(params);
        if (res && res.results) {
          let results = res.results;
          results = cleanEventsArray(
            results,
            hbStore.stores[requestConfig.deviceId].events,
            requestConfig.endTimestamp
          );

          hbStore.stores[requestConfig.deviceId].events = [
            ...results,
            ...hbStore.stores[requestConfig.deviceId].events,
          ];
          eventsUpdatedTimestamp.value = Date.now();
          params.pageToken = res.nextPageToken;
          apiCallSuccessful = true;
        } else {
          apiCallSuccessful = false;
          break;
        }
      } catch (error) {
        apiCallSuccessful = false;
        unregisterApiCall(apiCallId);
        console.error(error);
      }
    } while (params.pageToken && !terminateEventCalls);

    hbStore.loadingEvents = false;
    terminateEventCalls = false;
    unregisterApiCall(apiCallId);
    return apiCallSuccessful;
  }

  async function getEvents(params: EventsRequestParams): Promise<Event[]> {
    let eventList: Event[] = [];
    try {
      let pageToken: string | undefined;
      do {
        const data = await api.getEvents({
          pageToken: pageToken as string,
          pageSize: 500,
          ...params,
        });
        if (data) {
          pageToken = data.nextPageToken;
          if (data.results) eventList = [...eventList, ...data.results] as Event[];
        } else break;
      } while (pageToken !== '' && !terminateEventCalls);
    } catch (error) {
      console.error(error);
    }
    return eventList;
  }

  function resetEventsOfCamera(cameraId: string) {
    if (!hbStore.stores[cameraId]) return;
    hbStore.stores[cameraId].events = [];
    hbStore.stores[cameraId].allEventsInRangeLoaded = false;
    hbStore.stores[cameraId].fetchingEvents = false;
    hbStore.stores[cameraId].eventsCurrentCoverage = {
      start: 0,
      end: 0,
    };
  }

  function cleanEventsArray(
    newEvents: ExtendedEvent[],
    existingEvents: ExtendedEvent[],
    requestedEndTimestamp: string
  ): ExtendedEvent[] {
    const cleanedEvents: ExtendedEvent[] = [];
    const existingEventIds = new Set(existingEvents.map((event) => event.id));

    const reducedEvents = newEvents.reduce((acc, newEvent) => {
      if (newEvent?.data?.[0]?.newStatus?.connectionStatus === 'online') {
        return acc;
      }
      if (existingEventIds.has(newEvent.id)) {
        return acc;
      }
      if (!newEvent.endTimestamp) {
        newEvent.endTimestamp = requestedEndTimestamp;
      }
      newEvent.startTimestampEpoch = new Date(newEvent.startTimestamp).getTime();
      newEvent.endTimestampEpoch = new Date(newEvent.endTimestamp).getTime();
      acc.push(newEvent);
      return acc;
    }, cleanedEvents);

    reducedEvents.sort((a, b) => a.startTimestampEpoch - b.startTimestampEpoch);
    return reducedEvents;
  }

  function terminateFetchEventLoops() {
    terminateEventCalls = true;
  }

  async function createEventSubscription(payload: EventSubscriptionCreate) {
    return await api.createEventSubscription(payload);
  }

  async function deleteEventSubscription(eventSubscriptionId: string) {
    return await api.deleteEventSubscription(eventSubscriptionId);
  }

  return {
    getEvents,
    fetchEvents,
    getDeviceSupportedEventTypes,
    getRequestedEventTypes,
    visibleEventTypes,
    eventTypes,
    getEventTypes,
    DEAFULT_EVENT_TYPES,
    resetEventsOfCamera,
    MOTION_DETECTION_EVENT,
    STATUS_UPDATE_EVENT,
    SECENE_LABEL_EVENT,
    HIDDEN_EVENT_TYPES,
    eventsUpdatedTimestamp,
    stopSearchingFilteredEvents,
    stopSearchingEvents,
    terminateFetchEventLoops,
    deleteEventSubscription,
    createEventSubscription,
    POS_TRANSACTION_EVENT,
    setDefaultEventTypes,
    resetDefaultEventTypes,
    eventsLoading,
    resetConcurrentApiTracker,
    uniqueRequestedEventTypes,
    resizeTriggeredTimestamp,
  };
});
