import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Route } from 'vue-router';

type TimeFormat = '24h' | '12h';

type TopBarBreadcrumbs =
  | {
      from: string;
      title: string;
      backEvent: () => void;
    }
  | undefined;

export const useAppStateStore = defineStore('appState', function () {
  const timeFormat = ref<TimeFormat>('24h');
  const externalBrowser = ref(false);
  const topBarBreadcrumbs = ref<TopBarBreadcrumbs>();
  const showHiddenFeatures = ref<boolean>(false);
  const showHiddenFeaturesOnCtrlDblClick = ref<boolean>(false);
  const showHiddenAMFeatures = ref<boolean>(false);
  const isFullScreen = ref<boolean>(false);
  const searchQuery = ref<string>('');
  const currentRoute = ref<Route>();
  const isIFrame = ref(window.self !== window.top);
  const iframeAuthenticated = ref(false);
  const routerHistoryItems = ref<Route[]>([]);
  const openedChildWindows = ref<Window[]>([]);
  const isInternetConnected = ref<boolean>(true);

  const actions = {
    updateCurrentRoute(route: Route) {
      currentRoute.value = route;
    },
    updateSearchQuery(query: string) {
      searchQuery.value = query.trim();
    },
    changeTimeFormat(format: TimeFormat) {
      timeFormat.value = format;
    },
    updateIsFullScreen(newValue: boolean) {
      isFullScreen.value = newValue;
    },
    changeTopBarBreadcrumbs(newTopBarBreadcrumbs: TopBarBreadcrumbs) {
      topBarBreadcrumbs.value = newTopBarBreadcrumbs;
    },
    updateShowHiddenAMFeatures(state: boolean) {
      showHiddenAMFeatures.value = state;
    },
    updateShowHiddenFeatures(state: boolean) {
      showHiddenFeatures.value = state;
      this.changeFont();
    },
    closeAllChildWindows() {
      openedChildWindows.value.forEach((childWindow) => {
        if (childWindow && !childWindow.closed) {
          childWindow.close();
        }
      });
      openedChildWindows.value = [];
    },
    updateNetworkState(state: boolean) {
      isInternetConnected.value = state;
    },
    // change font to Inter font
    changeFont() {
      const INTER_FONT_LINK = 'https://rsms.me/inter/inter.css';
      const existingLink = document.querySelector('link[href*="fonts.googleapis.com"]');
      if (existingLink) {
        existingLink.href = INTER_FONT_LINK;
      }
      document.querySelector('.v-application').style.fontFamily = `"Inter", sans-serif`; // Apply the font to Vuetify components
    },
  };

  return {
    timeFormat,
    topBarBreadcrumbs,
    externalBrowser,
    showHiddenFeatures,
    showHiddenAMFeatures,
    showHiddenFeaturesOnCtrlDblClick,
    isFullScreen,
    isInternetConnected,
    searchQuery,
    currentRoute,
    isIFrame,
    iframeAuthenticated,
    routerHistoryItems,
    openedChildWindows,
    ...actions,
  };
});
