import { t } from '@/plugins/i18n';
import { Item } from '@eencloud/eewc-components/src/components/dropdowns/types';
import {
  ConfigurationPatch,
  CountingAndCrossingConfiguration,
  MotionConfiguration,
  MotionObjectSize,
} from '@eencloud/eewc-components/src/service/api-types';

export const SKELETON_ROW_COUNT = 5;
export const ANALYTIC_TYPE = {
  MOTION_DETECTION: 'een.motionDetectionEvent.v1',
  LINE_CROSSING: 'een.objectLineCrossEvent.v1',
  OBJECT_COUNTING: 'een.countChangeEvent.v1',
  OBJECT_DETECTION: 'een.bridgeCommonSettings.v1',
  OBJECT_INTRUSION: 'een.objectIntrusionEvent.v1',
  OBJECT_LOITERING: 'een.loiterDetectionEvent.v1',
  TAMPER_DETECTION: 'een.tamperDetectionEvent.v1',
  LPR_DETECTION: 'een.lprPlateReadEvent.v1',
  FACE_DETECTION: 'een.faceDetectionEvent.v1',
  VEHICLE_DETECTION: 'een.vehicleDetectionEvent.v1',
  PPE_DETECTION: 'een.ppeViolationEvent.v1',
  SCENE_ANALYSIS: 'een.sceneLabelEvent.v1',
  PERSON_DETECTION: 'een.personDetectionEvent.v1',
  GUN_DETECTION: 'een.gunDetectionEvent.v1',
};
export const SCENE_ANALYSIS_TITLE = t('Scene analysis');

export const DISABLED_MENU_ITEM: Item = { text: t('Disabled'), value: 'disabled' };
export const PERFORMED_ON = {
  BRIDGE: 'bridge',
  DISABLED: 'disabled',
  CAMERA: 'camera',
  CLOUD: 'cloud',
  ENABLED: 'Enabled',
};

export const CONFIGURATION_TYPE = {
  TUNNEL: 'tunnel',
  NOTE: 'note',
  SETTINGS: 'settings',
  OBJECT: 'object',
};

export type Size = {
  width: number;
  height: number;
};

export type UPDATED_CONFIGURATION = {
  id: string;
  performedOn: string;
  configurationType?: string;
  configuration?: ConfigurationPatch[]; // each analytic type can be configured using different performedOn types - bridge, camera, cloud etc. For each one of then we need to send a separate configuration
  isDirty?: boolean; // flag to check if the configuration is dirty or not
  selectedConfigurationIndex?: number; // index of the active/selected configuration, it can be undefined when the state is disabled
};

export interface Line {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
}

export const DEFAULT_LINE_CROSS_CONFIGURATION: CountingAndCrossingConfiguration = {
  lines: [
    {
      name: 'New line',
      direction: 'up',
      coordinates: [
        [0.049, 0.119],
        [0.919, 0.928],
      ],
    },
  ],
};

export const DEFAULT_MOTION_CONFIGURATION: MotionConfiguration = {
  sensitivity: 0.8,
  objectSize: MotionObjectSize.Small,
  regions: [],
};

export const DEFAULT_OBJECT_INTRUSION_CONFIGURATION = {
  regions: [],
};

export const DEFAULT_OBJECT_LOITERING_CONFIGURATION = {
  regions: [],
};

export const LPR_APPLICATION_PROCESSING_RATES = {
  GATE: 10,
  STREET: 15,
  HIGHWAY: 20,
};

export const CREATOR_ID = {
  LPR_CLOUD: 'een.cloudLpr',
  LPR_BRIDGE: 'een.bridgeLpr',
};

export const LPR_DIRECTIONS = {
  ENTRY: 'entry',
  EXIT: 'exit',
  BI_DIRECTIONAL: 'biDirectional',
};

export enum LPR_ENTRY_DIRECTIONS {
  UP = 'up',
  DOWN = 'down',
}

export const DEFAULT_LPR_DETECTION_CONFIGURATION = [
  {
    creatorId: CREATOR_ID.LPR_BRIDGE,
    localId: { enabled: false },
    entryDirection: LPR_ENTRY_DIRECTIONS.UP,
  },
  {
    creatorId: CREATOR_ID.LPR_CLOUD,
    entryDirection: LPR_ENTRY_DIRECTIONS.UP,
  },
];

export const TAMPER_DETECTION_DEFAULT_CONFIGURATION_FOR_BRIDGE = {
  sensitivity: 0.8,
};

export const DEFAULT_VEHICLE_DETECTION_CONFIGURATION: MotionConfiguration = {
  regions: [],
};

export const DEFAULT_PERSON_DETECTION_CONFIGURATION = {
  regions: [],
};

export const DEFAULT_GUN_DETECTION_CONFIGURATION = {
  regions: [],
};

export const DEFAULT_CONFIGURATION = {
  [ANALYTIC_TYPE.TAMPER_DETECTION]: TAMPER_DETECTION_DEFAULT_CONFIGURATION_FOR_BRIDGE,
  [ANALYTIC_TYPE.LINE_CROSSING]: DEFAULT_LINE_CROSS_CONFIGURATION,
  [ANALYTIC_TYPE.OBJECT_COUNTING]: DEFAULT_LINE_CROSS_CONFIGURATION,
  [ANALYTIC_TYPE.MOTION_DETECTION]: DEFAULT_MOTION_CONFIGURATION,
  [ANALYTIC_TYPE.OBJECT_INTRUSION]: DEFAULT_OBJECT_INTRUSION_CONFIGURATION,
  [ANALYTIC_TYPE.OBJECT_LOITERING]: DEFAULT_OBJECT_LOITERING_CONFIGURATION,
  [ANALYTIC_TYPE.LPR_DETECTION]: DEFAULT_LPR_DETECTION_CONFIGURATION,
  [ANALYTIC_TYPE.VEHICLE_DETECTION]: DEFAULT_VEHICLE_DETECTION_CONFIGURATION,
  [ANALYTIC_TYPE.PERSON_DETECTION]: DEFAULT_PERSON_DETECTION_CONFIGURATION,
  [ANALYTIC_TYPE.GUN_DETECTION]: DEFAULT_GUN_DETECTION_CONFIGURATION,
};

// Default canvas size for the analytic configuration
export const DEFAULT_CANVAS_SIZE: Size = { width: 1270, height: 760 };

export enum LPR_INTEGRATION_TYPE_ITEMS {
  brivo = 'Brivo',
  externalIO = 'External I/O',
}

export const LPR_INTEGRATION_TYPE = {
  BRIVO: 'brivo',
  ExternalIO: 'externalIO',
};

export const TRANSPARENT = 'transparent';

export type LocalIdSettings = {
  type: string;
  usbSerialNumber?: string;
  externalIoModule?: string;
  peripheralDeviceId?: string;
  allowedPin?: number | string;
  restrictedPin?: number | string;
  unlistedPin?: number | string;
  ipAddress?: string;
};

export const EXTERNAL_IO_MODULE = {
  USB_RELAY: 'usbRelay',
};

export const USB_LABEL_STRINGS = {
  allLabel: t('Select All'),
  allSelectedLabel: t('All USB serial numbers'),
  searchPlaceholder: t('Search'),
  noneLabel: t('USB serial number'),
  noResultsLabel: t('No results found'),
};

export const getOptionalROIErrorMessage = () =>
  t('Add a region of interest (ROI) to identify events in specific areas. Mask regions can be used to ignore events');
export const getRequiredROIErrorMessage = () => t('Add a region of interest to capture events in the specified area');
