const Alerts = () => import('@/pages/POS/alerts/Alerts.vue');
const Rules = () => import('@/pages/POS/rules/Rules.vue');
const Transactions = () => import('@/pages/POS/transactions/Transactions.vue');
const POSAddRule = () => import('@/pages/POS/rules/AddRule.vue');
const POSEditRule = () => import('@/pages/POS/rules/EditRule.vue');
const POSAddSystem = () => import('@/pages/POS/posSystems/AddPosSystem.vue');

const POSRoutes = [
  {
    name: 'POS',
    path: '/pos',
    redirect: { name: 'POS Transactions' },
  },
  {
    name: 'POS Rules',
    path: '/pos/rules',
    component: Rules,
    meta: {
      auth: true,
    },
  },
  {
    name: 'POS Alerts',
    path: '/pos/alerts',
    component: Alerts,
    meta: {
      auth: true,
    },
  },
  {
    name: 'POS Transactions',
    path: '/pos/transactions',
    component: Transactions,
    meta: {
      auth: true,
      rootRoute: true,
    },
  },
  {
    path: '/pos/rules/add-rule',
    name: 'New Rule',
    component: POSAddRule,
    meta: {
      auth: true,
    },
  },
  {
    path: '/pos/rules/:id/edit-rule',
    name: 'Edit Rule',
    component: POSEditRule,
    meta: {
      auth: true,
    },
  },
  {
    path: '/pos/system/add',
    name: 'PoS system',
    component: POSAddSystem,
    meta: {
      auth: true,
    },
  },
];

export default POSRoutes;
