<template>
  <div class="image-preview-popup">
    <v-menu
      v-model="show"
      offset-y
      content-class="image-preview-popup__menu"
      :close-on-content-click="false"
      open-on-hover
    >
      <template #activator="{ on, attrs }">
        <v-icon
          class="image-preview-popup__icon"
          :data-testid="`image-preview-popup-icon-${deviceId}${compositeId ? `-composite-id-${compositeId}` : ''}`"
          size="24"
          v-bind="attrs"
          v-on="on"
          @click="openVideo"
        >
          {{ icon }}
        </v-icon>
      </template>
      <div
        v-if="show"
        class="image-preview-popup__stream"
      >
        <preview-image
          class="image-preview-popup__stream"
          is-visible
          :camera-id="deviceId"
          :pane-id="Math.random().toString()"
          :camera="camera"
          :isCameraOnline="isCameraOnline"
          @timestamp="getTimestamp"
          @videoPlayingState="updateVideoPlayingState"
          @feedError="() => (feedError = true)"
          @click="openVideo"
        />
        <eewc-loading-spinner
          :data-testid="'image-preview-popup-loader-' + deviceId"
          :is-loading="loading"
        />
        <camera-states
          v-if="showCameraOfflineMessage"
          :status="connectionStatus"
          icon-size="medium"
          :camera-id="deviceId"
          :preview-permission="camera?.effectivePermissions?.viewPreviewVideo"
          :live-permission="camera?.effectivePermissions?.viewLiveVideo"
          :feed-error="feedError"
        />
        <div :class="['image-preview-popup__overlay', { 'image-preview-popup__overlay--negative': isPreviewStale }]">
          <eewc-tooltip
            v-if="cameraRecordingStatus"
            :text="t('Recording')"
          >
            <template #content="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                class="mr-1"
                v-on="on"
              >
                $icon_live
              </v-icon>
            </template>
          </eewc-tooltip>
          <div>
            {{ timestamp }}
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted, onUnmounted } from 'vue';
import { useCameraStatusStore, useCamerasStore, useWebSocketStore } from '@/stores';
import { ApiCameraWithIncludes } from '@eencloud/eewc-components/src/service/api-types';
import PreviewImage from '@/components/PreviewImage.vue';
import CameraStates from '@/components//CameraStates.vue';
import { timeSince } from '@/service/helpers';
import { t } from '@/plugins/i18n.ts';
import router from '@/service/router';

type Props = {
  deviceId: string;
  icon: string;
  compositeId?: string;
};

const props = defineProps<Props>();
const show = ref();
const previewImage = ref<HTMLCanvasElement>();
const camerasStore = useCamerasStore();
const connectionStatus = computed(() => camera.value?.status?.connectionStatus);
const timestamp = ref<string>();
const isPreviewStale = ref<boolean>(false);
const offlineDuration = ref<string>();
const camera = ref<ApiCameraWithIncludes>();
const webSocketStore = useWebSocketStore();
const cameraStatusStore = useCameraStatusStore();
const cameraStatus = computed(() => cameraStatusStore.findCameraStatus(props.deviceId));
const cameraRecordingStatus = computed(() => cameraStatus.value?.status?.recordingStatus === 'on');
const isVideoPlaying = ref(false);
const feedError = ref(false);
const loading = ref(true);

watch(
  () => show.value,
  async (newVal, oldVal) => {
    if (newVal !== oldVal && newVal) {
      loading.value = true;
      camera.value = await camerasStore.getCamera(props.deviceId, { include: 'status,timeZone,effectivePermissions' });
      loading.value = false;
    }
  }
);

onMounted(() => {
  webSocketStore.subscribeCameras('imagePreviewPopup', [props.deviceId]);
});

onUnmounted(() => {
  webSocketStore.unSubscribeCameras('imagePreviewPopup', [props.deviceId]);
});

const isCameraOnline = computed(() => {
  if (!camera.value || !camera.value.status) {
    return undefined;
  } else {
    return camera.value.status.connectionStatus === 'online';
  }
});

const showCameraOfflineMessage = computed(
  () =>
    !isVideoPlaying.value &&
    (feedError.value ||
      (camera.value && (isCameraOnline.value === false || !camera.value?.effectivePermissions?.viewPreviewVideo)))
);

function getTimestamp(videoTimestamp: string) {
  const dateFromTs = new Date(videoTimestamp);
  const eenLocalizedTS = dateFromTs.toEENCameraDateTimeStamp({ timezone: camera.value?.timeZone?.zone });
  timestamp.value = eenLocalizedTS.slice(10);
  const now = new Date();
  const diff = now - dateFromTs;
  isPreviewStale.value = diff > 10000;
  offlineDuration.value = timeSince(dateFromTs.getTime(), false);
}

function openVideo() {
  if (connectionStatus.value !== 'online' || !camera.value?.effectivePermissions?.viewLiveVideo) {
    return;
  }
  router.push({
    name: 'Video',
    params: { id: props.deviceId },
    query: props.compositeId ? { compositeId: props.compositeId } : undefined,
  });
}

function updateVideoPlayingState(isPlaying: boolean) {
  isVideoPlaying.value = isPlaying;
}
</script>

<style lang="scss" scoped>
@import '../styles/public/main.scss';
.image-preview-popup {
  &__overlay {
    display: flex;
    position: absolute;
    top: 4px;
    right: 10px;
    @include subtitle-2;
    color: $primaryWhite;
    text-shadow: 0px 0px 2px rgba(33, 42, 52, 0.86);

    &--negative {
      color: $negative;
    }
  }

  &__icon {
    &::after {
      width: inherit;
    }
  }
  &__menu {
    contain: initial;
    overflow: visible;
    border: 1px solid $elements !important;
    box-shadow: 0px 2px 12px rgba(42, 52, 64, 0.08) !important;
    border-radius: 4px !important;
    padding: 4px !important;
    margin-top: -1px;
  }

  &__menu::before {
    position: absolute;
    content: '';
    top: -6px;
    left: 6px;
    width: 11px;
    height: 6px;
    background: $elements;
    clip-path: polygon(0 100%, 100% 100%, 50% 0);
  }

  &__menu::after {
    position: absolute;
    content: '';
    top: -5px;
    left: 7px;
    width: 9px;
    height: 5px;
    background: $primaryWhite;
    clip-path: polygon(0 100%, 100% 100%, 50% 0);
  }

  &__stream {
    border-radius: 4px;
    position: relative;
    display: flex;
    overflow: hidden;
    height: 138px;
    width: 241px;
  }
}
</style>
