import { useDewarpStore } from '@/stores';
import { ApiCameraWithIncludes, ApiDewarpConfigComposite } from '@eencloud/eewc-components/src/service/api-types';
import { ref, onUnmounted, Ref } from 'vue';

/**
 * The composable function to dewarp the image. Call initImageDewarp to initialize the dewarping and dewarpImage to dewarp the image.
 */
export function useDewarp() {
  const dewarpStore = useDewarpStore();
  const camera = ref<ApiCameraWithIncludes>();
  const canvasRef = ref<HTMLCanvasElement>();
  let uniqueId = '';
  const selectedComposite = ref<ApiDewarpConfigComposite>();

  /**
   * Initializes the image dewarping process.
   *
   * @param canvas - The canvas element to render the dewarped image.
   * @param cameraItem - The camera item containing the dewarp configuration.
   * @param compositeId - Optional. The ID of the composite to use for dewarping.
   */
  function initImageDewarp(
    canvas: Ref<HTMLCanvasElement | undefined>,
    cameraItem: ApiCameraWithIncludes,
    compositeId?: string
  ) {
    if (!canvas.value) return;
    camera.value = cameraItem;
    canvasRef.value = canvas.value;
    uniqueId = camera.value.id + Math.random().toString(36).substring(2, 15);
    selectedComposite.value = camera.value?.dewarpConfig?.composites?.find((c) => c.id === compositeId);
    dewarpStore.addDeviceConfig(
      { esn: uniqueId, ...camera.value.dewarpConfig },
      canvasRef.value,
      selectedComposite.value
    );
  }

  /**
   * function to dewarp the image
   * @param imageBuffer - image buffer to dewarp
   */
  async function dewarpImage(imageBuffer: ArrayBuffer) {
    if (!canvasRef.value) {
      console.error('initImageDewarp must be called before dewarpImage');
      return;
    }
    const imageBitmap = await createImageBitmap(new Blob([imageBuffer], { type: 'image/jpeg' }));
    if (selectedComposite.value?.panes?.length) {
      canvasRef.value.height = canvasRef.value.width;
      dewarpStore.setRendererSize(uniqueId, canvasRef.value.width, canvasRef.value.width);
      dewarpStore.addOriginalImage(uniqueId, imageBitmap);
    } else {
      // when panes length is 0, it means the composite is of type fisheye
      const ctx = canvasRef.value.getContext('2d');
      ctx && ctx.drawImage(imageBitmap, 0, 0, canvasRef.value.width, canvasRef.value.height);
    }
    imageBitmap.close();
  }

  onUnmounted(() => {
    dewarpStore.removeDevice(uniqueId);
  });

  return { dewarpImage, initImageDewarp };
}
