import { Route } from 'vue-router';
import { useVideoSearchStore } from '@/stores';

export default function useVideoSearchAttributeRoutes(to: Route) {
  const videoSearchStore = useVideoSearchStore();

  // Constants
  const ROUTE_SELECTION_ID_ALL = 'all';
  const VIDEO_SEARCH_ATTRIBUTE_ROUTES = ['layouts', 'tags', 'site'];

  const routeName = to.name?.toLowerCase() ?? '';
  const id = to.params.id;
  const isSelectedIDIsAll = id?.toLowerCase() === ROUTE_SELECTION_ID_ALL;

  // Clear video-search camera filter values while move to other routes
  if (![...VIDEO_SEARCH_ATTRIBUTE_ROUTES, 'videosearch'].includes(routeName) || isSelectedIDIsAll) {
    videoSearchStore.cameraFilterValues = {};
  }

  if (VIDEO_SEARCH_ATTRIBUTE_ROUTES.includes(routeName) && id && !isSelectedIDIsAll) {
    videoSearchStore.cameraFilterValues = { [routeName]: id };
  }
}
