<template>
  <eewc-profile-menu
    :user-email="userEmail"
    :full-name="fullName"
    :initials="initials"
    :menu-items="combinedMenuItems"
    @click="handleClick"
    @onshiftdblclick="appState.updateShowHiddenFeatures(true)"
    @onctrldblclick="onCtrlDblClick"
    @onusermetadblclick="onCtrlDblClick"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import useNavigation from '@/service/navigationService';
import { useUsersStore, useAppStateStore } from '@/stores';

type UserItem = {
  click: () => void;
  icon: string;
  name: string;
  active?: boolean;
  isLoading?: boolean;
};

const USER_PROFILE_MENU_ICONS = ['$icon_user'];
const ACCOUNT_PROFILE_MENU_ICONS = ['$icon_een_logo', '$icon_first_responder', '$icon_logout'];

const usersStore = useUsersStore();
const appState = useAppStateStore();
const { navigationData } = useNavigation();

const firstName = computed(() => usersStore.currentUser?.firstName);
const lastName = computed(() => usersStore.currentUser?.lastName);
const fullName = computed(() => `${firstName.value} ${lastName.value}`);
const initials = computed(() => `${firstName.value?.[0].toUpperCase()}${lastName.value?.[0].toUpperCase()}`);
const userEmail = computed(() => usersStore.currentUser?.email);
const userMenuItems = computed(() => {
  return navigationData.userItems.filter(
    (item) => typeof item === 'object' && 'icon' in item && USER_PROFILE_MENU_ICONS.includes(item.icon)
  );
});
const accountMenuItems = computed(() => {
  return navigationData.userItems.filter(
    (item) => typeof item === 'object' && 'icon' in item && ACCOUNT_PROFILE_MENU_ICONS.includes(item.icon)
  );
});
const combinedMenuItems = computed(() => {
  const items = [];

  if (userMenuItems.value.length > 0) {
    items.push({
      type: 'menu',
      section: 'user',
      items: userMenuItems.value,
    });
  }

  if (accountMenuItems.value.length > 0) {
    // Only add divider if both sections have items
    if (userMenuItems.value.length > 0) {
      items.push({ type: 'divider' });
    }

    items.push({
      type: 'menu',
      section: 'account',
      items: accountMenuItems.value,
    });
  }

  return items;
});

function onCtrlDblClick() {
  appState.updateShowHiddenAMFeatures(true);
  appState.showHiddenFeaturesOnCtrlDblClick = true;
}

function handleClick(item: UserItem) {
  navigationData.userItems.forEach((userItem) => {
    if (typeof userItem === 'object' && userItem.name === item.name) {
      userItem.click();
    }
  });
}
</script>
