import { LAST_USED_APP } from '@/constants';
import { useUsersStore } from '@/stores';

export default function useUserPreference() {
  const usersStore = useUsersStore();

  async function getLastUsedApp() {
    const res = await usersStore.getUserPreference(LAST_USED_APP);

    return res?.data;
  }

  function updateLastUsedApp(value: string) {
    const payload = {
      data: {
        value: value,
      },
    };
    usersStore.updateUserPreference(LAST_USED_APP, payload);
  }

  async function getSelectedEvent() {
    const res = await usersStore.getUserPreference('selectedEvent');
    usersStore.userPreferenceDataForEventsPopover = res?.data;
  }

  async function updateSelectedEvent(newData: object) {
    const existingPreference = usersStore.userPreferenceDataForEventsPopover;

    const mergedData = {
      ...(existingPreference || {}),
      ...newData,
    };

    const payload = {
      data: mergedData,
    };
    usersStore.updateUserPreference('selectedEvent', payload);
    usersStore.userPreferenceDataForEventsPopover = mergedData;
  }

  return { getLastUsedApp, updateLastUsedApp, getSelectedEvent, updateSelectedEvent };
}
