<template>
  <eewc-auto-complete
    v-model="autoCompleteValue"
    :items="pagedLocations"
    :label="t('Site')"
    :label-text="labelText"
    paginated
    :no-data-text="t('No site found')"
    :loading="loading"
    :disabled="disabled || isSitePickerDisabled"
    @infinite="infiniteHandler"
    @search="searchLocations"
  />
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import type { AutoCompleteValue } from '@eencloud/eewc-components/src/components/dropdowns/AutoComplete.vue';
import { GetLocationParams } from '@eencloud/eewc-components/src/service/api-types';
import { IntersectionStateChanger } from '@eencloud/eewc-components/src/utils/types';
import { t } from '@/plugins/i18n.ts';
import { useLocationsStore } from '@/stores';
import { LocationId } from '@eencloud/eewc-components/src/service/api-types/common';

const props = withDefaults(
  defineProps<{
    locationId?: LocationId;
    labelText?: string;
    showLocationsWithResource?: boolean;
    resourceName?: string;
    hasNoLocationItem?: boolean;
    isCurrentSiteFilteredOut?: boolean;
    disabled?: boolean;
  }>(),
  {
    disabled: false,
    isCurrentSiteFilteredOut: false,
  }
);

const emit = defineEmits<{
  (event: 'input', value: string): void;
}>();

const locationsStore = useLocationsStore();

const NO_SITE_ITEM = { text: t('No site'), value: null };

const intersectionStateChanger = ref<IntersectionStateChanger>();
const pagedLocations = ref<AutoCompleteValue[]>([]);
const selectedLocation = ref<AutoCompleteValue | undefined>({ text: '', value: '' });
const loading = ref(false);
const setDefaultSite = ref(true);
const hasSearchInput = ref(false);
const isReset = ref(false);
let pageToken: string | undefined = '';
let searchText = '';

const autoCompleteValue = computed<AutoCompleteValue | undefined>({
  get() {
    return selectedLocation.value;
  },
  set(item) {
    selectedLocation.value = item;
    if (item) {
      if (typeof item !== 'string') {
        (!loading.value || !props.locationId) &&
          emit('input', item.value !== null ? item.value.toString() : item.value);
      }
    } else {
      !loading.value && emit('input', '');
    }
  },
});

const isSitePickerDisabled = computed(() => !hasSearchInput.value && !pagedLocations.value.length && !loading.value); // this avoids the site picker getting disabled when no results found while searching.

watch(
  () => locationsStore.locations.length,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      if (intersectionStateChanger.value) {
        pagedLocations.value = [];
        pageToken = '';
        intersectionStateChanger.value.reset();
        getPagedLocations(intersectionStateChanger.value);
      }
    }
  }
);

async function infiniteHandler(state: IntersectionStateChanger) {
  intersectionStateChanger.value = state;
  await getPagedLocations(state);
}

async function searchLocations(data: { searchText: string; state: IntersectionStateChanger }) {
  // hack for resetting the location picker when clearing filters out
  if (isReset.value) {
    isReset.value = false;
    return;
  }

  if ((searchText !== '' && data.searchText === null) || searchText === data.searchText) {
    return;
    /// to avoid all null value searches except the initial one.
    // To avoid making a search call for the same text as the previous one.
  }

  intersectionStateChanger.value = data.state;
  pagedLocations.value = [];
  searchText = data.searchText;
  searchText && (hasSearchInput.value = true);
  pageToken = '';
  await getPagedLocations(data.state);
  setDefaultSite.value = false;
}

async function getPagedLocations(state: IntersectionStateChanger) {
  loading.value = true;

  const params: GetLocationParams = {
    pageToken,
    name__contains: searchText,
    pageSize: 20,
    sort: '+name',
  };

  if (props.showLocationsWithResource) {
    params.include = 'resourceCounts';
  }

  await locationsStore.getPagedLocations(params);

  pageToken = locationsStore.nextPageToken;

  let items: AutoCompleteValue[] = [];

  if (!props.showLocationsWithResource) {
    if (props.isCurrentSiteFilteredOut) {
      items = locationsStore.pagedLocations.reduce((acc, location) => {
        if (location.id !== props.locationId) {
          acc.push({
            text: location.name,
            value: location.id,
          } as AutoCompleteValue);
        }
        return acc;
      }, [] as AutoCompleteValue[]);
    } else {
      items = locationsStore.pagedLocations.map(
        (location) =>
          ({
            text: location.name,
            value: location.id,
          } as AutoCompleteValue)
      );
    }
  } else {
    items = locationsStore.pagedLocations
      .filter((location) => {
        return location.resourceCounts[props.resourceName] > 0;
      })
      .map(
        (location) =>
          ({
            text: location.name,
            value: location.id,
          } as AutoCompleteValue)
      );
  }

  pagedLocations.value = props.hasNoLocationItem
    ? (Array.from(new Set([NO_SITE_ITEM, ...pagedLocations.value, ...items])) as AutoCompleteValue[])
    : Array.from(new Set([...pagedLocations.value, ...items]));

  // only need to set in the initial call
  if (setDefaultSite.value) {
    autoCompleteValue.value = props.locationId
      ? pagedLocations.value.find((location) => typeof location !== 'string' && props.locationId === location.value)
      : NO_SITE_ITEM;
  }

  state.loaded();
  if (
    !pagedLocations.value.length ||
    locationsStore.totalSize === pagedLocations.value.length ||
    !locationsStore.nextPageToken
  ) {
    state.completed();
  }
  loading.value = false;
}

const clearSelectedLocation = () => {
  selectedLocation.value = { text: '', value: '' };
  isReset.value = true;
};

defineExpose({ clearSelectedLocation });
</script>
