<template>
  <div class="view-switcher">
    <div class="view-switcher__title">
      {{ t('Camera view') }}
    </div>

    <div class="view-switcher__buttons">
      <eewc-button-group
        :value="value"
        :items="[
          {
            value: 'grid',
            icon: '$icon_cards',
          },
          {
            value: 'list',
            icon: '$icon_list',
          },
        ]"
        @input="onInput"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { t } from '@/plugins/i18n.ts';
withDefaults(
  defineProps<{
    value: 'list' | 'grid';
  }>(),
  {
    value: 'list',
  }
);

const emit = defineEmits<{
  (e: 'input', value: 'list' | 'grid'): void;
}>();

function onInput(value: 'list' | 'grid') {
  emit('input', value);
}
</script>

<style lang="scss" scoped>
@import '@/styles/public/main.scss';

.view-switcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $secondary;
  margin-top: 8px;
  margin-bottom: 12px;
}

.view-switcher__title {
  @include subtitle-2;
  color: $primary;
}
</style>
