<template>
  <div
    :class="[
      'scrollable-list-item',
      { 'scrollable-list-item__drag-and-drop': type === 'dragAndDrop', 'scrollable-list-item__select': selected },
      { 'scrollable-list-item--grid': view === 'grid', 'scrollable-list-item--list': view === 'list' },
      { 'composite-item': isItemComposite },
      { 'scrollable-list-item--has-viewports': viewportCount > 0 },
    ]"
    :draggable="draggable"
    @click="onRowClick"
    @dragstart="sendDragStartEvent"
    @dragend="sendDragEndEvent"
  >
    <!-- Checkbox -->
    <div class="d-flex align-center">
      <eewc-check-box
        v-if="type === 'selectable'"
        :data-testid="'scrollable-item-check-box-' + displayId + '-name-' + displayName"
        :value="selected"
        @change="onRowClick"
      />
      <v-icon
        v-if="type === 'dragAndDrop'"
        size="24"
      >
        $icon_drag_and_drop
      </v-icon>
    </div>
    <!-- Canvas -->
    <div
      v-show="view === 'grid'"
      :data-testid="'scrollable-item-canvas-' + displayId + '-name-' + displayName"
      class="camera"
    >
      <eewc-loading-spinner :is-loading="loading && camera.status && camera.status.connectionStatus === 'online'" />
      <canvas
        ref="canvas"
        height="80"
        width="130"
      />
      <div
        v-if="camera.status && camera.status.connectionStatus !== 'online'"
        class="camera--overlay"
      />
      <camera-states
        v-if="camera.status && camera.status.connectionStatus !== 'online'"
        :status="camera.status.connectionStatus"
        icon-size="small"
        :show-time="false"
        :camera-id="camera.id"
      />
    </div>
    <!-- Camera preview popup -->
    <div
      v-if="view === 'list'"
      class="preview-image"
    >
      <image-preview-popup
        :device-id="camera.id"
        :icon="cameraIcon"
        :composite-id="composite?.id || undefined"
        class="camera-icon"
      />
    </div>
    <!-- Text -->
    <div
      :class="['list-title', { 'list-title--grid': view === 'grid', 'list-title--has-viewports': viewportCount > 0 }]"
    >
      <eewc-tooltipped-text
        class="list-title__text"
        :data-testid="'scrollable-item-title-' + displayId + '-name-' + displayName"
        :text="displayName"
      />
      <viewport-expander
        v-if="view === 'grid' && viewportCount > 0"
        :view="view"
        :expanded="expanded"
        :viewport-count="viewportCount"
        @onClickExpand="emit('onClickExpand', camera.id)"
      />
    </div>
    <!-- Status -->
    <div
      v-if="view === 'list' && !isItemComposite"
      class="list-status"
    >
      <eewc-tooltip
        v-if="getDeviceDisplayStatus(camera.status?.connectionStatus)"
        :text="getDeviceDisplayStatus(camera.status?.connectionStatus).tooltip"
      >
        <template #content="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            :color="getDeviceDisplayStatus(camera.status?.connectionStatus).iconColor"
            size="32"
            v-on="on"
          >
            {{ getDeviceDisplayStatus(camera.status?.connectionStatus).icon }}
          </v-icon>
        </template>
      </eewc-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import CameraStates from '../CameraStates.vue';
import { ApiCameraWithIncludes, ApiDewarpConfigComposite } from '@eencloud/eewc-components/src/service/api-types';
import { useMediaStore } from '@/stores';
import { generateTimestamp } from '@/service/helpers';
import ImagePreviewPopup from '../ImagePreviewPopup.vue';
import { getDeviceDisplayStatus } from '@/service/helpers';
import viewportExpander from '@/components/ScrollableList/ViewportExpander.vue';
import { useDewarp } from '@/service/dewarp/useDewarp';
import type { ViewType } from '@/components/ScrollableList/types.ts';

const displayName = computed(() => (props.composite ? props.composite.name : props.camera.name));
const displayId = computed(() => (props.composite ? props.composite.id : props.camera.id));
/**
 * Computed property to determine if the item is composite.
 * It checks if the `composite` prop is defined.
 *
 * @returns {boolean} - Returns true if the `composite` prop is defined, otherwise false.
 */
const isItemComposite = computed(() => props.composite !== undefined);

const props = withDefaults(
  defineProps<{
    camera: ApiCameraWithIncludes;
    composite?: ApiDewarpConfigComposite;
    type?: 'selectable' | 'dragAndDrop';
    selected?: boolean;
    draggable?: boolean;
    view?: ViewType;
    expanded?: boolean;
    viewportCount?: number;
  }>(),
  {
    composite: undefined,
    type: 'dragAndDrop',
    selected: false,
    draggable: false,
    view: 'list',
    expanded: false,
    viewportCount: 0,
    dewarpElements: undefined,
  }
);
const emit = defineEmits<{
  (e: 'onSelect', addOrRemove: 'add' | 'remove'): void;
  (e: 'dragStart', event: DragEvent): void;
  (e: 'dragEnd', event: DragEvent): void;
  (e: 'onClickExpand', value: string): void;
}>();

const loading = ref(false);
const mediaStore = useMediaStore();
const canvas = ref<HTMLCanvasElement>();
const { dewarpImage, initImageDewarp } = useDewarp();

const cameraIcon = computed(() => {
  if (props.viewportCount > 0) return '$icon_fisheye';
  if (isItemComposite.value) return '$icon_eye';
  return '$icon_camera';
});

onMounted(() => {
  if (isItemComposite.value) initImageDewarp(canvas, props.camera, props.composite.id);
  updatePreviewImage();
});

function onRowClick() {
  if (props.type !== 'selectable') return;
  emit('onSelect', !props.selected ? 'add' : 'remove');
}

async function updatePreviewImage() {
  loading.value = true;
  const res = await mediaStore.getRecordedImage(
    {
      deviceId: props.camera.id,
      timestamp__lte: generateTimestamp(Date.now()),
      type: 'preview',
    },
    false
  );
  loading.value = false;
  if (isItemComposite.value && res?.data) {
    dewarpImage(res.data);
    return;
  }
  if (canvas.value && res?.data) {
    const ctx = canvas.value.getContext('2d');
    const bitmap = await createImageBitmap(new Blob([res.data], { type: 'image/jpeg' }));
    ctx && ctx.drawImage(bitmap, 0, 0, canvas.value.width, canvas.value.height);
    bitmap.close();
  }
}

function sendDragStartEvent(event: DragEvent) {
  emit('dragStart', event);
}

function sendDragEndEvent(event: DragEvent) {
  emit('dragEnd', event);
}
</script>

<style lang="scss" scoped>
@import '../../styles/public/main.scss';

@mixin tile {
  min-width: 130px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
}

.scrollable-list-item {
  display: flex;
  padding: 4px;
  align-items: center;

  &__select {
    border-radius: 4px;
    background: $backgrounds;
  }

  &__drag-and-drop {
    &:hover {
      background: $backgrounds;
      box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
      cursor: grab;
    }

    &:active {
      opacity: 0.5;
      box-shadow: 2px 2px 2px rgba(33, 42, 52, 0.32);
    }
  }

  .camera {
    position: relative;
    background: $primary;
    @include tile;

    &--overlay {
      @include tile;
      position: absolute;
      margin-left: 0;
      top: 0;
      background: linear-gradient(0deg, rgba(33, 42, 52, 0.72), rgba(33, 42, 52, 0.72));
    }

    canvas {
      border-radius: 4px;
      width: 100%;
      height: 100%;
    }
  }

  .col {
    padding: 0;
  }

  // add border each list item
  &--list {
    &:not(:last-child) {
      border-bottom: 1px solid $elements;
    }
  }

  // remove border if list item has viewports
  &--has-viewports {
    &:not(:last-child) {
      border-bottom: none;
    }
  }

  &--grid {
    min-height: 80px;
    padding: 4px 0px;
    margin: 0px;
  }
}

.list-title {
  display: flex;
  width: 253px;
  margin-left: 12px;
  flex-direction: column;
  &__text {
    @include subtitle-2;
    color: $primary;
    padding-top: 2px;
  }
  &--has-viewports {
    gap: 4px;
  }
  &--grid {
    align-self: baseline;
    margin-top: 12px;
    width: 197px;
    gap: 8px;
  }
}

.list-status {
  margin-left: auto;
}

.composite-item {
  padding-left: 40px;
}

.preview-image :deep {
  .image-preview-popup__icon {
    color: $primary;
  }
}
</style>
