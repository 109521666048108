import { defineStore } from 'pinia';
import api from '@eencloud/eewc-components/src/service/api';
import { ApiDewarpConfigWithEsn, ImageDwarper, RenderValue, VideoDewarper } from '@/service/dewarp/dewarpTypes';
import {
  ApiDewarpConfig,
  ApiDewarpConfigComposite,
  DewarpConfigUpdate,
} from '@eencloud/eewc-components/src/service/api-types';
import '@/service/dewarp/EENDewarp.js';
import { normalizePaneDimensionsToPercentage } from '@/service/fisheyeHelpers';
import { reactive, ref } from 'vue';

export const useDewarpStore = defineStore('dewarp', () => {
  let imageDwarper: ImageDwarper | undefined;
  const devices: ApiDewarpConfigWithEsn[] = [];
  const canvasArr: { [esn: string]: HTMLCanvasElement } = {};

  const videoDevices: ApiDewarpConfigWithEsn[] = [];
  const videoDewarperArr = reactive<{ [esn: string]: VideoDewarper }>({});
  const videoCanvasArr: { [esn: string]: HTMLCanvasElement } = {};
  const videoElementArr: { [esn: string]: HTMLVideoElement } = {};
  const dwarperMouseInteractionArr: { [esn: string]: boolean } = {};
  const dewarpConfigArray = ref<{ [esn: string]: ApiDewarpConfig }>({});

  async function getDewarpConfig(cameraId: string) {
    try {
      const res = await api.getDewarpConfig(cameraId);
      if (res) {
        dewarpConfigArray.value[cameraId] = res;
      }
      return res;
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteComposite(compositeId: string, cameraId: string) {
    try {
      const status = await api.deleteComposite(compositeId, cameraId);
      if (status === 204) {
        dewarpConfigArray.value[cameraId] = {
          ...dewarpConfigArray.value[cameraId],
          composites: dewarpConfigArray.value[cameraId]?.composites?.filter(
            (composite) => composite.id !== compositeId
          ),
        };
      }
      return status;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Add device which needs to dewarped for image
   * @param device
   * @param canvas
   * @param composite
   */
  function addDeviceConfig(
    device: ApiDewarpConfigWithEsn,
    canvas: HTMLCanvasElement,
    composite?: ApiDewarpConfigComposite
  ) {
    delete device.composites; // remove composites from device, which is required by the dewarp library
    const existingDevice = devices.find((d) => d.esn === device.esn);
    const existingDeviceForVideoDewarp = videoDevices.find((d) => d.esn === device.esn);
    if (existingDevice) {
      return console.debug('Device already exists');
    }
    devices.push(device);
    canvasArr[device.esn] = canvas;
    if (!imageDwarper) {
      // @ts-ignore
      imageDwarper = EENDewarp.create_composite_renderer({
        config: { devices: [device] },
        width: canvas.width,
        height: canvas.height,
        supersampling: 1.0,
        disableWorker: true,
        flipY: existingDeviceForVideoDewarp ? true : false,
      });
    } else {
      imageDwarper?.setDevice(device.esn, {
        ...device,
        flipY: existingDeviceForVideoDewarp ? true : false,
      });
    }
    applyComposite(device.esn, composite);
  }

  /**
   * Add device which needs to dewarped for video
   * @param device
   * @param canvas
   * @param composite
   */
  function addDeviceConfigForVideoDewarp(
    device: ApiDewarpConfigWithEsn,
    composite: ApiDewarpConfigComposite | undefined,
    canvas: HTMLCanvasElement,
    videoElement: HTMLVideoElement
  ) {
    delete device.composites; // remove composites from device, which is required by the dewarp library
    const existingDevice = videoDevices.find((d) => d.esn === device.esn);
    if (existingDevice) {
      return console.debug('Device already exists');
    }
    videoDevices.push(device);
    videoCanvasArr[device.esn] = canvas;
    canvasArr[device.esn] = canvas;
    videoElementArr[device.esn] = videoElement;
    videoElementArr[device.esn].crossOrigin = 'use-credentials';
    if (!videoDewarperArr[device.esn]) {
      videoDewarperArr[device.esn] = EENDewarp.create_video_renderer({
        config: { devices: [device] },
        width: videoElement.parentElement?.clientWidth,
        height: videoElement.parentElement?.clientHeight,
        canvas,
        supersampling: 1.0,
      });
    }
    videoElement.parentElement &&
      videoDewarperArr[device.esn]?.initVideoSource(videoElement, device.esn, videoElement.parentElement);
    videoDewarperArr[device.esn]?.startPlayback(device.esn);
    applyVideoComposite(device.esn, composite);
  }

  /**
   *  Apply video composite to the device
   * @param esn
   * @param composite
   */

  function applyVideoComposite(esn: string, composite?: ApiDewarpConfigComposite) {
    const device = videoDevices.find((d) => d.esn === esn);
    if (!device) {
      return console.error('Device not found');
    }
    videoDewarperArr[device.esn]?.setDevice(esn); // remove device from the renderer
    videoDewarperArr[device.esn]?.setDevice(esn, device);
    if (composite) {
      composite.aspect = 16.0 / 9.0; // aspect ratio of the video is hardcoded to make it similar to classic
      normalizePaneDimensionsToPercentage(composite.panes);
      videoDewarperArr[device.esn]?.setComposite(esn, composite.id);
      videoDewarperArr[device.esn]?.setComposite(esn, composite.id, composite);
      videoElementArr[esn].style.visibility = 'hidden';
      videoCanvasArr[device.esn].style.visibility = 'visible';
      videoDewarperArr[device.esn].setRendererSize(
        videoElementArr[esn]?.parentNode?.clientWidth,
        videoElementArr[esn]?.parentNode?.clientHeight
      );
      setMouseInteractionEnabled(device.esn, !!dwarperMouseInteractionArr[device.esn]);
    } else {
      videoCanvasArr[device.esn].style.visibility = 'hidden';
      canvasArr[esn].style.visibility = 'hidden';
      videoElementArr[esn].style.visibility = 'visible';
    }
  }

  /**
   *  Apply image composite to the device
   * @param esn
   * @param composite
   */

  function applyComposite(esn: string, composite?: ApiDewarpConfigComposite) {
    const device = devices.find((d) => d.esn === esn);
    if (!device) {
      return console.error('Device not found');
    }
    imageDwarper?.setDevice(esn); // remove device from the renderer
    imageDwarper?.setDevice(esn, device);
    if (composite) {
      normalizePaneDimensionsToPercentage(composite.panes);
      imageDwarper?.setComposite(esn, composite.id, null);
      imageDwarper?.setComposite(esn, composite.id, composite, renderImageOnCanvas);
    } else {
      imageDwarper?.setComposite(esn, '', null); // remove composite from the renderer
    }
  }

  function renderImageOnCanvas(value: RenderValue) {
    if (!('event' in value)) return;
    const { event, canvas } = value;
    canvasArr[event.esn]?.getContext('2d')?.drawImage(canvas, 0, 0, canvas.width, canvas.height);
  }

  function updateConfig(deviceId: string, config: ApiDewarpConfig) {
    videoDewarperArr[deviceId]?.setConfig(config);
  }

  function addOriginalImage(esn: string, image: ImageBitmap | string) {
    if (!canvasArr[esn]) return;
    imageDwarper?.enqueueImage(esn, '', image);
  }

  /**
   * Remove device from the dewarp
   * @param esn
   */
  function removeDevice(esn: string) {
    const imageIndex = devices.findIndex((d) => d.esn === esn);
    if (imageIndex > -1) {
      devices.splice(imageIndex, 1);
      imageDwarper?.setComposite(esn, '', null); // remove composite from the renderer
      imageDwarper?.setDevice(esn); // remove device from the renderer
      delete canvasArr[esn]; // remove canvas from the canvas array
    }

    const videoIndex = videoDevices.findIndex((d) => d.esn === esn);
    if (videoIndex > -1) {
      videoDevices.splice(videoIndex, 1);
      videoDewarperArr[esn]?.setComposite(esn, '', null); // remove composite from the renderer
      videoDewarperArr[esn]?.setDevice(esn); // remove device from the renderer
      delete videoCanvasArr[esn]; // remove canvas from the video canvas array
      delete videoElementArr[esn];
      delete videoDewarperArr[esn];
      delete dwarperMouseInteractionArr[esn];
    }

    if (devices.length === 0 && imageDwarper) {
      imageDwarper = undefined;
    }

    if (videoDevices.length === 0) {
      Object.keys(videoDewarperArr).forEach((esn) => {
        delete videoDewarperArr[esn];
      });
    }
  }

  function setRendererSize(esn: string, width: number, height: number) {
    videoDewarperArr[esn]?.setRendererSize(width, height);
    imageDwarper?.setRendererSize(width, height);
  }

  /**
   * change mouse interaction for the video dewarp
   * @param esn - camera unique id
   * @param value - interaction value
   */
  function setMouseInteractionEnabled(esn: string, value: boolean) {
    dwarperMouseInteractionArr[esn] = value;
    videoDewarperArr[esn]?.setMouseInteractionEnabled(value);
  }

  function setCameraDirection(esn: string, compositeId: string, paneIdx: number | undefined, x: number, y: number) {
    const pane_idx = paneIdx || 0;
    const coords = imageDwarper?.unprojectPoint(esn, compositeId, pane_idx, x, y);
    if (!coords) return;
    imageDwarper?.setCameraDirection(esn, compositeId, pane_idx, coords.x, coords?.y, coords?.z);
  }

  function getFOV(esn: string, compositeId: string, paneIdx: number | undefined) {
    const pane_idx = paneIdx || 0;
    return imageDwarper?.getFOV(esn, compositeId, pane_idx);
  }

  function setFOV(esn: string, compositeId: string, paneIdx: number | undefined, fov: number) {
    const pane_idx = paneIdx || 0;
    imageDwarper?.setFOV(esn, compositeId, pane_idx, fov);
  }

  async function addComposite(cameraId: string, composite: ApiDewarpConfigComposite) {
    try {
      const status = await api.addComposite(cameraId, composite);
      if (status === 204) {
        dewarpConfigArray.value[cameraId]?.composites?.push(composite);
      }
      return status;
    } catch (error) {
      console.error(error);
    }
  }

  async function updateComposite(cameraId: string, composite: ApiDewarpConfigComposite) {
    try {
      const status = await api.updateComposite(cameraId, composite);
      if (status === 204) {
        dewarpConfigArray.value[cameraId] = {
          ...dewarpConfigArray.value[cameraId],
          composites: dewarpConfigArray.value[cameraId]?.composites?.map((c) =>
            c.id === composite.id ? composite : c
          ),
        };
      }
      return status;
    } catch (error) {
      console.error(error);
    }
  }

  async function updateDewarpConfig(cameraId: string, payload: DewarpConfigUpdate) {
    try {
      const status = await api.updateDewarpConfig(cameraId, payload);
      return status;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    getDewarpConfig,
    addDeviceConfig,
    addDeviceConfigForVideoDewarp,
    addOriginalImage,
    applyComposite,
    applyVideoComposite,
    removeDevice,
    setRendererSize,
    setMouseInteractionEnabled,
    setCameraDirection,
    getFOV,
    setFOV,
    deleteComposite,
    dewarpConfigArray,
    videoDewarperArr,
    addComposite,
    updateComposite,
    updateDewarpConfig,
    updateConfig,
  };
});
